import {
  GSButton,
  GSCircleImage,
  GSInfoGroup,
  GSItemList,
  defaultLayouts,
} from "golfstatus_react_components";
import React from "react";
import "./tournament-leaderboard-result-line-item.scss";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavorite,
  removeFavorite,
  selectFavoriteTeams,
} from "../../../../reducers/leaderboardSlice";
import { useTheme } from "../../../../hooks/themeHooks";

const TournamentLeaderboardResultLineItem = (props) => {
  const {
    leaderboard,
    name,
    matchScores,
    matchResult,
    matchWinnerKey,
    netTotal,
    thru,
    totalScore,
    displayPosition,
    hole,
    score,
    scoreTerm,
    winner,
    style,
    className,
    teamKey,
  } = props;

  const dispatch = useDispatch();

  const [, theme, getStyle] = useTheme();

  const favorites = useSelector(selectFavoriteTeams);

  const isFavorite = (key) =>  favorites?.includes?.(key);

  const defaultStyle = {};

  const favoriteStyle = (key) => isFavorite(key)
    ? getStyle(theme.secondaryContainer)
    : getStyle(theme.surface);

  const getFavoriteButton = (key) => (
    <GSButton
      style={favoriteStyle(key)}
      buttonIcon={faStar}
      type="secondary"
      onClick={(e) => {
        if (isFavorite(key)) {
          dispatch(removeFavorite(key));
        } else {
          dispatch(addFavorite(key));
        }
        e?.stopPropagation?.();
      }}
    />
  );

  const getMatchTeam = (item, direction) => {
    return (
      <div className={`teammate ${direction}`}>
        <div className={`teammate-image `}>
          <GSCircleImage size="large" url={item?.avatar}></GSCircleImage>
        </div>
        <div>{item?.name}</div>
      </div>
    );
  };

  //skins

  const skinHoleSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width position-width ",
        value: "Hole",
      },
      {
        type: "primary no-wrap full-width position-width centered ",
        value: hole,
      },
    ],
  };
  const skinScoreSection = {
    sectionItems: [
      {
        type: `primary no-wrap position-width score ${scoreTerm}`,
        sectionItemStyle:{height: "30px", width: "30px"},
        value: score,
      },
    ],
  };
  const skinWinnerSection = {
    fit: "stretch",
    sectionItems: [
      {
        type: winner === "-" ? "secondary full-width " : "primary full-width ",
        value:
          winner === "-" ? "No skins have been won for this hole." : winner,
      },
    ],
  };

  const skinHoleColumn = {
    sections: [
      {
        ...skinHoleSection,
      },
    ],
  };
  const skinScoreColumn = {
    sections: [
      {
        ...skinScoreSection,
      },
    ],
  };
  const skinWinnerColumn = {
    fit: "stretch",
    sections: [
      {
        ...skinWinnerSection,
      },
    ],
  };

  //stroke play

  const teamSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width position-width ",
        value: displayPosition,
      },
      {
        type: "primary full-width",
        value: name,
      },
    ],
  };

  const scoreSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width centered",
        value: "Total",
      },
      {
        type: "primary no-wrap full-width centered",
        value: netTotal === 0 ? "E" : netTotal > 0 ? `+${netTotal}` : netTotal,
      },
    ],
  };

  const thruSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width centered",
        value: "Thru",
      },
      {
        type: "primary no-wrap full-width centered ",
        value: thru,
      },
    ],
  };

  const pointsSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width centered",
        value: ["match_play", "stableford", "modified_stableford"].includes(
          leaderboard?.scoringSystem
        )
          ? "Points"
          : "Strokes",
      },
      {
        type: "primary no-wrap full-width centered ",
        value: totalScore,
      },
    ],
  };

  const favoriteSection = {
    sectionItems: [
      {
        type: "no-wrap full-width centered",
        value: (
          getFavoriteButton(teamKey)
        ),
      },
    ],
  };

  const teamColumn = {
    fit: "stretch",
    sections: [
      teamSection,
      favoriteSection
    ],
  };

  const scoreColumn = {
    sections: [
      {
        ...scoreSection,
      },
    ],
  };

  const thruColumn = {
    sections: [
      {
        ...thruSection,
      },
    ],
  };

  const pointsColumn = {
    sections: [
      {
        ...pointsSection,
      },
    ],
  };

  // const favoritColumn = {
  //   sections: [favoriteSection],
  // };

  const groupedColumns = [scoreColumn, pointsColumn]
  if((thru ?? "") !== ""){
    groupedColumns.splice(1, 0, thruColumn)
  }

  const newScoreSection = {
    sectionItems : [
      {type: "",
        value: <GSInfoGroup dataGroups={groupedColumns}/>
      }
    ]
  }

  const newScoreColumn = {
    sections: [
      newScoreSection
    ]
  }

  //match play
  const teamAAvatarSection = {
    sectionItems: [
      {
        type: `primary full-width team green ${
          matchScores?.[0].teamKey === matchWinnerKey ? "winner " : "loser"
        }`,
        value: (
          <div
            style={{
              ...defaultLayouts.hStack.align("flex-start", "center"),
              ...defaultLayouts.fullWidth,
            }}
          >
            <GSItemList
              style={{ ...defaultLayouts.fullWidth }}
              type={"vertical medium-large-gap"}
              items={matchScores?.[0]?.playerHash}
              listItem={(item) => getMatchTeam(item)}
            ></GSItemList>
            {getFavoriteButton(matchScores?.[0].teamKey)}
          </div>
        ),
      },
    ],
  };

  const matchResultSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: matchResult,
      },
      {
        type: "secondary no-wrap full-width",
        value: thru === "F" ? "Final" : `Thru ${thru}`,
      },
    ],
  };

  const teamBAvatarSection = {
    sectionItems: [
      {
        type: `primary full-width team orange ${
          matchScores?.[1].teamKey === matchWinnerKey ? "winner " : "loser"
        }`,
        value: (
          <div
            style={{
              ...defaultLayouts.hStack.align("flex-start", "center"),
              ...defaultLayouts.fullWidth,
            }}
          >
            <GSItemList
              style={{ ...defaultLayouts.fullWidth }}
              type={"vertical medium-large-gap"}
              items={matchScores?.[1]?.playerHash}
              listItem={(item) => getMatchTeam(item)}
            ></GSItemList>
            {getFavoriteButton(matchScores?.[1].teamKey)}
          </div>
        ),
      },
    ],
  };

  const teamAColum = {
    fit: "stretch",
    sections: [{ ...teamAAvatarSection }],
  };

  const matchResultColumn = {
    fit: "team-width",
    sections: [
      {
        ...matchResultSection,
      },
    ],
  };

  const teamBColumn = {
    fit: "stretch",
    sections: [{ ...teamBAvatarSection }],
  };

  const getGroup = () => {
    let group = [];

    if (matchResult) {
      group = [{ ...matchResultColumn }, { ...teamAColum }, { ...teamBColumn }];
    } else if (leaderboard?.type === "skins") {
      group = [
        { ...skinHoleColumn },
        { ...skinWinnerColumn },
        { ...skinScoreColumn },
      ];
    } else {
      group = [
        teamColumn,
        newScoreColumn,
      ];
    }
    return group;
  };

  return (
    <tournament-leaderboard-result-line-item class={className} style={style}>
      <GSInfoGroup
        style={defaultStyle}
        listType={matchResult ? `mobile-vertical` : ""}
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </tournament-leaderboard-result-line-item>
  );
};

export default TournamentLeaderboardResultLineItem;
