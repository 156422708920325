import {
  defaultPadding,
  defaultLayouts,
  defaultBorders,
  defaultGapping,
  defaultTypography,
} from "golfstatus_react_components";
import React from "react";
import { useTheme } from "../../../../hooks/themeHooks";
import "./payment-method-details.scss"
/**
 *
 * @typedef Properties
 * @type {object}
 * @property {string} label label for the Payment Type
 * @property {object} description description of the payment type
 * @property {object} content content for the section
 * @property {object} style style of the component

 * @param {Properties} props paymentType,
    icon
 */

//Name the component

const PaymentMethodDetails = (props) => {
  const { label, description, content, style, isPaymentAttached } = props;

  const [, theme, getStyle] = useTheme();
  
  const getStatusBorder = (status) => {
    if (!isPaymentAttached) {
      return {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: getStyle(theme.error)?.color,
      };
    } else {
      return {};
    }
  };


  let componentStyle = () => {
    const style = {
      ...defaultLayouts.fill,
      ...defaultLayouts.vStack,
      ...defaultGapping.xLargeGap,
      
    };
    return style;
  };

  return (
    <payment-method-details style={{ ...componentStyle(), ...style }}>
      <div
        style={{ ...defaultLayouts.vStack, ...defaultGapping.mediumLargeGap }}
      >
        <div style={{ ...defaultTypography.headline2 }}>{label}</div>
        <div style={{ ...defaultTypography.bodyRegular, opacity: 0.7 }}>
          {description}
        </div>
      </div>

      {content ? (
        <div
          style={{
            ...defaultPadding.xLargePad,
            ...getStyle(theme.surface),
            ...defaultLayouts.vStack,
            ...defaultLayouts.fullWidth,
            ...defaultBorders.mediumLargeBorderRadius,
            ...getStatusBorder()
          }}
        >
          {content}
        </div>
      ) : null}
    </payment-method-details>
  );
};

export default PaymentMethodDetails;
