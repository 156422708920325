import { defaultLayouts, GSButton, GSInfoGroup, GSItemList } from "golfstatus_react_components";
import React from "react";

/**
 * Donation info group.
 *
 * @typedef Properties
 * @type {object}
 * @property {string} itemCount count of packages in cart
 * @property {string} total subtotal for the cart
 * @property {string} nextStep label for next step
 * @property {function} navigate function to run for continue button
 * @property {object} style the style for this component
 * @property {object} buttonStyle style for the navigation button
 *
 * @param {Properties} props itemCount, total, nextStep, navigate, style
 */

const CartStepItem = (props) => {
  const {
    itemCount,
    total,
    description,
    icon,
    nextStep,
    actions,
    style,
  } = props;

  const getNextStep = () => {
    if (icon) {
      return (
        <div>
          {icon} {nextStep}
        </div>
      );
    } else {
      return nextStep;
    }
  };

  const stepSection = {
    sectionItems: [
      {
        type: "body",
        value: getNextStep(),
      },
      {
        type: "secondary",
        value: description
          ? description
          : `${itemCount} Item${itemCount !== 1 ? "s" : ""} | ${total}`,
      },
    ],
  };

  const navSection = {
    sectionItems: [
      {
        type: "primary mobile-stretch",
        value: (
          <GSItemList
            type="horizontal medium-gap mobile-vertical"
            style={{...defaultLayouts.fullWidth}}
            items={actions}
            listItem={(action) => <GSButton style={{flexGrow: 1}} {...action} />}
          />
        ),
      },
    ],
  };

  const stepColumn = {
    fit: "stretch",
    sections: [stepSection],
  };

  const navColumn = {
    type:"mobile-stretch",
    sections: [navSection],
  };

  const getGroup = () => {
    let group = [stepColumn, navColumn];
    return group;
  };

  return (
    <cart-step-item style={style}>
      <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup()}></GSInfoGroup>
    </cart-step-item>
  );
};

export default CartStepItem;
