import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defaultBorders, defaultGapping, defaultLayouts, defaultPadding, defaultTypography } from "golfstatus_react_components";

export const confirmationForm = (name, view, disclaimer, borderColor) => {
  let form = [
    {
      type: "vertical xx-large-gap",
      fields: [
        {
          value: (
            <div style={{...defaultLayouts.vStack, ...defaultGapping.largeGap}} >
              <div style={{...defaultTypography.bodyRegular.withOpacity()}} >{disclaimer}</div>
              <div style={{...defaultPadding.xLargePad, ...defaultBorders.primaryBorder, borderColor: borderColor, ...defaultBorders.mediumLargeBorderRadius}}>{view}</div>
            </div>
          ),
          customView:true
        },
      ],
    },
  ];
  return form;
};

export const confirmationTitle = (title, iconStyle, style) => {
  return (
    <div style={{...defaultLayouts.hStack.align("flex-start","center"), ...defaultGapping.mediumGap, ...style}}>
      <div>
        <FontAwesomeIcon style={iconStyle} icon={faCheckCircle}></FontAwesomeIcon>
      </div>
      {title}
    </div>
  );
};
