import React from "react";
import { GSForm, GSSidePanelPage, useFormDataValidation } from "golfstatus_react_components";
import { faChevronLeft, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

//import scss
import "./sponsor-image-editor.scss";
import {
  useImageCropper,
} from "../../../../hooks/formHooks";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { getImageCropForm } from "../../../../forms/PackageForm";
import { useDispatch, useSelector } from "react-redux";
import { selectRegistrationOrder, updateSponsor } from "../../../../reducers/tournamentSlice";
import { getRouting } from "../../../../helpers/Converters";
import {
  ORDER_ID,
  ORDER_ITEM_ID,
  SPONSOR_ID,
  TOURNAMENT_ID,
  eventBaseRoute,
  getProp,
} from "../../../../routes/event";

//Name the component
const SponsorImageEditor = (props) => {
  const registrationOrder = useSelector(selectRegistrationOrder);
  const dispatch = useDispatch()

  const route = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "cart",
    ORDER_ID,
    ORDER_ITEM_ID,
    "sponsor",
    SPONSOR_ID,
    "image",
  ]);
  const match = useMatch(route);
  const orderItemID = getProp(match?.params, ORDER_ITEM_ID);
  const sponsorID = getProp(match?.params, SPONSOR_ID);

  const currentOrder = registrationOrder?.registrationOrderItems?.find?.(
    (oi) => oi.id === orderItemID
  );
  const currentSponsor = currentOrder?.inProgressSponsors?.find?.(
    (s) => s.id === sponsorID
  );

  const [cropParams] = useSearchParams();

  const property = cropParams?.get("property");

  const [context, isValid] = useFormDataValidation({initialValid: true, data: currentSponsor, setData: (sponsor) => {
    dispatch(updateSponsor(sponsor));
  } });

  const [
    croppedImg,
    showCroppedImage,
    saveCroppedLogo,
    applyCrop,
    undoCrop,
  ] = useImageCropper(context, property);

  const navigate = useNavigate();
  const notifications = [];

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save Image",
        isDisabled: !isValid,
        action: saveLogo,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const saveLogo = () => {
    saveCroppedLogo();
    leftNavigation();
  };

  context.getActions = () => {
    if (!showCroppedImage) {
      return [
        { buttonTitle: "Preview", actionClick: applyCrop, type: "transparent" },
      ];
    } else {
      return [
        {
          buttonTitle: "revert",
          actionIcon: faUndoAlt,
          actionClick: undoCrop,
          type: "transparent red",
        },
      ];
    }
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Edit Image`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  context.getRatio = () => {
    return property === "logoMobile" ? 4 : 2;
  };

  context.getMinHeight = () => {
    return property === "logoMobile" ? 150 : 300;
  };

  context.getLogo = () => {
    return currentSponsor?.[property];
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle="Edit Image"
        formActions={context.getActions()}
        formSections={getImageCropForm(context, showCroppedImage, croppedImg)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {};

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  const keyPressed = () => {
    saveLogo();
  };

  return (
    //name the component tag
    <sponsor-image-editor
      id="image-editor"
      onKeyPress={keyPressed}
      tabIndex={1}
    >
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </sponsor-image-editor>
  );
};

//export the new namet
export default SponsorImageEditor;
