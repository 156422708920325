import {
  defaultBorders,
  defaultLayouts,
  defaultPadding,
  GSEmptyList,
  GSItemList,
} from "golfstatus_react_components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  addPackageToOrder,
  getTournamentPackageFieldSets,
  selectRegistrationOrder,
  updateCurrentPackage,
} from "../../../../reducers/tournamentSlice";
import PackageLineItem from "./package-line-item";
import { useTheme } from "../../../../hooks/themeHooks";
import { faCartPlus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { addNotification } from "../../../../reducers/appSlice";
import { createNotification } from "../../../../app/api";
import { mediumDelay } from "../../../../helpers/JSONapi";
import { getRouting } from "../../../../helpers/Converters";
import { eventBaseRoute } from "../../../../routes/event";
import { packageSoldOut, useTournament } from "../../../../hooks/tournamentHooks";

export const createAddToCartNotification = (
  tournament,
  registrationOrder,
  navigate
) => {
  return createNotification(
    "Package has been added to your cart",
    faCartPlus,
    "success",
    "saved",
    mediumDelay,
    [
      {
        id: 0,
        title: "View Cart",
        type: "secondary",
        style: {},
        actionClick: () => {
          navigate(
            getRouting(eventBaseRoute, [
              tournament?.publicUrl,
              "cart",
              registrationOrder.id,
            ])
          );
        },
      },
    ]
  );
};

export const PLAYER_PACKAGES = "playerPackages";
export const TEAM_PACKAGES = "teamPackages";
export const SPONSOR_PACKAGES = "sponsorPackages";
export const ADD_ON_PACKAGES = "addOnPackages";

const PackageList = (props) => {
  const { packages, type } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [, theme, getStyle] = useTheme();

  const tournament = useTournament();
  const registrationOrder = useSelector(selectRegistrationOrder);

  const isTeamRegistration = type === TEAM_PACKAGES || type === PLAYER_PACKAGES;

  const showWaitList = tournament.numberRegisteredTeams >= tournament.numberOfTeams &&
  isTeamRegistration &&
  tournament.hasWaitList

  const viewPackage = (tournamentPackage) => {
    dispatch(updateCurrentPackage(tournamentPackage));
    navigate(`${tournamentPackage.id}?${searchParams.toString()}`);
  };

  const getPackageStyle = (tp) => {
    return tp.remainingPackages > 0
      ? getStyle(theme.surfaceVariant)
      : { ...getStyle(theme.surfaceVariant) };
  };

  const getPackageAction = (tp) => {
    if (
      showWaitList
    ) {
      return {
        title: "Join Waitlist",
        type: "grey",
        buttonIcon: faPlus,
        onClick: (e) => {
          addPackageToCart(tp);
          e?.stopPropagation?.();
        },
      };
    }
    if (!packageSoldOut(tournament, tp)) {
      return {
        title: "Add To Cart",
        type: "",
        buttonIcon: faPlus,
        style: { ...getStyle(theme.tertiaryContainer), flexGrow: 1 },
        onClick: (e) => {
          addPackageToCart(tp);
          e?.stopPropagation?.();
        },
      };
    } else {
      return {
        title: "sold out",
        type: "black pill secondary",
      };
    }
  };

  const addPackageToCart = (currentPackage) => {
    dispatch(
      addNotification(
        createAddToCartNotification(tournament, registrationOrder, navigate)
      )
    );
    dispatch(getTournamentPackageFieldSets(currentPackage?.id)).then((response) => {
      const p = {...currentPackage}
      p.fieldSets = response?.payload?.fieldSets
      p.packageItems = response?.payload?.packageItems
      p.rounds = response?.payload?.rounds
      p.tournamentRounds = response?.payload?.rounds?.map?.(r => r.id)
      dispatch(addPackageToOrder(p));
    })
    
  };

  const getWaitList = () => {
    return (
      <GSEmptyList
      style={{...defaultLayouts.fullWidth, ...defaultPadding.emptyListPad, ...getStyle(theme.surfaceVariant), ...defaultBorders.mediumLargeBorderRadius}}
        title="Sold Out!"
        detail="Join the waitlist to be notified is a spot opens up!"
        actions={[
          {
            title: "Join Waitlist",
            type: "black",
            buttonIcon: faPlus,
            onClick: () => {
              navigate(`waitlist?type=${type}`)
            },
          },
        ]}
      />
    );
  };

  if(showWaitList){
    return getWaitList()
  }

  return (
    <package-list>
      <GSItemList
        style={{ ...defaultLayouts.fullWidth }}
        type="vertical selectable medium-large-gap"
        items={packages}
        itemSelected={(p) => {
          viewPackage(p);
        }}
        listItem={(tp) => (
          <PackageLineItem
            className="hover-color-shift"
            style={{
              ...defaultLayouts.fullWidth,
              ...defaultPadding.xLargePad,
              ...getPackageStyle(tp),
              ...defaultBorders.mediumLargeBorderRadius,
            }}
            {...tp}
            action={getPackageAction(tp)}
          />
        )}
      />
    </package-list>
  );
};

export default PackageList;
