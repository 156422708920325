import { Route } from "react-router-dom";
import EventsList from "../components/events/events-list";
import EventsFilter from "../components/events/events-filter";

export const getEventListRoutes = () => {
  return (
    <>
      <Route path={"/"} element={<EventsList />}>
        <Route path="/filter" element={<EventsFilter />} />
      </Route>
      <Route path={"/organization/:id"} element={<EventsList />}>
        <Route path="/organization/:id/filter" element={<EventsFilter />} />
      </Route>
    </>
  );
};
