import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  GSEmptyList,
  defaultPadding,
  defaultLayouts,
  GSPageSection,
  defaultTypography,
} from "golfstatus_react_components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCompletedOrder,
  selectRegistrationCustomer,
} from "../../../../reducers/tournamentSlice";
import {
  useHeaderStyle,
  useSectionStyles,
} from "../../../../hooks/themeHooks";
import "./event-cart-checkout-confirmation.scss";
import CustomerLineItem from "./customer-line-item";
import { getRouting } from "../../../../helpers/Converters";
import {
  eventBaseRoute,
} from "../../../../routes/event";
import PageHeader from "../../../page-header";
import ConfirmationPaymentInfoWidget from "../../../widgets/confirmation-payment-info-widget";
import ThankYouWidget from "../../../widgets/thank-you-widget";
import { useNavigationButtons } from "../../../../hooks/pageHooks";
import CheckoutItemsWidget from "../../../widgets/checkout-items-widget";
import CheckoutDiscountWidget from "../../../widgets/checkout-discount-widget";
import CheckoutDonationWidget from "../../../widgets/checkout-donation-widget";
import CheckoutFeesWidget from "../../../widgets/checkout-fees-widget";
import CheckoutRoundUpWidget from "../../../widgets/checkout-round-up-widget";
import CheckoutTotalWidget from "../../../widgets/checkout-total-widget";
import { useTournament } from "../../../../hooks/tournamentHooks";
import { usePermissions } from "../../../../hooks/permissionHooks";
import InternationalFeeWidget from "../../../widgets/international-fee-widget";

const EventCartCheckoutConfirmation = (props) => {
  const navigate = useNavigate();

  const tournament = useTournament()

  const permissions = usePermissions()

  const registrationOrder = useSelector(selectCompletedOrder);
  const customer = useSelector(selectRegistrationCustomer);

  const { headerStyle } = useHeaderStyle();

  const discount = registrationOrder?.discountCodes?.[0];
  const roundUp = registrationOrder?.donationOrderItems?.find?.(
    (donation) => donation?.["roundUp"]
  )?.amount;

  const donation = registrationOrder?.donationOrderItems?.find?.(
    (donation) => !donation?.["roundUp"]
  )?.amount;

  const { defaultSectionStyle, headerSectionStyle } = useSectionStyles();

  const getPageActions = useNavigationButtons("receipt");

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Registration Order Confirmation"
        detail="We could not find your order..."
        actions={[
          {
            title: "View Event Details",
            type: "grey",
            onClick: () => {
              navigate(getRouting(eventBaseRoute, [tournament?.publicUrl]));
            },
          },
        ]}
        style={defaultPadding.emptyListPad}
      />
    );
  };

  const isSidePanelOpen = () => {
    //remove this when you add your match route.
    return false;
    //
    //return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const summarySections = () => {
    const summary = [];

    const hasOrderItems = registrationOrder?.registrationOrderItems?.length > 0;

    if (hasOrderItems) {
      summary.push(<CheckoutItemsWidget complete={true} />);
    }
    if (discount && hasOrderItems) {
      summary.push(<CheckoutDiscountWidget complete={true} />);
    }
    if (donation) {
      summary.push(<CheckoutDonationWidget complete={true} />);
    }
    if (permissions.feesEnabled(registrationOrder) && registrationOrder?.registrantCoversFees) {
      summary.push(<CheckoutFeesWidget complete={true} />);
    }
    if(registrationOrder?.internationalCardUsed){
      summary.push(<InternationalFeeWidget complete={true}/>)
    }
    if (roundUp) {
      summary.push(<CheckoutRoundUpWidget complete={true} />);
    }

    summary.push(<CheckoutTotalWidget complete={true} />);
    return summary;
  };

  const getItemList = () => {
    if (registrationOrder?.id === undefined) {
      return getEmptyListMessage();
    }
    return (
      <div style={{ ...defaultLayouts.vStack }}>
        <PageHeader header="Order Confirmed!" />
        <GSPageSection {...headerSectionStyle} body={[<ThankYouWidget />]} />
        <GSPageSection
          title="Order Details"
          {...defaultSectionStyle}
          body={[
            <CustomerLineItem
              nameStyle={{ ...defaultTypography.body }}
              {...customer}
            />,
            ...summarySections(),
            <ConfirmationPaymentInfoWidget />,
          ]}
        />
      </div>
    );
  };

  return (
    <event-cart-checkout-confirmation>
      <GSListPage
        title=""
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        headerStyle={headerStyle}
      ></GSListPage>
    </event-cart-checkout-confirmation>
  );
};

export default EventCartCheckoutConfirmation;
