import { defaultTypography, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { teeLocations } from "../../../../forms/PackageForm";

const WaitListMemberItem = (props) => {

  const { firstName, lastName, email, phone, waitListType, notes, ghinNumber, teePreference } = props;

  const memberInfoSection = {
    sectionItems: [
      { type: "body", value: `${firstName} ${lastName}` },
      { type: "", style:{...defaultTypography.bodyRegular.withOpacity()},  value: email },
      { type: "", style:{...defaultTypography.bodyRegular.withOpacity()}, value: phone },
    ],
  };

if(ghinNumber){
  memberInfoSection.sectionItems.push({
    type:"", style:{...defaultTypography.bodyRegular.withOpacity()},
    value: `GHIN Number: ${ghinNumber}`
  })
}

if(teePreference){
  const tee = teeLocations.find?.(t => t.value === teePreference)?.label
  memberInfoSection.sectionItems.push({
    type: "", style:{...defaultTypography.bodyRegular.withOpacity()},
    value: `Tee: ${tee} `
  })
}

  const typeSections = {
    sectionItems: [{ type: "", style:{...defaultTypography.bodyRegular.withOpacity(), ...defaultTypography.capitalize}, value: waitListType === "individual" ? "Individual Player":  waitListType }],
  };

  const messageSection = {
    sectionItems: [{ type: "", style:{...defaultTypography.bodyRegular.withOpacity()}, value: notes }],
  };

  const memberColumn = {
    sections: [memberInfoSection, typeSections, messageSection],
  };

  const columns = [memberColumn];

  return <GSInfoGroup dataGroups={columns} />;
};

export default WaitListMemberItem;
