import React, { useEffect } from "react";
import {
  GSForm,
  GSSidePanelPage,
  useFormDataValidation,
} from "golfstatus_react_components";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useMatch, useNavigate } from "react-router-dom";

//import scss
import "./package-details.scss";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { getPackageForm } from "../../../../forms/PackageForm";
import { useDispatch, useSelector } from "react-redux";
import {
  addPackageToOrder,
  getTournamentPackage,
  getTournamentPackageFieldSets,
  selectPublicTournament,
  selectRegistrationOrder,
  selectTournamentPackage,
  updateCurrentPackage,
  selectLoading
} from "../../../../reducers/tournamentSlice";
import { getRouting } from "../../../../helpers/Converters";
import {
  eventBaseRoute,
  getProp,
  PACKAGE_ID,
  TOURNAMENT_ID,
} from "../../../../routes/event";

import { useTheme } from "../../../../hooks/themeHooks";
import { addNotification } from "../../../../reducers/appSlice";
import { createAddToCartNotification } from "./package-list";
import { packageHasWaitlist, packageSoldOut } from "../../../../hooks/tournamentHooks";

//Name the component
const PackageDetails = (props) => {
  const { parent } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const currentPackage = useSelector(selectTournamentPackage);
  const registrationOrder = useSelector(selectRegistrationOrder);
  const tournament = useSelector(selectPublicTournament);

  const loading = useSelector(selectLoading)?.filter?.(t => t === "getTournamentPackage")

  const route = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "packages",
    PACKAGE_ID,
  ]);
  const sponsorshipRoute = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "sponsorships",
    "package",
    PACKAGE_ID,
  ]);
  const registrantsRoute = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "registrants",
    "package",
    PACKAGE_ID,
  ]);

  const match = useMatch(
    parent === "sponsorships"
      ? sponsorshipRoute
      : parent === "registrants"
      ? registrantsRoute
      : route
  );
  const packageID = getProp(match?.params, PACKAGE_ID);

  const [, theme, getStyle] = useTheme();

  const [context, , setIsValid] = useFormDataValidation({
    initialValid: true,
    setData: (update) => {
      dispatch(updateCurrentPackage(update));
    },
    data: currentPackage,
  });

  context.getPackageAction = () => {
    return !packageSoldOut(tournament, currentPackage)
      ? {
          title: "Add To Cart",
          type: "no-wrap",

          buttonIcon: faPlus,
          style: { ...getStyle(theme.tertiaryContainer), flexGrow: 1 },
          onClick: (e) => {
            save();
            e?.stopPropagation?.();
          },
        }
      : {
          title: "sold out",
          type: "black pill secondary no-wrap",
        };
  };

  useEffect(() => {
    if (packageID) {
      dispatch(getTournamentPackage(packageID));
    }
    return () => {
      dispatch(updateCurrentPackage({}))
    }
  }, [dispatch, packageID]);

  const navigate = useNavigate();
  const notifications = [];

  const save = () => {

    dispatch(
      addNotification(
        createAddToCartNotification(tournament, registrationOrder, navigate)
      )
    );
  
    // we need to get the field sets before we add to order... this could be done with an updated api
    dispatch(getTournamentPackageFieldSets(currentPackage?.id)).then(
      (response) => {
        const p = { ...currentPackage };
        p.fieldSets = response?.payload?.fieldSets;
        p.packageItems = response?.payload?.packageItems;
        p.rounds = response?.payload?.rounds
        p.tournamentRounds = response?.payload?.rounds?.map?.(r => r.id)
        dispatch(addPackageToOrder(p));
      }
    );
    setIsValid(false);
    leftNavigation();
  };

  const joinWaitlist = () => {
    navigate(getRouting(eventBaseRoute, [tournament?.publicUrl, "packages", `waitlist`]))
  }

  const getDrawerActions = () => {
    let actions = [{ name: "close", action: leftNavigation, type: "grey" }];

    if(packageHasWaitlist(tournament, currentPackage)){
      actions.unshift(
        { name: "Join Waitlist", action: joinWaitlist, type: "black" }
      )
    }

    return actions;
  };

  const leftNavigation = () => {
    if(location.key === "default"){
      navigate(getRouting(eventBaseRoute, [tournament?.publicUrl, "packages"]))
    }
    else{
      navigate(-1);
    }
    
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Package Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formSections={getPackageForm(context)}></GSForm>;
  };

  const timeoutAction = () => {
    leftNavigation();
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <package-details>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
        loading={loading?.length > 0}
        loadingText="Loading Package..."
      ></GSSidePanelPage>
    </package-details>
  );
};

//export the new namet
export default PackageDetails;
