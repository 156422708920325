import React from "react";
import {
  faArrowRight,
  faFile,
  faPeopleLine,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSInfoGroup,
  GSItemList,
  defaultLayouts,
  defaultPadding,
  defaultBorders,
  useMediaQuery,
  golfstatusTypography,
} from "golfstatus_react_components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "../../hooks/themeHooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectPublicTournament } from "../../reducers/tournamentSlice";

const RoundNavigationWidget = (props) => {
  const [, theme, getStyle] = useTheme();
  const navigate = useNavigate();

  const { isPhone } = useMediaQuery();

  const tournament = useSelector(selectPublicTournament)

  const links = [
    {
      icon: faFile,
      label: "Rules",
      route: "rules",
    },
  ];

  if(!tournament?.tournamentPromotion?.hideHoleAssignment){
    links.unshift(
      {
        icon: faPeopleLine,
        label: "Hole Assignments",
        route: "hole-assignments",
      },
    )
  }

  const linkInfo = (link) => {
    const IconSection = {
      sectionItems: [
        {
          type: "",
          style: {
            fontSize: golfstatusTypography.mediumFont,
            lineHeight: golfstatusTypography.squareLineHeight,
          },
          value: <FontAwesomeIcon icon={link.icon} />,
        },
      ],
    };

    const labelSection = {
      sectionItems: [{ type: "body", value: link.label }],
    };

    const actionSection = {
      sectionItems: [
        {
          type: "",
          value: (
            <FontAwesomeIcon
              style={{
                fontSize: golfstatusTypography.mediumFont,
                lineHeight: golfstatusTypography.squareLineHeight,
              }}
              icon={faArrowRight}
            />
          ),
        },
      ],
    };
    const labelColum = {
      fit: "stretch",
      sections: [IconSection, labelSection],
    };
    const actionColumn = { sections: [actionSection] };
    return (
      <GSInfoGroup
        type="hover-outline"
        style={{
          ...getStyle(theme.surface),
          ...defaultPadding.xLargePad,
          ...defaultBorders.mediumLargeBorderRadius,
          ...defaultLayouts.fullWidth,
        }}
        dataGroups={[labelColum, actionColumn]}
      />
    );
  };

  return (
    <GSItemList
      style={{ ...defaultLayouts.fullWidth }}
      type="inline-grid medium-large-gap selectable"
      itemSelected={(link) => {
        navigate(link.route);
      }}
      gridColumns={isPhone() ? 1 : Math.min(2, links.length)}
      items={links}
      listItem={(link) => linkInfo(link)}
    />
  );
};

export default RoundNavigationWidget;
