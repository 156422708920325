import { GSButton } from "golfstatus_react_components";
import React from "react";
import { useSelector } from "react-redux";

import { selectPublicTournament, selectTournamentRound } from "../../reducers/tournamentSlice";
import { useNavigate } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TournamentRoundSelectItem from "../events/event/rounds/tournament-round-select-item";

const CurrentRoundWidget = () => {
  const tournament = useSelector(selectPublicTournament)
  const currentRound = useSelector(selectTournamentRound);

  const navigate = useNavigate();

  const getAction = () => {
    if(tournament?.tournamentRounds?.length < 2){
      return
    }
    return <GSButton
    style={{ flexGrow: 1 }}
    rightIcon={faArrowRight}
    title="change round"
    type="black"
    onClick={() => {
      navigate("select");
    }}
  />
  }

  return (
    <TournamentRoundSelectItem
      {...currentRound}
      action={
       getAction()
      }
    />
  );
};

export default CurrentRoundWidget;
