import React from "react";
import {
  GSItemList,
  GSSidePanelPage,
  defaultLayouts,
  defaultPadding,
  defaultBorders,
  defaultTypography
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./leaderboard-select.scss";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { useDispatch, useSelector } from "react-redux";
import { selectLeaderboards, setLeaderboard } from "../../../../reducers/leaderboardSlice";
import { useTheme } from "../../../../hooks/themeHooks";
import { getRouting } from "../../../../helpers/Converters";
import { LEADERBOARD_ID, TOURNAMENT_ID, eventBaseRoute, getProp } from "../../../../routes/event";
import PageHeader from "../../../page-header";
import { useTournament } from "../../../../hooks/tournamentHooks";

//Name the component
const LeaderboardSelect = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const route = getRouting(eventBaseRoute, [TOURNAMENT_ID,"leaderboards", LEADERBOARD_ID, "select"])
  const match = useMatch(route);
  const leaderboardID = getProp(match?.params, LEADERBOARD_ID)

  const tournament = useTournament();
  
  const notifications = [];

  const leaderboards = useSelector(selectLeaderboards);

  const [, theme, getStyle] = useTheme();

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Leaderboards`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const selectLeaderboard = (leaderboard) => {
    dispatch(setLeaderboard(leaderboard))
    navigate(getRouting(eventBaseRoute, [tournament?.publicUrl, `leaderboards`, leaderboard?.id]))
  }

  const getborder = (leaderbaord) => {
    if (leaderbaord?.id === leaderboardID){
      return {
        borderColor : getStyle(theme.background).color
      }
    }
  }

  const getContent = () => {
    return (
      <gs-form>
        <PageHeader header="Leaderboards"/>
        <GSItemList
          style={{ ...defaultLayouts.fullWidth, ...defaultPadding.smallLayoutPad.apply("vertical"), ...defaultPadding.xLargePad.apply("horizontal") }}
          type="vertical selectable medium-large-gap"
          items={leaderboards}
          itemSelected={(leaderboard) => {selectLeaderboard(leaderboard)}}
          listItem={(leaderboard) => (
            <div
            className="hover-color-shift"
              style={{
                ...defaultLayouts.fullWidth,
                ...defaultPadding.xLargePad,
                ...defaultBorders.mediumLargeBorderRadius,
                borderWidth: "4px",
                borderStyle: "solid",
                ...getStyle(theme.outlineVariant),
                ...getStyle(theme.surface),
                ...defaultTypography.body,
                ...getborder(leaderboard)
              }}
            >
              {leaderboard.name}
            </div>
          )}
        />
      </gs-form>
    );
  };

  const timeoutAction = () => {};

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <leaderboard-select>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </leaderboard-select>
  );
};

//export the new namet
export default LeaderboardSelect;
