import { defaultLayouts, defaultPadding, GSEmptyList } from "golfstatus_react_components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useIntercom  } from "react-use-intercom";
import { useNavigationButtons } from "../hooks/pageHooks";

const GolfStatNotFound = () => {
  const navigate = useNavigate()
  const intercom = useIntercom()
  useNavigationButtons("")
  const navigateBack = () => {
    navigate("/")
  }
  return (
    <golfstatus-not-found style={{...defaultLayouts.vStack.align("center","center"), ...defaultLayouts.fill, ...defaultPadding.xLargePad}}>
      <GSEmptyList
        title={"Page Not Found"}
        detail={<center>Check the URL for errors or visit the events list to search for an event. If you’re still having troubles, let us know!</center>}
        actions={[{ title: "View All Events", type:"black", onClick: () => {navigateBack()} },{
          title:"Contact Support", type:"grey", onClick: () => {intercom?.show?.()}
        }]}
      />
    </golfstatus-not-found>
  );
};

export default GolfStatNotFound;
