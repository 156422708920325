import { defaultBorders, defaultLayouts, defaultPadding, GSItemList, useMediaQuery } from "golfstatus_react_components";
import React from "react";
import { useNavigate } from "react-router-dom";
import PackageLineItem from "./package-line-item";
import { useTheme } from "../../../../hooks/themeHooks";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const AvailablePackagesList = (props) => {

  const {isPhone, isTablet} = useMediaQuery()
  const navigate = useNavigate()
  const {tournamentPackages} = props

  const [,theme, getStyle] = useTheme()

  const getPackageStyle = (tp) => {
    return tp.remainingPackages > 0
      ? {
          ...getStyle(theme.surface),
        }
      : {
          ...getStyle(theme.surfaceDim),
        };
  };

  const getAction = (tp) => {
      return tp.remainingPackages > 0
        ? {
            rightIcon: faArrowRight,
            title: "view",
            type: "grey secondary",
            style: { flexGrow: 1 },
          }
        : {
            title: "Sold out",
            type: "black secondary pill",
          };
    };

  return (
    <GSItemList
      type="inline-grid medium-large-gap large-pad selectable "
      style={{ ...defaultLayouts.fullWidth }}
      gridColumns={
        isPhone() ? 1 : isTablet() ? 2 : Math.min(3, tournamentPackages?.length)
      }
      items={tournamentPackages}
      itemSelected={(item) => {
        navigate(`package/${item.id}`);
      }}
      listItem={(tp) => {
        let pv = { ...tp, description: false };
        return (
          <PackageLineItem
            className="hover-outline"
            style={{
              ...defaultLayouts.fullWidth,
              ...defaultPadding.xLargePad,
              ...getPackageStyle(tp),
              ...defaultBorders.mediumLargeBorderRadius,
            }}
            {...pv}
            action={getAction(tp)}
          />
        );
      }}
    />
  );


}

export default AvailablePackagesList