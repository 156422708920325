import { GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { getOrderItemCount } from "../../../../models/Registration";
import { moneyWithCommas } from "../../../../helpers/RegexHelper";
import { usePermissions } from "../../../../hooks/permissionHooks";

/**
 * Donation info group.
 * 
 * @typedef Properties
 * @type {object}
 * @property {string} style the style for this component
 * @property {RegistrationOrder} registrationOrder the cart
 *
 * @param {Properties} props
 */

export const summarize = (registrationOrder, showFee) => {

  const itemCount = getOrderItemCount(registrationOrder)

  const donations = registrationOrder?.donationOrderItems

  let summary = ""
  
  if(itemCount > 0 ){
    summary += `${itemCount} Item${itemCount !== 1 ? 's' : ''}`
  }

  if(itemCount > 0 && donations?.length > 0){
    summary += " + "
  }

  if(donations?.length > 0){
    summary += "Donation"
  }

  if(registrationOrder?.registrantCoversFees && showFee){
    summary += " + Fee"
  }

  return summary
}

const CartSubtotalItem = (props) => {


  const permissions = usePermissions()

  const {style, registrationOrder, total, header} = props
  
  const itemCount = getOrderItemCount(registrationOrder)

  const cartInfoSection = {
    sectionItems: [
      {
        type: "headline-3 no-wrap full-width",
        value: header ? header: "Here's what you've got",
      },
      
    ]
  }

  if(itemCount > 0 ){
    cartInfoSection.sectionItems.push(
      {
        type: "secondary no-wrap full-width",
        value: summarize(registrationOrder, permissions.feesEnabled(registrationOrder)),
      },
    )
  }


  const subtotalCostSection = {
    sectionItems: [
      {
        type: "headline-3 no-wrap full-width",
        value: moneyWithCommas(registrationOrder?.totalCost),
      },
    ]
  }

  const totalCostSection = {
    sectionItems: [
      {
        type: "headline-3 no-wrap full-width",
        value: moneyWithCommas(total),
      },
    ]
  }

  const cartInfoColumn ={
    fit: "stretch",
    sections: [
      cartInfoSection
    ],
  }

  const totalCostColumn ={
    sections: [
      totalCostSection
    ],
  }

  const subTotalCostColumn ={
    sections: [
      subtotalCostSection
    ],
  }

  const getGroup = () => {
    let group = [cartInfoColumn]
    if(total){
      group.push(totalCostColumn)
    }
    else{
      group.push(subTotalCostColumn)
    }
    return group
  }

  return <cart-subtotal-item style={style}>
    <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
  </cart-subtotal-item>
}


export default CartSubtotalItem