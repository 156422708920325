import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentExternalLinks,
  selectExternalLinks,
  } from "../reducers/tournamentSlice";
import {
  getActiveNavigation,
  navigationOptions,
  setNavigationItems,
} from "../reducers/appSlice";
import { getRouting } from "../helpers/Converters";
import { eventBaseRoute } from "../routes/event";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "./permissionHooks";
import { faExternalLinkSquare } from "@fortawesome/free-solid-svg-icons";
import { openExternalLink } from "../helpers/Utilities";
import { useTournament } from "./tournamentHooks";

export const AUCTION_LINK = "event_auction";

export const useNavigationButtons = (route) => {
  const tournament = useTournament()
  const externalLinks = useSelector(selectExternalLinks);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auctionLink = externalLinks?.findLast(
    (el) => el.linkType === AUCTION_LINK
  )?.externalLink ?? "";

  useEffect(() => {
    if (tournament?.id) {
      dispatch(getTournamentExternalLinks(tournament?.id));
    }
  }, [tournament?.id, dispatch]);

  
  const {
    packagesEnabled,
    donationsActive,
    hasRounds,
    hideLeaderboards,
    hideRegistrants,
    hideSponsorships,
    hideCourseDetails
  } = usePermissions();

  const packagesAreEnabled = packagesEnabled();
  const donationsAreActive = donationsActive();

  const applicablePermissions = useMemo(() => {
    const p = {
      packagesAreEnabled,
      hideSponsorships,
      hideRegistrants,
      hideCourseDetails,
      hasRounds,
      hideLeaderboards,
      donationsAreActive,
      auctionLink
    };
    return p;
  }, [
    packagesAreEnabled,
    hideSponsorships,
    hideRegistrants,
    hideCourseDetails,
    hasRounds,
    hideLeaderboards,
    donationsAreActive,
    auctionLink
  ]);

  const getPageActions = () => {
    if (!tournament?.id) {
      return [];
    }
    return getActiveNavigation(applicablePermissions)?.map?.((tab) => {
      return {
        ...tab,
        rightIcon: tab.isExternal ? faExternalLinkSquare : false,
        type: tab.route === route ? "grey secondary" : "transparent secondary",
        actionClick: () => {
          if(tab.isExternal ){
            openExternalLink(tab.externalLink?.(applicablePermissions))
          }
          else{
            navigate(getRouting(eventBaseRoute, [tournament?.publicUrl, tab.route]));
          }
        },
      };
    });
  };

  useEffect(() => {
    const navOptions = navigationOptions?.filter?.(
      (o) => !o.disabled(applicablePermissions)
    );

    const tabs = navOptions.map?.((tab) => {
      const link = tab.isExternal ? tab?.externalLink?.(applicablePermissions) : getRouting(eventBaseRoute, [tournament?.publicUrl, tab?.route])
      return {
        label: tab?.buttonTitle,
        isSelectable: true,
        isExternal: tab.isExternal,
        url: link,
      };
    });
    if(tournament?.id){
      dispatch(setNavigationItems(tabs));
    }
    
  }, [dispatch, applicablePermissions, tournament?.publicUrl, tournament?.id]);

  return getPageActions;
};
