import React from "react";
import { useSelector } from "react-redux";
import { selectRegistrationCustomer } from "../../reducers/tournamentSlice";
import { defaultLayouts, defaultTypography, golfstatusTypography, GSButton, GSInfoGroup } from "golfstatus_react_components";
import { useNavigate } from "react-router-dom";
import { getRouting } from "../../helpers/Converters";
import { eventBaseRoute } from "../../routes/event";
import { useTournament } from "../../hooks/tournamentHooks";

const ThankYouWidget = () => {

  const tournament = useTournament()
  const customer = useSelector(selectRegistrationCustomer)

  const navigate = useNavigate()

  const secondaryStyle = { ...defaultTypography.bodyRegular, opacity: 0.7 };

  const thankYouSection = {
    sectionItems: [
      {
        type: "headline-2",
        value: "Thank you!",
      },
    ],
  };

  const confirmSection = {
    sectionItems: [
      {
        type: "secondary",
        value: `You have successfully purchased your order for ${tournament?.name}! A receipt and tournament details have been sent to ${customer?.email} `,
      },
    ],
  };

  const identifySection = {
    sectionItems: [
      {
        type: "",
        value: (
          <div style={{...defaultLayouts.vStack}}>
            <span style={secondaryStyle}>
              To help you identify this transaction, the charge will appear as:
            </span>{" "}
            <span style={{ opacity: 1, fontWeight: golfstatusTypography.boldFontWeight }}>
              GolfStatus Golf Event Lincoln USA
            </span>
          </div>
        ),
      },
    ],
  };

  const questionsSection = {
    sectionItems: [
      {
        type: "secondary",
        value:
          "If you have any questions or concerns regarding your purchase, please don't hesitate to contact our customer support team.",
      },
    ],
  };

  const eventDetailsSection = {
    sectionItems: [
      {
        type: "",
        value: <GSButton type="black" title="View Event Details" onClick={() => {navigate(getRouting(eventBaseRoute, [tournament?.publicUrl]))}} />,
      },
    ],
  };

  const confirmColumn = {
    fit: "stretch",
    sections: [
      thankYouSection,
      confirmSection,
      questionsSection,
      identifySection,
    ],
  };

  const actionColumn = {
    sections: [eventDetailsSection],
  };

  return (
    <GSInfoGroup
      listType="mobile-vertical"
      dataGroups={[confirmColumn, actionColumn]}
    />
  );
}

export default ThankYouWidget