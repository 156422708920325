import {
  GSImage,
  GSInfoGroup,
  GSItemList,
  defaultLayouts,
  useMediaQuery,
} from "golfstatus_react_components";
import React from "react";

/**
 * Donation info group.
 *
 * @typedef Properties
 * @type {object}
 * @property {string} style the style for this component
 *
 * @param {Properties} props
 */

const LeaderboardPresentedBy = (props) => {
  const { techSponsors, secondaryLeaderboardPhoto, style } = props;

  const {isPhone} = useMediaQuery()

  const ts = techSponsors ?? [];

  const sponsors = secondaryLeaderboardPhoto
    ? [...ts, { logo: secondaryLeaderboardPhoto }]
    : ts;

  // const sponsor = ts?.[0];

  // const sponsorInfoSection = {
  //   sectionItems: [
  //     {
  //       type: "overline-2 full-width uppercase",
  //       value: sponsor?.name ?? "",
  //     },
  //   ],
  // };

  const sponsorLogoSection = {
    type: "mobile-stretch",
    sectionItems: [
      {
        type: "full-width",
        value: (
          <GSItemList
            style={{ ...defaultLayouts.fullWidth }}
            type="horizontal medium-gap"
            listStyle={{ justifyContent: "center" }}
            items={sponsors}
            listItem={(item) => (
              <GSImage size={isPhone() ? "x-small" : "small"} ratio="wide" src={item.logo} />
            )}
          />
        ),
      },
    ],
  };

  // const sponsorInfoColumn = {
  //   fit: "stretch hide-mobile",
  //   sections: [sponsorInfoSection],
  // };

  const sposorLogoColumn = {
    fit: "mobile-stretch",
    type: "mobile-stretch",
    sections: [sponsorLogoSection],
  };

  const getGroup = () => {
    let group = [ sposorLogoColumn];
    return group;
  };

  return (
    <leaderboard-presented-by style={style}>
      <GSInfoGroup
        style={{ ...defaultLayouts.fullWidth }}
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </leaderboard-presented-by>
  );
};

export default LeaderboardPresentedBy;
