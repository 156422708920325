import { faCircleCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { defaultTypography, GSButton, GSInfoGroup, GSItemList } from "golfstatus_react_components";
import React from "react";

/**
 * Donation info group.
 *
 * @typedef Properties
 * @type {object}
 * @property {string} formName label of the form
 * @property {boolean} required is this form required
 * @property {Array} actions actions for component
 * @property {string} style the style for this component
 *
 * @param {Properties} props formName, required, style
 */

const CartFormLineItem = (props) => {
  const { formName, required, description, actions, status, style } = props;

  const getStatusIcon = () => {
    switch (status) {
      case "complete":
        return faCircleCheck;
      case "incomplete":
        return faExclamationCircle;
      default:
        return "grey";
    }
  }

  const getStatusStyle = () => {
    switch (status) {
      case "complete":
        return "green";
      case "incomplete":
        return required ? "grey" : "grey";
      default:
        return "grey";
    }
  };

  const formInfoSection = {
    sectionItems: [
      {
        type: " no-wrap full-width",
        style: {...defaultTypography.mediumBoldBody},
        value: formName,
      },
      
    ],
  };

  if(required !== undefined){
    formInfoSection.sectionItems.push({
      type: "secondary no-wrap full-width",
      style: {...defaultTypography.capitalize},
      value: required ? "required" : "optional",
    },)
  }

  if(description){
    formInfoSection.sectionItems.push({
      type: "secondary no-wrap full-width",
      value: description,
    },)
  }

  const formStateSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: (
          <GSButton
            title={status ?? "Incomplete"}
            buttonIcon={getStatusIcon()}
            type={`pill secondary ${getStatusStyle()}`}
          />
        ),
      },
    ],
  };

  const formButtonSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: (
          <GSItemList
            type="horizontal"
            items={actions}
            listItem={(item) => <GSButton {...item} />}
          />
        ),
      },
    ],
  };

  const formInfoColumn = {
    fit: "stretch",
    sections: [formInfoSection, formStateSection],
  };

  const formButtonColumn = {
    type: "mobile-stretch",
    sections: [formButtonSection],
  };

  const getGroup = () => {
    let group = [formInfoColumn, formButtonColumn];
    return group;
  };

  return (
    <cart-form-line-item style={style}>
      <GSInfoGroup
        listType="horizontal mobile-vertical"
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </cart-form-line-item>
  );
};

export default CartFormLineItem;
