import React, { useEffect } from "react";
import {
  GSDivider,
  GSItemList,
  GSScorecard,
  GSSidePanelPage,
  ScoreTotal,
  defaultLayouts,
  defaultPadding,
  useMediaQuery,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./tournament-leaderboard-result.scss";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { getRouting } from "../../../../helpers/Converters";
import {
  LEADERBOARD_ID,
  TEAM_KEY,
  TOURNAMENT_ID,
  eventBaseRoute,
  getProp,
} from "../../../../routes/event";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeaderboardFlights,
  getPublicLeaderboards,
  selectLeaderboard,
  selectLeaderboards,
  setLeaderboard,
} from "../../../../reducers/leaderboardSlice";
import TournamentLeaderboardResultLineItem from "./tournament-leaderboard-result-line-item";
import { useTournament } from "../../../../hooks/tournamentHooks";

//Name the component
const TournamentLeaderboardResult = (props) => {
  const dispatch = useDispatch();

  const { isTablet, isPhone } = useMediaQuery();

  const navigate = useNavigate();
  const notifications = [];

  const route = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "leaderboards",
    LEADERBOARD_ID,
    "results",
    TEAM_KEY,
  ]);
  const match = useMatch(route);
  
  const leaderboardID = getProp(match?.params, LEADERBOARD_ID);
  const teamKey = getProp(match?.params, TEAM_KEY);

  const leaderboards = useSelector(selectLeaderboards);
  const leaderboard = useSelector(selectLeaderboard);

  const tournament = useTournament();
  const tournamentID = tournament?.id;

  useEffect(() => {
    if (tournamentID && leaderboardID !== leaderboard?.id) {
      dispatch(getLeaderboardFlights(tournamentID));
      dispatch(getPublicLeaderboards(tournamentID));
    }
  }, [dispatch, tournamentID, leaderboardID, leaderboard?.id]);

  useEffect(() => {
    if (leaderboardID && leaderboard?.id !== leaderboardID) {
      const lb = leaderboards?.find?.((l) => l?.id === leaderboardID);
      dispatch(setLeaderboard(lb));
    }
  }, [dispatch, leaderboards, leaderboardID, leaderboard?.id]);

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Results`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getResult = (leaderbaord) => {
    let result = {};
    if (leaderbaord?.type === "round_match") {
      result = leaderbaord?.scores?.find?.((s) => s.teamKeys.includes(teamKey));
    } else {
      result = leaderbaord?.scores?.find?.((s) => s.teamKey === teamKey);
    }
    return result;
  };

  const getScorecards = (result, showPoints) => {
    if (result?.rounds) {
      return (
        <GSItemList
          type="vertical xx-large-gap"
          items={result?.rounds}
          style={{ ...defaultLayouts.fullWidth }}
          listItem={(round) => (
            <div
              style={{ ...defaultLayouts.fullWidth, ...defaultLayouts.vStack }}
            >
              <GSScorecard
                style={{
                  ...defaultLayouts.fullWidth,
                  ...defaultPadding.smallLayoutPad.apply("vertical"),
                  ...defaultPadding.xLargePad.apply("horizontal"),
                }}
                nineStyle={{ ...defaultLayouts.fullWidth }}
                totalScore={
                  <ScoreTotal scores={round.roundScore.scores}></ScoreTotal>
                }
                splitNines={isTablet() || isPhone()}
                user={{ name: round.roundDisplayName }}
                holes={round.roundScore?.scores}
                showPoints={showPoints}
                startingHole={round?.startingHole}
                showTotalColumn
                playerScores={
                  round?.playerScores?.length > 0 ? round?.playerScores : []
                }
              />
              <GSDivider />
            </div>
          )}
        ></GSItemList>
      );
    } else if (result?.roundScore) {
      return (
        <div className="round-score">
          <GSScorecard
            style={{ ...defaultLayouts.fullWidth, ...defaultPadding.xLargePad }}
            nineStyle={{ ...defaultLayouts.fullWidth }}
            totalScore={
              <ScoreTotal scores={result?.roundScore?.scores}></ScoreTotal>
            }
            splitNines={isTablet() || isPhone()}
            showTotalColumn
            user={{ name: result?.roundDisplayName }}
            holes={result?.roundScore?.scores}
            showPoints={showPoints}
            startingHole={result?.startingHole}
            playerScores={
              result.playerScores?.length > 0 ? result.playerScores : []
            }
          />
          <GSDivider />
        </div>
      );
    } else if (result?.matchScores) {
      return (
        <div
          className="match-scores"
          style={{ ...defaultLayouts.fullWidth, ...defaultLayouts.vStack }}
        >
          <GSScorecard
            style={{
              ...defaultLayouts.fullWidth,
              ...defaultPadding.smallLayoutPad.apply("vertical"),
              ...defaultPadding.xLargePad.apply("horizontal"),
            }}
            nineStyle={{ ...defaultLayouts.fullWidth }}
            holes={result?.matchScores?.[0].roundScore.scores}
            splitNines={isTablet() || isPhone()}
            startingHole={result.startingHole}
            showTotalColumn
            match={result}
          ></GSScorecard>
          <GSDivider />
          <GSItemList
            type="vertical xx-large-gap"
            items={result?.matchScores}
            style={{ ...defaultLayouts.fullWidth }}
            listItem={(round) => (
              <div
                style={{
                  ...defaultLayouts.fullWidth,
                  ...defaultLayouts.vStack,
                }}
              >
                <GSScorecard
                  style={{
                    ...defaultLayouts.fullWidth,
                    ...defaultPadding.smallLayoutPad.apply("vertical"),
                    ...defaultPadding.xLargePad.apply("horizontal"),
                  }}
                  nineStyle={{ ...defaultLayouts.fullWidth }}
                  totalScore={
                    <ScoreTotal scores={round.roundScore.scores}></ScoreTotal>
                  }
                  splitNines={isTablet() || isPhone()}
                  user={{ name: round.name }}
                  holes={round.roundScore?.scores}
                  showPoints={showPoints}
                  startingHole={round?.startingHole}
                  showTotalColumn
                  playerScores={
                    round?.playerScores?.length > 0 ? round?.playerScores : []
                  }
                ></GSScorecard>
                <GSDivider />
              </div>
            )}
          ></GSItemList>
          <GSDivider />
        </div>
      );
    }
  };

  const getContent = () => {
    let result = getResult(leaderboard);
    let showPoints = [
      "match_play",
      "stableford",
      "modified_stableford",
    ].includes(leaderboard?.scoringSystem);
    return (
      <gs-form style={{ ...defaultLayouts.vStack }}>
        <TournamentLeaderboardResultLineItem
          leaderboard={leaderboard}
          {...result}
        ></TournamentLeaderboardResultLineItem>
        <GSDivider style={{minHeight: "1px"}}/>
        {getScorecards(result, showPoints)}
      </gs-form>
    );
  };

  const timeoutAction = () => {};

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <tournament-leaderboard-result>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
      ></GSSidePanelPage>
    </tournament-leaderboard-result>
  );
};

//export the new namet
export default TournamentLeaderboardResult;
