
export const getDonationForm = (context) => {
  const form = [
    {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Donation Message",
          isEditable: true,
          type:"text-area",
          rows: 10,
          placeholder: "Donation Message...",
          ...context.setBinding?.("notes")
        }
      ]
    }

  ]
  return form
}