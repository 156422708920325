
import React from "react";
import { GSForm, GSSidePanelPage, useFormDataValidation } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./donation-item-form.scss"
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { getDonationForm } from "../../../../forms/DonationForm";
import { useDispatch, useSelector } from "react-redux";
import { selectRegistrationOrder, updateOrder } from "../../../../reducers/tournamentSlice";

//Name the component
const DonationItemForm = (props) => {

  const registrationOrder = useSelector(selectRegistrationOrder)

  const dispatch = useDispatch()

  const donation = registrationOrder?.donationOrderItems?.find?.(d => !d.roundUp)

  const [context, isValid, setIsValid] = useFormDataValidation({
    initalValid: false,
    setData: (update) => {
      dispatch(updateOrder(
        {...registrationOrder, donationOrderItems: [update]}
      ))
    },
    data: donation
  })

  const navigate = useNavigate()
  const notifications = []

  const save = () => {
    setIsValid(false)
    leftNavigation()
  }

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save & Continue",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Donation Message`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return <GSForm formTitle="Donation Message" formSections={getDonationForm(context)}></GSForm>;
  };

  const timeoutAction = () => {
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({notifications, timeoutAction})

  return (
    //name the component tag
    <donation-item-form>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </donation-item-form>
  );
};

//export the new namet
export default DonationItemForm;