import React from "react";
import AvailablePackagesList from "../events/event/packages/available-packages-list";
import { useTournamentPackages } from "../../hooks/tournamentHooks";

const AvailableSponsorshipsWidget = () => {
  const {sponsorPackages} = useTournamentPackages()

  return <AvailablePackagesList tournamentPackages={sponsorPackages} />;
};

export default AvailableSponsorshipsWidget;
