import React from 'react';
import './payment-option.scss';
import {GSButton, GSInfoGroup} from "golfstatus_react_components";

const PaymentOption = ({ feeLabel, icon, title, subTitle, displayFees, style }) => {

  const getDataGroups = () => {
    let dataGroups = [
      {
        sections: [
          {
            sectionItems: [
              {
                type: "primary no-wrap",
                value: <GSButton buttonIcon={icon}/>,
              },
            ],
          },
        ],
      },
      {
        fit: "stretch",
        sections: [
          {
            sectionItems: [
              {
                type: "primary",
                value: title,
              },
            ],
          },
        ],
      },
    ];
    if (displayFees?.()) {
      dataGroups.push({
        fit: "no-wrap",
        sections: [
          {
            sectionItems: [
              {
                type: "secondary",
                value: feeLabel,
              },
            ],
          },
        ],
      });
    }
    if (subTitle) {
      dataGroups[1].sections.push({
        sectionItems: [
          {
            type: "secondary",
            value: subTitle,
          },
        ],
      });
    }

    return dataGroups;
  };

  return (
    <payment-option style={style}>
      <GSInfoGroup type="list-item x-large-pad" listType={"payment-option"} dataGroups={getDataGroups()} />
    </payment-option>
  );
};

export default PaymentOption;
