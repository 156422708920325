import React from "react";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../../reducers/appSlice";
import {
  defaultGapping,
  defaultLayouts,
  defaultTypography,
  golfstatusTypography,
} from "golfstatus_react_components";
import {
  selectRegistrationCustomer,
  selectRegistrationOrder,
} from "../../reducers/tournamentSlice";
import PaymentMethodDetails from "../events/event/cart/payment-method-details";
import {
  BANK_ACCOUNT,
  getActivePaymentMethods,
  isBankAttached,
  isCardAttached,
} from "../../forms/CartSections";
import CartFormLineItem from "../events/event/cart/cart-form-line-item";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import CustomerLineItem from "../events/event/cart/customer-line-item";
import { usePaymentOptions } from "../../hooks/tournamentHooks";
import { usePermissions } from "../../hooks/permissionHooks";
const PaymentDetailsWidget = () => {
  const navigate = useNavigate();

  const currentUser = useSelector(selectLoggedInUser);
  const customer = useSelector(selectRegistrationCustomer);
  const registrationOrder = useSelector(selectRegistrationOrder);

  const permissions = usePermissions();

  const savedPaymentOptions = usePaymentOptions().availablePayments;
  const savedPaymentOption = savedPaymentOptions?.find?.(
    (po) => po?.id === registrationOrder?.paymentOptionKey
  );

  const lastBank = savedPaymentOptions?.findLast?.((po) => {
    return po.paymentType === BANK_ACCOUNT;
  });

  const currentPaymentMethod = getActivePaymentMethods({
    ...permissions,
    hasBankAccountAttached: !!lastBank,
  }).find?.((m) => m.type === registrationOrder?.paymentType);

  

  const cardIsAttached = isCardAttached(registrationOrder);
  const bankIsAttached = isBankAttached(registrationOrder);

  const label = currentUser?.id ? null : (
    <div>
      {"Add a card or  "}
      <span
        onClick={() => {
          window.location.href = process.env.REACT_APP_GOLFSTATUS_LOGIN_URL;
        }}
        style={{
          fontWeight: golfstatusTypography.boldFontWeight,
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {"Sign In "}
      </span>
      {"to use a saved card."}
    </div>
  );

  const bankLabel = currentUser?.id ? null : (
    <div>
      {"Add a bank account or  "}
      <span
        onClick={() => {
          window.location.href = process.env.REACT_APP_GOLFSTATUS_LOGIN_URL;
        }}
        style={{
          fontWeight: golfstatusTypography.boldFontWeight,
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {"Sign In "}
      </span>
      {"to use saved payment methods."}
    </div>
  );

  const getCardDescription = () => {
    const { card } = registrationOrder;
    if (savedPaymentOption?.id) {
      return savedPaymentOption.subTitle;
    }
    if (registrationOrder?.internationalCardUsed) {
      return (
        <div
          style={{
            ...defaultLayouts.hStack.align("center", "center"),
            ...defaultGapping.smallGap,
            ...defaultLayouts.fullWidth,
            textWrap: "wrap",
          }}
        >
          {"This is an international card and is subject to additional fees."}
        </div>
      );
    }
    if (card) {
      return (
        <div
          style={{
            ...defaultLayouts.vStack.align("flex-start", "center"),
            ...defaultGapping.noGap,
            ...defaultLayouts.fullWidth,
            textWrap: "wrap",
          }}
        >
          <div>
            {card.brand} ending in {card.last4}
          </div>
        </div>
      );
    }
  };

  switch (currentPaymentMethod?.id) {
    case 1:
      return (
        <PaymentMethodDetails
          label="Credit/Debit Card"
          description={label}
          isPaymentAttached={cardIsAttached}
          content={
            <CartFormLineItem
              formName="Credit/Debit Card Details"
              required={true}
              status={cardIsAttached ? "complete" : "incomplete"}
              description={getCardDescription()}
              actions={[
                {
                  type: cardIsAttached ? "grey" : "orange",
                  title: cardIsAttached ? "Edit" : "Add Card",
                  style: { flexGrow: 1 },
                  buttonIcon: faPen,
                  onClick: () => {
                    navigate("card");
                  },
                },
              ]}
            />
          }
        />
      );
    case 2:
      return (
        <PaymentMethodDetails
          label="Bank Account"
          description={bankLabel}
          isPaymentAttached={bankIsAttached}
          content={
            <CartFormLineItem
              formName="Bank Account Details"
              required={true}
              status={bankIsAttached ? "complete" : "incomplete"}
              description={
                savedPaymentOption?.id ? savedPaymentOption.subTitle : null
              }
              actions={[
                {
                  type: bankIsAttached ? "grey" : "orange",
                  title: bankIsAttached ? "Edit" : "Add Account",
                  buttonIcon: faPen,
                  style: { flexGrow: 1 },
                  onClick: () => {
                    navigate("bank");
                  },
                },
              ]}
            />
          }
        />
      );
    case 3:
      return (
        <PaymentMethodDetails
          label="Payment Disclaimer"
          description={
            "You are agreeing to register for the Invoice Status Testing event. By tapping “Confirm Order” you acknowledge your obligation to pay the total to the tournament organizer. "
          }
        />
      );
    case 4:
      return (
        <PaymentMethodDetails
          isPaymentAttached={true}
          label="Invoice"
          description={
            "The invoice will be sent to the person below and will include the due date and payment instructions."
          }
          content={
            <CustomerLineItem
              {...customer}
              nameStyle={{ ...defaultTypography.mediumBoldBody }}
              editClick={() => {
                navigate("customer");
              }}
            />
          }
        />
      );
    default:
      return (
        <PaymentMethodDetails
          isPaymentAttached={true}
          label="Please Select A Payment Method"
          description={
            "Please select your preferred payment method from the options above."
          }
        />
      );
  }
};

export default PaymentDetailsWidget;
