import React from "react";
import './customer-invoice-summary.scss';
import {GSButton, GSImage, GSInfoGroup} from "golfstatus_react_components";
import moment from "moment/moment";

const CustomerInvoiceSummary = ({details, style}) => {

  const isInvoicePaid = () => {
    return details?.registrationOrder?.status === "paid";
  };

  const isInvoiceVoid = () => {
    return details?.registrationOrder?.status === "void";
  };

  const getPaymentStatus = () => {
    let status = "Unpaid";
    if (isInvoicePaid()) {
      status = "Paid";
    } else if (isInvoiceVoid()) {
      status = "Void";
    }
    return status;
  };

  const getPaymentStatusDate = () => {
    if (isInvoicePaid()) {
      return `Paid on ${moment(details?.registrationOrder?.invoicePaidAt).format("MMM DD, YYYY")}`;
    } else if (isInvoiceVoid()) {
      return `Voided on ${moment(details?.registrationOrder?.updatedAt).format("MMM DD, YYYY")}`;
    }
    return `Due on ${moment(details?.invoice?.dueDate).format("MMM DD, YYYY")}`;
  };

  const tournamentDetails = {
    fit: "stretch",
    sections: [
      {
        sectionItems: [
          {
            type: "primary small-gap",
            value: <GSImage size="medium wide" src={details?.tournament?.logo} />,
          },
          {
            type: "primary",
            value: `${details?.tournament?.name} Invoice`
          },
          {
            type: "secondary no-wrap",
            value: details?.organization?.name
          },
        ],
      },
    ],
  };

  const paymentDetails = {
    sections: [
      {
        sectionItems: [
          {
            type: "secondary no-wrap right small-gap",
            value: <GSButton title={getPaymentStatus()} isPill={true} type={isInvoicePaid() ? "green" : "grey"} />,
          },
          {
            type: "secondary no-wrap right",
            value: `Invoice #${details?.registrationOrder?.id.toString().padStart(8, '0')}`,
          },
          {
            type: "secondary no-wrap right",
            value: `Issued on ${moment(details?.registrationOrder?.createdAt).format("MMM DD, YYYY")}`,
          },
          {
            type: isInvoicePaid() ? "secondary no-wrap right small-gap" : "secondary no-wrap right",
            value: getPaymentStatusDate(),
          },
        ],
      },
    ],
  };

  const getInvoiceInfo = () => {
    let payment = paymentDetails;
    if (isInvoicePaid()) {
      // if customer invoice was marked as paid, these values will be null
      if (details?.registrationOrder?.cardBrand) {
        payment.sections[0].sectionItems.push({
          type: "secondary no-wrap right",
          value: details?.registrationOrder?.cardBrand,
        });
      }
      if (details?.registrationOrder?.last4) {
        payment.sections[0].sectionItems.push({
          type: "secondary no-wrap right",
          value: `Ending in *${details?.registrationOrder?.last4}`,
        });
      }
    }
    let groups = [
      { ...tournamentDetails },
      { ...payment },
    ];

    return (
      <GSInfoGroup dataGroups={groups} listType={"details"} />
    );
  };

  return (
    <customer-invoice-summary style={style}>
      {getInvoiceInfo()}
    </customer-invoice-summary>
  );
};

export default CustomerInvoiceSummary;
