import {
  GSButton,
  GSImage,
  GSInfoGroup,
  GSItemList,
} from "golfstatus_react_components";
import React from "react";
import "./organization-hero.scss";
import { faExternalLinkSquare } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { openExternalLink } from "../../../helpers/Utilities";
/**
 * Donation info group.
 *
 * @typedef Properties
 * @type {object}
 * @property {string} style the style for this component
 *
 * @param {Properties} props
 */

const OrganizationHero = (props) => {
  const { logo, name, organizationAdditionalLinks, style } = props;

  const getIcon = (link) => {
    switch (link?.type) {
      case "instagram":
        return faInstagram;
      case "twitter":
        return faXTwitter;
      case "facebook":
        return faFacebook;
      default:
        return faExternalLinkSquare;
    }
  };

  const logoSection = {
    sectionItems: [
      {
        type: "",
        value: <GSImage src={logo} size="medium" ratio="wide" />,
      },
    ],
  };

  const organizationInformationSection = {
    sectionItems: [
      {
        type: "headline-1",
        value: name,
      },
    ],
  };

  const organizationLinksSection = {
    sectionItems: [
      {
        type: "",
        value: (
          <GSItemList
            type="horizontal medium-large-gap"
            items={organizationAdditionalLinks}
            listItem={(link) => (
              <GSButton
                buttonIcon={getIcon(link)}
                type="grey"
                onClick={() => {
                  openExternalLink(link.website);
                }}
              />
            )}
          />
        ),
      },
    ],
  };

  const logoColumn = {
    fit: "stretch",
    sections: [logoSection, organizationInformationSection],
  };

  const organizationInfoColumn = {
    sections: [organizationLinksSection],
  };


  const getGroup = () => {
    let group = [logoColumn, organizationInfoColumn];
    return group;
  };

  return (
    <organization-hero style={style}>
      <GSInfoGroup
        listType="mobile-vertical"
        dataGroups={getGroup()}
      ></GSInfoGroup>
    </organization-hero>
  );
};

export default OrganizationHero;
