import { GSInfoGroup, defaultLayouts } from "golfstatus_react_components";
import React from "react";

/**
 * Donation info group.
 * 
 * @typedef Properties
 * @type {object}
 *  @property {string} label 
 *  @property {string} description 
 *  @property {object} content 
 *  @property {string} value 
 * @property {string} style the style for this component
 *
 * @param {Properties} props label, description, content, value, style
 */

const SummaryLineItem = (props) => {

  const {label, description, content, value, valueDescription, style} = props
  
  const labelSection = {
    sectionItems: [
      {
        type: "primary  full-width",
        value: label,
      },
      
    ]
  }

  if(description){
    labelSection.sectionItems.push(
      {
        type: "secondary full-width",
        value: description,
      }
    )
  }

  const contentSection = {
    sectionItems: [
      {
        type: "full-width",
        value: content,
      },
    ]
  }

  const valueSection = {
    sectionItems: [
      {
        type: "primary  full-width",
        value: value,
      },
    ]
  }

  const valueDescriptionSection = {
    sectionItems: [
      {
        type: "secondary full-width",
        value: valueDescription,
      },
    ]
  }

  const contentColumn ={
    fit: "stretch",
    style: {...defaultLayouts.fullWidth},
    sections: [
      labelSection
    ],
  }

  if(content){
    contentColumn.sections.push(contentSection)
  }

  const valueColumn = {
    sections: valueDescription ? [valueSection, valueDescriptionSection] : [
      valueSection
    ]
  }

  const getGroup = () => {
    let group = [contentColumn]
    if(value){
      group.push(valueColumn)
    }
    return group
  }

  return <summary-line-item style={style}>
    <GSInfoGroup style={{...defaultLayouts.fullWidth}} dataGroups={getGroup()}></GSInfoGroup>
  </summary-line-item>
}


export default SummaryLineItem