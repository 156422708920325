import {
  defaultLayouts,
  defaultGapping,
  useMediaQuery,
  GSSplitView,
} from "golfstatus_react_components";
import React from "react";
import { useSelector } from "react-redux";
import { selectPublicTournament } from "../../reducers/tournamentSlice";
import { openExternalLink } from "../../helpers/Utilities";

import appStore from "../../images/AppStore.png"
import googlePlay from "../../images/GooglePlay.png"

const DownloadAppWidget = () => {
  const tournament = useSelector(selectPublicTournament);
  const { isPhone, isTablet } = useMediaQuery();

  const getLayout = () => {
    if (isPhone() || isTablet()) {
      return defaultLayouts.hStack.align("center", "center");
    }
    return defaultLayouts.hStack.align("center", "center");
  };

  const getLinks = () => {
    return (
      <div
        style={{
          ...defaultLayouts.vStack.align("center", "center"),
          ...defaultLayouts.fill,
          ...defaultGapping.xLargeGap,
        }}
      >
        <div style={{opacity: .7, textAlign: "center"}}>
          Download the free GolfStatus App to live score {tournament?.name}!
        </div>
        <div
          style={{
            ...getLayout(),
            ...defaultGapping.mediumLargeGap,
            ...defaultLayouts.fullWidth,
          }}
        >
          <img alt="Google Play Store" style={{ height: "40px"}} className="hover-color-shift" src={googlePlay} onClick={() => {
              openExternalLink(
                "https://play.google.com/store/apps/details?id=com.golfstatus.iphoneapp&hl=en_US"
              );
            }} />
          <img style={{height: "40px"}} className="hover-color-shift" src={appStore} alt="Apple App Store" onClick={() => {
              openExternalLink(
                "https://apps.apple.com/us/app/golfstatus/id518185490"
              );
            }}/>
        </div>
      </div>
    );
  };

  const getImage = () => {
    return (
      <div
        style={{
          ...defaultLayouts.fullWidth,
          ...defaultLayouts.vStack.align("center", "center"),
        }}
      >
        <img
          src="https://images.squarespace-cdn.com/content/v1/5cc21844fb18203030916d32/1890f0c4-0bd0-4131-b81b-eed2a0784948/3-Score.gif?format=1500w"
          style={{ background: "transparent", border: "none", height: "560px" , objectFit: "contain"}}
          alt="Download GolfStatus"
        />
      </div>
    );
  };
  return (
    <GSSplitView
      dividerStyle={{ width: "0" }}
      style={{ ...defaultLayouts.fullWidth }}
      left={getImage()}
      right={getLinks()}
    />
  );
};

export default DownloadAppWidget;
