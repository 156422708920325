import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../reducers/appSlice';
import invoiceReducer from '../reducers/invoiceSlice';
import userReducer from '../reducers/userSlice';
import tournamentReducer  from '../reducers/tournamentSlice'
import organizationSlice from '../reducers/organizationSlice';
import leaderboardSlice from '../reducers/leaderboardSlice';
import utilitySlice from '../reducers/utilitySlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    invoice: invoiceReducer,
    user: userReducer,
    tournaments: tournamentReducer,
    organization: organizationSlice,
    leaderboards: leaderboardSlice,
    utilities: utilitySlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
