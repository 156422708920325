import React from "react";
import { useNavigate } from "react-router-dom";
import CustomerLineItem from "../events/event/cart/customer-line-item";
import UserCheckoutInfo from "../events/event/cart/user-checkout-info";
import { useSelector } from "react-redux";
import { selectRegistrationCustomer } from "../../reducers/tournamentSlice";

const CartContactWidget = () => {

  const customer = useSelector(selectRegistrationCustomer);
  const navigate = useNavigate()


  if(customer.firstName) {
    return <CustomerLineItem
    {...customer}
    editClick={() => {
      navigate("customer");
    }}
  />
  } 

  return <UserCheckoutInfo/>
}

export default CartContactWidget