import React, { useEffect } from "react";
import {
  GSForm,
  GSSidePanelPage,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./registration-waitlist-confirmation.scss";
import {
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  selectNotifications,
  selectWaitlistDetails,
} from "../../../../reducers/tournamentSlice";
import {
  confirmationForm,
  confirmationTitle,
} from "../../../../forms/ConfirmationForm";
import WaitListMemberItem from "./wait-list-member-item";
import { useTheme } from "../../../../hooks/themeHooks";

//Name the component
const RegistrationWaitlistConfirmation = (props) => {
  const waitlistDetails = useSelector(selectWaitlistDetails);

  const dispatch = useDispatch();

  const [,theme, getStyle] = useTheme()
  const navigate = useNavigate();
  const notifications = useSelector(selectNotifications).filter?.(
    (n) => n.id === "joinWaitList"
  );

  const getDrawerActions = () => {
    let actions = [{ name: "close", action: leftNavigation, type: "grey" }];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  useEffect(() => {return () => {dispatch(clearNotifications())}},[dispatch])

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Waitlist Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle={confirmationTitle("Waitlist Confirmation", getStyle(theme.tertiary) )}
        formSections={confirmationForm(
          "Wait List Confirmation",
          <WaitListMemberItem {...waitlistDetails} />,
          "You’ve been added to the waitlist! If a spot opens up, the tournament organizer will contact you.",
          getStyle(theme.tertiary).color
        )}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <registration-waitlist-confirmation>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </registration-waitlist-confirmation>
  );
};

//export the new namet
export default RegistrationWaitlistConfirmation;
