
import { GSRadioGroup, GSSelect, validations } from "golfstatus_react_components";
import { teeLocations } from "./PackageForm";

export const getWaitlistForm = (context) => {
  const form = [];

  const { waitListType } = context?.getData?.();

  const waitlistDetails = {
    type: "vertical xx-large-gap",
    fields: [
      {
        label: "First Name",
        placeholder: "First Name",
        ...context?.setBinding?.("firstName"),
        isEditable: true,
        required: true
      },
      {
        label: "Last Name",
        placeholder: "Last Name",
        ...context?.setBinding?.("lastName"),
        isEditable: true,
        required: true
      },
      {
        label: "Email Address",
        placeholder: "Email Address",
        ...context?.setBinding?.("email"),
        validation: {
          isValid: validations.validEmail,
          invalidLabel: "Must be a valid email.",
        },
        isEditable: true,
        required: true
      },
      {
        label: "Phone Number",
        placeholder: "Phone Number",
        ...context?.setBinding?.("phone"),
        validation: {
          isValid: validations.validPhone,
          invalidLabel: "Must be a valid phone.",
        },
        isEditable: true,
        required: true
      },
      
    ],
  };

  if(context?.tournament?.handicapScoring){
    waitlistDetails.fields.push(
      {
        label: "GHIN Number",
        isEditable: true,
        placeholder: "GHIN Number",
        ...context.setBinding("ghinNumber"),
        hintText: "Enter GHIN number without dashes.",
      },
      {
        label: "Select A Tee Location",
        value: (
          <GSSelect
            options={teeLocations}
            placeholder="Select A Tee Location..."
            selectedOption={teeLocations?.find?.(t => t.value === context?.getData?.()?.teePreference)}
            onChange={(option) => {
              context?.updateData?.(
                option.value,
                "teePreference"
              );
            }}
          />
        ),
      }
    )
  }

  waitlistDetails.fields.push(
    {
      label: "Join Waitlist As",
      isEditable: true,
      value: (
        <GSRadioGroup
          options={[
            { label: "Team", value: "team" },
            { label: "Individual Player", value: "individual" },
          ]}
          selectedOption={{ value: waitListType }}
          selectionChanged={(option) => {context.updateData?.(option?.value, "waitListType")}}
          isLtr
        />
      ),
      customView: true,
    },
    {
      label: "Message",
      placeholder: "Message",
      type: "text-area",
      rows: 4,
      ...context?.setBinding?.("notes"),
      isEditable: true,
    },
  )

  form.push(waitlistDetails);
  return form;
};
