import { useNavigate } from "react-router-dom";
import EventDetailsItem from "../events/event-details-item";
import RegistrationLineItem from "../events/registration-line-item";

import {
  defaultLayouts,
  defaultGapping,
  GSDivider,
  GSLoadingSpinnerOverlay,
  defaultPadding,
} from "golfstatus_react_components";

import React from "react";
import { useSelector } from "react-redux";
import {
  selectLoading,
  selectPublicTournament,
} from "../../reducers/tournamentSlice";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { selectTheme } from "../../reducers/appSlice";
import TournamentDonationWidget from "./tournament-donation-widget";
import { usePermissions } from "../../hooks/permissionHooks";
import { useTournamentPackages } from "../../hooks/tournamentHooks";

const TournamentDetailsWidget = (props) => {
  const navigate = useNavigate();

  const tournament = useSelector(selectPublicTournament);
  const loading = useSelector(selectLoading)

  const {accessiblePackages} = useTournamentPackages();
  

  const mode = useSelector(selectTheme);

  const permissions = usePermissions()

  const getActionSection = () => {
    if (permissions.packagesEnabled()) {
      return <>
      <GSDivider mode={mode} />
      <RegistrationLineItem
          cheapestTeamPackageCost={tournament.cheapestTeamPackageCost}
          packagesAvailable={accessiblePackages?.length}
          registrationAction={{
            title: "register now",
            rightIcon: faArrowRight,
            type: "black",
            onClick: () => {
              navigate("packages");
            },
          }}
        />
      </>
    }
    else if (permissions.donationsActive()){
      return <>
      <GSDivider mode={mode} />
      <TournamentDonationWidget/>
    </>
    }

    return null
  };

  if(!tournament?.id && loading?.length > 0){
    return <GSLoadingSpinnerOverlay mainText="Loading..." style={{...defaultLayouts.vStack.align("center", "center"), ...defaultPadding.emptyListPad, ...defaultLayouts.fill}}/>
  }
  
  return (
    <div
      style={{
        ...defaultLayouts.vStack,
        ...defaultLayouts.fullWidth,
        ...defaultGapping.xLargeGap,
      }}
    >
      <EventDetailsItem {...tournament} addLogos={true} />
      {getActionSection()}
    </div>
  );
};

export default TournamentDetailsWidget;
