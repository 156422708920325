import { faArrowRight, faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  defaultBorders,
  defaultGapping,
  defaultLayouts,
  defaultPadding,
  defaultTypography,
  GSEmptyList,
  GSInput,
  GSItemList,
} from "golfstatus_react_components";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectFlights, selectLeaderboard, selectLeaderboards, selectLoading } from "../../reducers/leaderboardSlice";
import { useNavigate } from "react-router-dom";
import { getRouting } from "../../helpers/Converters";
import { eventBaseRoute } from "../../routes/event";

import TournamentLeaderboardResultLineItem from "../events/event/leaderboards/tournament-leaderboard-result-line-item";
import { useTheme } from "../../hooks/themeHooks";
import { getDateTimeOn } from "../../helpers/Dates";
import { useTournament } from "../../hooks/tournamentHooks";

const LeaderboardWidget = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate()

  const [,theme, getStyle] = useTheme()

  const tournament =  useTournament()
  const leaderboards = useSelector(selectLeaderboards);
  const currentLeaderboard = useSelector(selectLeaderboard);
  const tournamentFlights = useSelector(selectFlights);
  const currentFlights = tournamentFlights?.filter?.(
    (f) =>
      f.leaderboardTypeSetting?.id ===
      currentLeaderboard?.leaderboardTypeSetting?.id
  );
  const loading = useSelector(selectLoading)

  const getName = (score) => {
    return score?.name?.toLowerCase?.();
  };

  const getSkinName = (score) => {
    return score?.winner?.toLowerCase?.();
  };

  const getMatchName = (score) => {
    const teams = score?.matchScores?.flatMap?.((ms) => ms?.playerHash);
    const teamNames = teams?.map((t) => t.name?.toLowerCase?.());
    return teamNames?.join?.(" ");
  };

  let flights = [];

  const getScores = (leaderboard) => {
    const skins = leaderboard?.scoresAbbrev?.filter?.((s) =>
      getSkinName(s)?.includes?.(search?.toLowerCase?.())
    );
    const results =
      leaderboard?.scoringSystem === "match_play" &&
      leaderboard?.type === "round_match"
        ? leaderboard.scores?.filter?.((s) =>
            getMatchName(s)?.includes?.(search?.toLowerCase?.())
          )
        : leaderboard?.scores?.filter?.((s) =>
            getName(s)?.includes?.(search?.toLowerCase?.())
          );
    return skins?.length > 0 ? skins?.filter?.(s => s.winner !== "-").map?.(s => {return {...s, skin: true}}) : results;
  };

  const selectTeam = (team) => {
      const key = team?.teamKey ?? team?.teamKeys?.[0];
      navigate(
        getRouting(eventBaseRoute, [
          tournament?.publicUrl,
          "leaderboards",
          currentLeaderboard?.id,
          "results",
          key,
        ])
      );
    };

  const getTeamIndex = (flight) => {
    const index = getScores(currentLeaderboard)?.findIndex?.(
      (s) => s.teamKey === flight?.teamFlights?.[0]?.teamScores?.teamKey
    );
    return index;
  };


  if (currentFlights?.length > 0 && currentLeaderboard?.type !== "skins") {
    flights = currentFlights?.map?.((f, index) => ({
      index: getTeamIndex(f),
      view: (
        <div
          style={{
            ...defaultTypography.headline1,
            ...defaultPadding.xLargePad,
          }}
        >{`${f.name} Flight`}</div>
      ),
    }));
  }


  const getEmptyListMessage = () => {
    if(currentLeaderboard?.id){
      return <GSEmptyList
      title={currentLeaderboard.name}
      detail={`This leaderboard does not yet have results to show.`}
      actions={leaderboards?.length > 0 ? [
        {
          title: "Change Leaderboard",
          type: "grey",
          rightIcon: faArrowRight,
          onClick: () => {navigate("select")},
        },
      ] : []}
    />
    }
    return (
      <GSEmptyList
        title="No Leaderboards Yet"
        detail={`Leaderboards will become available just before the tournament starts${tournament?.startAt ? ` at ${getDateTimeOn(tournament?.startAt)}` : ""}`}
        actions={[
          {
            title: "View Event Details",
            type: "grey",
            onClick: () => {navigate(getRouting(eventBaseRoute, [tournament?.publicUrl]))},
          },
        ]}
      />
    );
  };

  const getEmptySearchMessage = () => {
    return (
      <GSEmptyList
        title="We couldn’t find who you’re looking for…"
        detail="Try changing the search term."
        actions={[
          {
            title: "Clear Search",
            type: "grey",
            onClick: () => {setSearch("")},
          },
        ]}
      />
    );
  };

  return (
    <div style={{...defaultGapping.xxLargeGap, ...defaultLayouts.vStack, ...defaultLayouts.fullWidth}}>
      {currentLeaderboard?.scores?.length > 0 ?
      <GSInput
      textValue={search}
      rightIconClick={() => {
        setSearch("");
      }}
      placeholder="Search Players..."
      onChange={(e) => {
        setSearch(e?.target?.value);
      }}
      rightIcon={faTimesCircle}
      leftIcon={faSearch}
      style={{ width: "100%", maxWidth: "400px" }}
    /> : null}
      
      <GSItemList
        style={{ ...defaultLayouts.fullWidth }}
        type="vertical selectable small-gap"
        items={getScores(currentLeaderboard)}
        indexItems={flights}
        listItem={(item) => (
          <TournamentLeaderboardResultLineItem
            className={item?.skin ? "" : "hover-color-shift"}
            style={{
              ...getStyle(theme.surfaceVariant),
              ...defaultBorders.mediumLargeBorderRadius,
            }}
            leaderboard={currentLeaderboard}
            {...item}
          />
        )}
        itemSelected={(team) => {
          if(team?.skin){
            return
          }
          selectTeam(team);
        }}
        emptyMessage={
          search !== "" ? getEmptySearchMessage() : getEmptyListMessage()
        }
        loading={loading?.includes?.("getPublicLeaderboards")}
        loadingMainText="Loading Leaderboards"
      ></GSItemList>
    </div>
  );
};

export default LeaderboardWidget;
