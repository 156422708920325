import { GSInfoGroup, golfstatusTypography } from "golfstatus_react_components";
import React from "react";
import './package-group-line-item.scss'
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * info group to display a group of packages to filter on
 * @callback navigationCallback
 * 
 * @typedef Properties
 * @type {object}
 * @property {string} sponsorshipType the type of sponsorship this filters on
 * @property {object} icon icon for the group
 * @property {navigationCallback} navigate the navigation function
 * @property {string} style the style for this component
 *
 * @param {Properties} props
 */

const PackageGroupLineItem = (props) => {
  const {sponsorshipType, icon, description, className, style} = props

  const packageIconSection = {
    sectionItems: [
      {
        type: "primary headline-3",
        sectionItemStyle:{fontSize: golfstatusTypography.mediumFont, lineHeight: golfstatusTypography.squareLineHeight},
        value: <FontAwesomeIcon  icon={icon}/>
      }
    ]
  }


  const packageTypeInfoSection = {
    sectionItems: [
      {
        type: "primary  full-width",
        value: sponsorshipType,
      },
    ]
  }

  if(description){
    packageTypeInfoSection.sectionItems.push(
      {
        type: "secondary",
        value: description
      },
    )
  }


  const packageInfoColumn ={
    fit: "stretch",
    sections: [
      packageTypeInfoSection,
      
    ],
  }

  if(icon){
    packageInfoColumn.sections.unshift(packageIconSection)
  }

  const navigateSection = {
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        sectionItemStyle:{fontSize: golfstatusTypography.mediumFont, lineHeight: golfstatusTypography.squareLineHeight},
        value: <FontAwesomeIcon icon={faArrowRight} />,
      },
    ]
  }

  const navigationColumn = {
    sections:[
      navigateSection
    ]
  }

  const getGroup = () => {
    let group = [packageInfoColumn, navigationColumn]
    return group
  }

  return <package-group-line-item class={className} style={style}>
    <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
  </package-group-line-item>
}


export default PackageGroupLineItem

