import { GSItemList,defaultPadding, defaultLayouts, defaultBorders } from "golfstatus_react_components";
import React from "react";
import { useSelector } from "react-redux";
import { selectLoading, selectUnassignedPlayers } from "../../reducers/tournamentSlice";
import { useTheme } from "../../hooks/themeHooks";
import { ACTIVE } from "../../hooks/permissionHooks";
import UnassignedPlayerLineItem from "../events/event/teams/unasigned-player-line-item";



const UnassignedPlayersWidget = (props) => {

  const loading = useSelector(selectLoading)
  const unassignedPlayers = useSelector(selectUnassignedPlayers)?.filter?.(t => t?.status === ACTIVE )

  const [,theme, getStyle] = useTheme()

  return (
    <GSItemList
      style={{ width: "100%" }}
      type="vertical medium-large-gap"
      items={unassignedPlayers}
      listItem={(player) => (
         <UnassignedPlayerLineItem 
         style={{
                     ...defaultPadding.xLargePad,
                     ...getStyle(theme.surfaceVariant),
                     ...defaultLayouts.fullWidth,
                     ...defaultBorders.mediumLargeBorderRadius,
                   }}
         {...player}/>
      )}
      loading={loading.includes?.("getTournamentTeams")}
      loadingMainText="Loading Teams..."
      loadingSubtext="please wait."
    />
  );
};


export default UnassignedPlayersWidget