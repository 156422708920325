import React from "react";
import SummaryLineItem from "../events/event/cart/summary-line-item";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePricing,
  selectCompletedOrder,
  selectPublicTournament,
  selectRegistrationOrder,
} from "../../reducers/tournamentSlice";
import { moneyWithCommas } from "../../helpers/RegexHelper";
import { GSToggle } from "golfstatus_react_components";
import { usePermissions } from "../../hooks/permissionHooks";

const CheckoutFeesWidget = (props) => {
  const { complete } = props;
  const completedOrder = useSelector(selectCompletedOrder);
  const inProgressOrder = useSelector(selectRegistrationOrder);
  const registrationOrder = complete ? completedOrder : inProgressOrder;

  const permissions = usePermissions();

  const tournament = useSelector(selectPublicTournament);

  const dispatch = useDispatch();

  const toggleFees = () => {
    const ru =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => d?.roundUp)
        ?.map?.((d) => {
          return { round_up: true };
        }) ?? [];
    const don =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => !d?.roundUp)
        ?.map?.((d) => {
          return { id: d?.id, amount: d?.amount };
        }) ?? [];
    dispatch(
      calculatePricing({
        ...registrationOrder,
        registrantCoversFees: !registrationOrder?.registrantCoversFees,
        donationOrderItems: [...ru, ...don],
      })
    );
  };

  const getContent = () => {
    if (complete) {
      return null;
    }
    if (permissions.hideFees()) {
      return null;
    }
    return (
      <GSToggle
        trueDescription={`Pay Fees`}
        falseDescription={"Don't Pay Fees"}
        value={registrationOrder?.registrantCoversFees}
        onClick={() => {
          toggleFees();
        }}
      />
    );
  };

  return (
    <SummaryLineItem
      label="Fee"
      description={
        !complete && !permissions.hideFees()
          ? `${tournament?.organization?.name} will pay a fee for this transaction, but you can cover it for them!`
          : null
      }
      value={
        registrationOrder?.registrantCoversFees
          ? moneyWithCommas(registrationOrder?.totalPlatformFee)
          : null
      }
      content={getContent()}
    />
  );
};

export default CheckoutFeesWidget;
