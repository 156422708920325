import {
  GSCaptchaCheckbox,
  defaultLayouts,
  defaultGapping,
  defaultTypography,
  golfstatusTypography,
  GSButton,
  golfstatusColors,
  useMediaQuery,
} from "golfstatus_react_components";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCaptchaData,
  setCaptchaToken,
} from "../../reducers/invoiceSlice";
import {
  clearTokens,
  completeOrder,
  createAndPay,
  selectLoading,
  selectNotifications,
  selectRegistrationCustomer,
  selectRegistrationOrder,
} from "../../reducers/tournamentSlice";
import {
  CUSTOMER_INVOICE,
  getPaymentMethods,
  isBankAttached,
  isCardAttached,
  PAY_AT_COURSE,
} from "../../forms/CartSections";
import { useNavigate } from "react-router-dom";
import { useNotificationAction } from "../../hooks/notificationHooks";
import { REJECTED } from "../../app/api";

const PaymentWidget = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const captachData = useSelector(selectCaptchaData);
  const captchaRef = useRef(null)

  const notifications = useSelector(selectNotifications)

  const registrationOrder = useSelector(selectRegistrationOrder);
  const customer = useSelector(selectRegistrationCustomer);

  const paying = useSelector(selectLoading).filter((l) => l === "createAndPay");

  const currentPaymentMethod = getPaymentMethods().find?.(
    (m) => m?.type === registrationOrder?.paymentType
  );

  const [showCaptcha] = useState(true);

  const freeRegistration = ( registrationOrder?.totalCost ?? 0) === 0

  const { isPhone } = useMediaQuery();

  const fail = useCallback(() => {
    grecaptcha.enterprise.reset(); // eslint-disable-line no-undef
    dispatch(clearTokens())
  },[dispatch])

  useNotificationAction(REJECTED, notifications, fail, "createAndPay")

  const paymentAttached = () => {
    if(freeRegistration){
      return true
    }
    const cardAttached = isCardAttached(registrationOrder);
    const bankAttacted = isBankAttached(registrationOrder);
    const payLater =
      registrationOrder.paymentType === PAY_AT_COURSE ||
      registrationOrder.paymentType === CUSTOMER_INVOICE;
    return cardAttached || bankAttacted || payLater;
  };

  //for right now use a memo to prevent re render
  const captcha = useMemo(() => {
    if (!showCaptcha || (customer?.firstName ?? "") === "") {
      return null;
    }

    return (
      <div ref={captchaRef}>
        <GSCaptchaCheckbox
          captchaKey={process.env.REACT_APP_CHECKBOX_CAPTCHA_KEY}
          action="CHECKOUT"
          setToken={(value) => {
            dispatch(setCaptchaToken(value));
          }}
        />
      </div>
      
    );
  }, [dispatch, showCaptcha, customer?.firstName]);
  //----------------------------------------

  const layout = isPhone()
    ? defaultLayouts.vStack
    : defaultLayouts.hStack.align("flex-start", "center");

  const pay = () => {
    const paySettings = {
      registrationOrder,
      registrationCustomer: customer,
    };
    paySettings["captcha_data"] = captachData;
    if (showCaptcha) {
      dispatch(createAndPay(paySettings))
    } else {
      dispatch(completeOrder());
      navigate("receipt");
    }

    if (showCaptcha) {
      // clear captcha
      dispatch(setCaptchaToken(""));
       
    }
  };

  const buttonLabel = () => {
    if ([1, 2].includes(currentPaymentMethod?.id)) {
      return "Pay Now";
    } else {
      return "Confirm Order";
    }
  };

  const getTOS = () => {
    if ([1, 2].includes(currentPaymentMethod?.id)) {
      return (
        <div>
          <span style={{ ...defaultTypography.bodyRegular.withOpacity() }}>
            {`By tapping "${buttonLabel()}" you authorize payment and agree to
          GolfStatus' `}
          </span>{" "}
          <a
            href="https://golfstatus.com/golfstatus-terms-of-service"
            target="_blank"
            rel="noreferrer"
            style={{
              color: golfstatusColors.cyan700,
              fontWeight: golfstatusTypography.boldFontWeight,
              textDecoration: "underline",
            }}
          >
            Terms of Use
          </a>{" "}
          <span style={{ ...defaultTypography.bodyRegular.withOpacity() }}>
            {"and"}
          </span>{" "}
          <a
            href="https://golfstatus.com/golfstatus-privacy-policy"
            target="_blank"
            rel="noreferrer"
            style={{
              color: golfstatusColors.cyan700,
              fontWeight: golfstatusTypography.boldFontWeight,
              textDecoration: "underline",
            }}
          >
            Privacy Policy
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <span style={{ ...defaultTypography.bodyRegular.withOpacity() }}>
            {`By tapping "${buttonLabel()}" you agree to
          GolfStatus'`}
          </span>{" "}
          <a
            href="https://golfstatus.com/golfstatus-terms-of-service"
            target="_blank"
            rel="noreferrer"
            style={{
              color: golfstatusColors.cyan700,
              fontWeight: golfstatusTypography.boldFontWeight,
              textDecoration: "underline",
            }}
          >
            Terms of Use
          </a>{" "}
          <span style={{ ...defaultTypography.bodyRegular.withOpacity() }}>
            {"and"}
          </span>{" "}
          <a
            href="https://golfstatus.com/golfstatus-privacy-policy"
            target="_blank"
            rel="noreferrer"
            style={{
              color: golfstatusColors.cyan700,
              fontWeight: golfstatusTypography.boldFontWeight,
              textDecoration: "underline",
            }}
          >
            Privacy Policy
          </a>
        </div>
      );
    }
  };

  const getCaptcahOpacity = () => {
    if(paying.length > 0){
      return 0
    }
    if((customer.firstName ?? "") === ""){
      return 0
    }
    if(!paymentAttached()){
      return 0
    }
    return 1
  }

  return (
    <div
      style={{
        ...defaultLayouts.vStack,
        ...defaultGapping.xLargeGap,
        ...defaultLayouts.fullWidth,
      }}
    >
      <div
        style={{
          ...defaultLayouts.fullWidth,
          ...layout,
          ...defaultGapping.largeGap,
        }}
      >
        <div
          style={{
            ...defaultLayouts.fullWidth,
            ...defaultLayouts.vStack.align("flex-start", "center"),
            ...defaultGapping.largeGap,
          }}
        >
          <div style={{ ...defaultTypography.bodyRegular.withOpacity() }}>
            Tap “{buttonLabel()}” to finalize this order.
          </div>
          <div style={{opacity: getCaptcahOpacity(), transition: ".3s"}}>
          {captcha}
          </div>
          
        </div>

        {paying.length > 0 ? (
          <GSButton
            title="Processing..."
            type="grey"
            style={{ flexShrink: "0" }}
            isDisabled={true}
          />
        ) : (
          <GSButton
            title={buttonLabel()}
            type="black"
            style={{ flexShrink: "0" }}
            isDisabled={((captachData?.captcha_token ?? "") === "" && showCaptcha) || !paymentAttached()}
            onClick={() => {
              pay();
            }}
          />
        )}
      </div>
      {getTOS()}
    </div>
  );
};

export default PaymentWidget;
