/* eslint-disable */

export function isEmail(text) {
  const expression = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return testRegex(expression, text);
}

export function isLink(text) {
  var expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  return testRegex(expression, text);
}

export function isPhone(text) {
  var expression = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gi;
  return testRegex(expression, text);
}

export function isHex(text) {
  var expression = /[0-9A-Fa-f]{6}/g;
  return testRegex(expression, text);
}

export function isNumeric(text) {
  var expression = /^-?\d*\.?\d*$/;
  return testRegex(expression, text);
}

export function integerWithCommas(x) {
  return x.toLocaleString("en-US", { minimumFractionDigits: 0 });
}

export function numberWithCommas(x) {
  const number = Number.parseFloat(`${x}`)
  return number.toLocaleString("en-US", { minimumFractionDigits: 2 });
}

export function moneyWithCommas(x, round) {
  if (x === undefined) {
    return "$0.00";
  }
  const number = Number.parseFloat(`${x}`);
  if (round){
    return `$${integerWithCommas(number)}`;
  }
  return `$${fixedWithCommas(number)}`;
}

export function fixedWithCommas(x) {
  const number = Number.parseFloat(`${x}`)
  return numberWithCommas(number.toFixed(2));
}

export function centsToDollars(x) {
  let dollars = x / 100;
  return moneyWithCommas(dollars);
}

export function formattedPhoneNumber(x) {
  if (!x) {
    return "";
  }
  const stripped = x.replace(/\D/g, "");
  if (stripped.length === 10) {
    return `(${stripped.substring(0, 3)}) ${stripped.substring(
      3,
      6
    )}-${stripped.substring(6, 10)}`;
  }
}

function testRegex(expression, text) {
  var regex = new RegExp(expression);
  return regex.test(text);
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
