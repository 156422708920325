import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {usersURL} from "../app/api";

export const selectUser = createAsyncThunk(
  "app/selectUser",
  async (userID, thunkAPI) => {
    let url = `${usersURL}/${userID}`;
    return await gsApi.get(url);
  }
);

export const navigationOptions = [
  {
    id: 1,
    buttonTitle: "Event Details",
    type: "secondary",
    disabled: () => { return false},
    route: "",
  },
  {
    id: 2,
    buttonTitle: "Packages",
    type: "secondary",
    route: "packages",
    disabled: (permissions) => { return !permissions?.packagesAreEnabled},
  },
  {
    id: 3,
    buttonTitle: "Sponsors",
    type: "secondary",
    route: "sponsorships",
    disabled: (permissions) => { 
      return permissions?.hideSponsorships
    },
  },
  {
    id: 4,
    buttonTitle: "Registrants",
    type: "secondary",
    route: "registrants",
    disabled: (permissions) => { return permissions?.hideRegistrants},
  },
  {
    id: 6,
    buttonTitle: "Rounds",
    type: "secondary",
    route: "rounds",
    disabled: (permissions) => { return !permissions?.hasRounds || permissions.hideCourseDetails},
  },
  {
    id: 5,
    buttonTitle: "Leaderboards",
    type: "secondary",
    route: "leaderboards",
    disabled: (permissions) => { return permissions?.hideLeaderboards},
  },
  {
    id: 7,
    buttonTitle: "Auction",
    type: "secondary",
    route: "auction",
    externalLink: (permissions) => {return permissions?.auctionLink},
    disabled: (permissions) => { return (permissions?.auctionLink ?? "" ) === ""},
    isExternal: true,
  },
  {
    id: 7,
    buttonTitle: "Donate",
    type: "secondary",
    route: "donate",
    disabled: (permissions) => { return !permissions?.donationsAreActive},
  },
]

export const getActiveNavigation = (permissions) => {
  return navigationOptions?.filter?.(o => !o.disabled(permissions))
}

const appSlice = createSlice({
  name: "app",
  initialState: {
    theme: "light",
    navigationItems: [],
    loading: [],
    loggedInUser: {},
    notifications: [],
    customTheme: {
      surfaceVariant: {
        light: {
          backgroundColor: "#ecc4f5",
          color: "#370e40"
        },
        dark: {
          color: "#ecc4f5",
          backgroundColor: "#370e40"
        }
      },
      secondaryContainer: {
        light: {
          backgroundColor: "#ecc4f5",
          color: "#370e40"
        },
        dark: {
          color: "#ecc4f5",
          backgroundColor: "#370e40"
        }
      }
    }
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.unshift(action.payload);
    },
    removeNotification: (state, action) => {
      const newNotifications = state.notifications.filter(
        (not) => not.id !== action.payload.id
      );
      state.notifications = newNotifications;
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
    setNavigationItems: (state, action) => {
      state.navigationItems = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(selectUser.fulfilled, (state, action) => {
      state.loggedInUser = action.payload;
    });
  }
});

export const {
  setNotifications,
  addNotification,
  removeNotification,
  clearNotifications,
  setNavigationItems,
  setTheme
} = appSlice.actions;

export const selectNavItems = (state) => state.app.navigationItems
export const selectNotifications = (state) => state.app.notifications;
export const selectLoading = (state) => state.app.loading;
export const selectTheme = (state) => state.app.theme
export const selectCustomTheme = (state) => state.app.customTheme
export const selectLoggedInUser = (state) => state.app.loggedInUser;

export default appSlice.reducer;
