import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import {
  defaultNotificationSettings,
  getThunkResponse,
  publicTournamentsURL,
  registrationFieldSets,
  registrationOrdersURL,
  thunkResponse,
  tournamentPackages,
  tournamentPackageItems,
  tournamentPackageRegistrationFieldSets,
  tournamentRoundsURL,
  tournamentsURL,
  waitlistMember,
} from "../app/api";
import {
  getIDRelationship,
  getOpts,
  getResponse,
  getSerializedData,
  longDelay,
  mediumDelay,
} from "../helpers/JSONapi";

import { uuidv4 } from "../helpers/RegexHelper";
import {
  RegistrationOrder,
  addPackageToRegistrationOrder,
  decrementOrderItem,
  deleteOrderFromLocalStorage,
  getCustomerFromLocalStorage,
  getOrderFromLocalStorage,
  registrationCustomerComplete,
  removeOrderItem,
  saveCustomerToLocalStorage,
  saveOrderToLocalStorage,
} from "../models/Registration";
import { tournamentFilter } from "../components/events/events-list";
import { getRouting } from "../helpers/Converters";

//replace all occurences of template with the name of your new slice
export const tournamentSlice = createSlice({
  name: "tournaments",
  initialState: {
    tournamentList: [],
    hasMoreTournaments: false,
    tournament: {},
    externalLinks: [],
    tournamentRound: {},
    holeAssignments: [],
    roundCourses: [],
    roundHoles: [],
    roundTees: [],
    tournamentPaymentSettings: {},
    tournamentSponsors: [],
    tournamentPackages: [],
    tournamentPackage: {},
    tournamentAccessCodes: [],
    tournamentTeams: [],
    unassignedPlayers: [],
    tournamentCart: [],
    tournamentCartPrice: {},
    registrationOrder: null,
    completedOrder: {},
    packageFieldSets: [],
    registrationCustomer: {},
    tournamentTabs: [],
    tournamentFilter: tournamentFilter,
    notifications: [],
    loading: [],
    accessListCredentials: {
      accessIdentifier: "",
      accessEmail: "",
    },
    waitlistDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      waitListType: "team",
      notes: "",
    },
  },
  //be sure to export any reducers on line 65
  reducers: {
    setWaitlistDetails: (state, action) => {
      state.waitlistDetails = action.payload;
    },
    setWaitlistType: (state, action) => {
      state.waitlistDetails = {
        ...state.waitlistDetails,
        waitListType: action.payload,
      };
    },
    clearPayment: (state, action) => {
      const registrationOrder = state.registrationOrder;
      const ru =
        registrationOrder?.donationOrderItems
          ?.filter?.((d) => d?.roundUp)
          ?.map?.((d) => {
            return { round_up: true };
          }) ?? [];
      const don =
        registrationOrder?.donationOrderItems
          ?.filter?.((d) => !d?.roundUp)
          ?.map?.((d) => {
            return { id: d?.id, amount: d?.amount };
          }) ?? [];

      const update = {
        ...registrationOrder,
        paymentType: null,
        globalToken: null,
        stripeToken: null,
        cardFirstName: null,
        cardLastName: null,
        paymentOptionKey: null,
        saveCard: false,
        donationOrderItems: [...ru, ...don],
      };
      updateRegistrationOrder(state, update);
    },
    clearTokens: (state, action) => {
      const update = {
        ...state.registrationOrder,
        globalToken: null,
        stripeToken: null,
        saveCard: false
      };
      updateRegistrationOrder(state, update);
    },
    setAccessListCredentials: (state, action) => {
      state.accessListCredentials = action.payload;
    },
    setAccessCodes: (state, action) => {
      state.tournamentAccessCodes = action.payload;
      if (action?.payload?.length === 0) {
        const order = {
          ...state.registrationOrder,
          accessIdentifier: null,
          accessValidated: false,
          accessEmail: null,
          accessGroupID: null,
          accessGroupMemberID: null
        };
        updateRegistrationOrder(state, order);
      }
    },
    setTournamentRoundID: (state, action) => {
      const round = state?.tournament?.tournamentRounds?.find?.(
        (r) => r.id === action.payload
      );
      state.tournamentRound = round;
    },
    setTournamentList: (state, action) => {
      state.tournamentList = action.payload;
    },
    setEventListFilter: (state, action) => {
      state.tournamentFilter = action.payload;
    },
    setEventListLocation: (state, action) => {
      state.tournamentFilter = { ...state.tournamentFilter, ...action.payload };
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
    updateCurrentPackage: (state, action) => {
      state.tournamentPackage = action.payload;
    },
    addToCart: (state, action) => {
      const p = { ...action.payload, cartID: uuidv4() };
      state.tournamentCart = [...state.tournamentCart, p];
    },
    removeFromCart: (state, action) => {
      state.tournamentCart = state.tournamentCart?.filter?.(
        (p) => p.cartID !== action.payload.cartID
      );
    },
    addPackageToOrder: (state, action) => {
      let order = { ...state.registrationOrder };
      addPackageToRegistrationOrder(action.payload, order);
      updateRegistrationOrder(state, order);
    },
    removePackageItemFromOrder: (state, action) => {
      let order = { ...state.registrationOrder };
      removeOrderItem(action.payload, order);
      updateRegistrationOrder(state, order);
    },
    decrementItemQuantity: (state, action) => {
      let order = { ...state.registrationOrder };
      decrementOrderItem(action.payload, order);
      updateRegistrationOrder(state, order);
    },
    updateCustomer: (state, action) => {
      state.registrationCustomer = {...state?.registrationCustomer, ...action.payload};
      saveCustomerToLocalStorage({...action.payload, tournament: state?.tournament})
    },
    updateOrder: (state, action) => {
      updateRegistrationOrder(state, action.payload);
    },
    completeOrder: (state, action) => {
      state.completedOrder = { ...state.registrationOrder };
      updateRegistrationOrder(state, {
        ...RegistrationOrder,
        tournament: state.tournament,
      });
    },
    updateOrderForm: (state, action) => {
      const update = { ...state.registrationOrder };
      update.customFieldResponses = action.payload;
      updateRegistrationOrder(state, update);
    },
    updateTeamOrderForm: (state, action) => {
      const orderItemIndex = state?.registrationOrder?.registrationOrderItems?.findIndex?.(
        (oi) => oi.id === action?.payload?.orderItemID
      );
      const orderUpdate = { ...state?.registrationOrder };

      if (orderItemIndex >= 0) {
        const registrationOrderItem = {
          ...state?.registrationOrder?.registrationOrderItems[orderItemIndex],
        };
        const teamIndex = registrationOrderItem?.inProgressTournamentTeams?.findIndex?.(
          (team) => team?.id === action.payload?.teamID
        );
        if (teamIndex >= 0) {
          const update = {
            ...registrationOrderItem?.inProgressTournamentTeams[teamIndex],
          };
          update.customFieldResponses = action.payload.responses;
          orderUpdate.registrationOrderItems[
            orderItemIndex
          ].inProgressTournamentTeams[teamIndex] = update;
          updateRegistrationOrder(state, orderUpdate);
        }
      }
    },
    updateSponsorOrderForm: (state, action) => {
      const orderItemIndex = state?.registrationOrder?.registrationOrderItems?.findIndex?.(
        (oi) => oi.id === action?.payload?.orderItemID
      );
      const orderUpdate = { ...state?.registrationOrder };

      if (orderItemIndex >= 0) {
        const registrationOrderItem = {
          ...state?.registrationOrder?.registrationOrderItems[orderItemIndex],
        };
        const sponsorIndex = registrationOrderItem?.inProgressSponsors?.findIndex?.(
          (sponsor) => sponsor?.id === action.payload?.sponsorID
        );
        if (sponsorIndex >= 0) {
          const update = {
            ...registrationOrderItem?.inProgressSponsors[sponsorIndex],
          };
          update.customFieldResponses = action.payload.responses;
          orderUpdate.registrationOrderItems[orderItemIndex].inProgressSponsors[
            sponsorIndex
          ] = update;
          updateRegistrationOrder(state, orderUpdate);
        }
      }
    },
    updatePlayer: (state, action) => {
      const orderItemIndex = state?.registrationOrder?.registrationOrderItems?.findIndex?.(
        (oi) => oi.id === action?.payload?.orderItemID
      );
      const orderUpdate = { ...state?.registrationOrder };

      if (orderItemIndex >= 0) {
        const update = {
          ...state?.registrationOrder?.registrationOrderItems[orderItemIndex],
        };
        let playerIndex = update.inProgressPlayers?.findIndex?.(
          (p) => p.id === action?.payload?.id
        );
        if (playerIndex >= 0) {
          update.inProgressPlayers[playerIndex] = action.payload;
          orderUpdate.registrationOrderItems[orderItemIndex] = update;
          updateRegistrationOrder(state, orderUpdate);
          return;
        }
        update?.inProgressTournamentTeams?.forEach?.((t, teamIndex) => {
          playerIndex = t.inProgressPlayers?.findIndex?.(
            (p) => p.id === action?.payload?.id
          );
          if (playerIndex >= 0) {
            update.inProgressTournamentTeams[teamIndex].inProgressPlayers[
              playerIndex
            ] = action.payload;
            orderUpdate.registrationOrderItems[orderItemIndex] = update;
            updateRegistrationOrder(state, orderUpdate);
            return;
          }
        });
      }
    },
    updateTeam: (state, action) => {
      const orderItemIndex = state?.registrationOrder?.registrationOrderItems?.findIndex?.(
        (oi) => oi.id === action?.payload?.orderItemID
      );
      const orderUpdate = { ...state?.registrationOrder };

      if (orderItemIndex >= 0) {
        const update = {
          ...state?.registrationOrder?.registrationOrderItems[orderItemIndex],
        };
        let teamIndex = update.inProgressTournamentTeams?.findIndex?.(
          (p) => p.id === action?.payload?.id
        );
        if (teamIndex >= 0) {
          update.inProgressTournamentTeams[teamIndex] = action.payload;
          orderUpdate.registrationOrderItems[orderItemIndex] = update;
          updateRegistrationOrder(state, orderUpdate);
          return;
        }
      }
    },
    updateSponsor: (state, action) => {
      const orderItemIndex = state?.registrationOrder?.registrationOrderItems?.findIndex?.(
        (oi) => oi.id === action?.payload?.orderItemID
      );
      const orderUpdate = { ...state?.registrationOrder };

      if (orderItemIndex >= 0) {
        const update = {
          ...state?.registrationOrder?.registrationOrderItems[orderItemIndex],
        };
        let sponsorIndex = update.inProgressSponsors?.findIndex?.(
          (p) => p.id === action?.payload?.id
        );
        if (sponsorIndex >= 0) {
          update.inProgressSponsors[sponsorIndex] = action.payload;
          orderUpdate.registrationOrderItems[orderItemIndex] = update;
          updateRegistrationOrder(state, orderUpdate);
          return;
        }
      }
    },
    updateTournament: (state, action) => {
      state.tournament = {...state.tournament, ...action.payload};
    },
    clearTournament: (state) => {
      state.tournament = {}
    }
  },
  extraReducers: (builder) => {
    thunkResponse(builder, getPublicTournamentDetails, (state, action) => {
      state.tournament = action.payload;
      state.registrationCustomer.tournament = action.payload;
      if (
        state.registrationOrder === null ||
        state?.registrationOrder?.tourament?.id !== action?.payload?.id
      ) {
        state.registrationOrder = getOrderFromLocalStorage(action.payload);
      }
      if(!registrationCustomerComplete(state.registrationCustomer)){
        state.registrationCustomer = getCustomerFromLocalStorage(action?.payload?.id)
      }
    });
    thunkResponse(builder, getTournaments, (state, action) => {
      if (action?.meta?.arg?.page === 1) {
        if (action.payload.length === parseInt(action?.meta?.arg?.per_page)) {
          state.hasMoreTournaments = true;
        }
        state.tournamentList = action.payload;
      } else {
        if (action.payload?.length < parseInt(action?.meta?.arg?.per_page)) {
          state.hasMoreTournaments = false;
        }
        state.tournamentList = [...state.tournamentList, ...action.payload];
      }
    });
    thunkResponse(builder, getTournamentPaymentSettings, (state, action) => {
      state.tournamentPaymentSettings = action.payload;
    });
    thunkResponse(builder, getTournamentSponsors, (state, action) => {
      state.tournamentSponsors = action.payload;
    });
    thunkResponse(builder, getTournamentPackages, (state, action) => {
      state.tournamentPackages = action.payload;
    });
    thunkResponse(builder, getTournamentTeams, (state, action) => {
      state.tournamentTeams = action.payload;
    });
    thunkResponse(builder, getUnassignedPlayers, (state, action) => {
      state.unassignedPlayers = action.payload;
    });
    thunkResponse(builder, getTournamentPackageFieldSets, (state, action) => {
      if (action?.meta?.arg === state.tournaemntPackage?.id) {
        state.tournamentPackage = {
          ...state.tournamentPackage,
          fieldSets: action.payload,
        };
      }
    });
    thunkResponse(builder, getTournamentPackage, (state, action) => {
      state.tournamentPackage = action.payload;
    });
    thunkResponse(builder, calculatePricing, (state, action) => {
      const update = {
        ...state.registrationOrder,
        ...action.payload,
      };
      updateRegistrationOrder(state, update);
    });
    thunkResponse(
      builder,
      createAndPay,
      (state, action) => {
        state.completedOrder = { ...state.registrationOrder };
       const { accessIdentifier,
            accessValidated,
            accessEmail,
            accessGroupID,
            accessGroupMemberID} = state.registrationOrder
        updateRegistrationOrder(state, {
          ...RegistrationOrder,
          accessIdentifier, accessEmail, accessValidated, accessGroupID, accessGroupMemberID,
          tournament: state.tournament,
        });
      },
      {
        ...defaultNotificationSettings,
        pending: {
          ...defaultNotificationSettings.pending,
          message: () =>
            "We’re processing your order, please don’t refresh this page.",
        },
        rejected: {
          ...defaultNotificationSettings.rejected,
          timeout: longDelay,
          message: (state, action) => {
            return (
              action?.payload?.message ??
              "Please double-check your payment details."
            );
          },
          description: () => "We couldn't process your payment.",
        },
      }
    );
    thunkResponse(
      builder,
      applyDiscount,
      (state, action) => {
        const orderUpdate = {
          ...state.registrationOrder,
          ...action.payload,
        };
        updateRegistrationOrder(state, orderUpdate);
      },
      {
        pending: false,
        fulfilled: false,
        rejected: {
          ...defaultNotificationSettings.rejected,
          message: () => "Not a valid discount code.",
          timeout: mediumDelay,
        },
      }
    );
    getThunkResponse(builder, getRoundHoleAssignments, (state, action) => {
      state.holeAssignments = action.payload;
    });
    getThunkResponse(builder, getRoundCourses, (state, action) => {
      state.roundCourses = action.payload;
    });
    getThunkResponse(builder, getRoundHoles, (state, action) => {
      state.roundHoles = action.payload;
    });
    getThunkResponse(builder, getRoundTees, (state, action) => {
      state.roundTees = action.payload;
    });
    getThunkResponse(builder, getTournamentExternalLinks, (state, action) => {
      state.externalLinks = action.payload;
    });
    thunkResponse(
      builder,
      getTournamentAccessCode,
      (state, action) => {
        state.tournamentAccessCodes = action.payload;
        if (action.payload?.length > 0) {
          const access = action.payload[0];
          const order = {
            ...state.registrationOrder,
            accessIdentifier: access?.identifier?.toLowerCase?.(),
            accessValidated: true,
            accessEmail: access?.credentials?.accessEmail,
            accessGroupID: access?.accessGroup?.id,
            accessGroupMemberID: access?.id
          };
          updateRegistrationOrder(state, order);
        }
      },
      {
        ...defaultNotificationSettings,
        rejected: {
          ...defaultNotificationSettings.rejected,
          message: () =>
            "This email address and access code combination is incorrect.",
        },
        fulfilled: {
          ...defaultNotificationSettings.fulfilled,
          message: () => "Your credentials have been validated!",
        },
      }
    );
    getThunkResponse(builder, internationalCardCheck, (state, action) => {
      const order = { ...state.registrationOrder, ...action.payload };
      updateRegistrationOrder(state, order);
    });
    thunkResponse(builder, joinWaitList, (state, action) => {}, {
      pending: {
        ...defaultNotificationSettings.pending,
        message: () => "Adding you to the waitlist...",
      },
      fulfilled: {
        ...defaultNotificationSettings.fulfilled,
        message: () => "You have been added to the waitlist.",
        description: () => "We couldn't add you to the waitlist at this time.",
      },
      rejected: {
        ...defaultNotificationSettings.rejected,
        timeout: mediumDelay,
        message: () => "Please try again later",
        description: () => "We couldn't add you to the waitlist at this time.",
      },
    });
  },
});

export const getPublicTournamentDetails = createAsyncThunk(
  `${tournamentSlice.name}/getPublicTournamentDetails`,
  async (id, thunkAPI) => {
    let tournamentID = id;
    const slugTournament = await getResponse(
      gsApi.get,
      `${publicTournamentsURL}/${id}`
    );
    if (slugTournament) {
      tournamentID = slugTournament.id;
    }
    return await getResponse(gsApi.get, `${tournamentsURL}/${tournamentID}`);
  }
);

export const getTournaments = createAsyncThunk(
  `${tournamentSlice.name}/getTournaments`,
  async (filter, thunkAPI) => {
    return await getResponse(gsApi.get, tournamentsURL, {
      data: filter,
    });
  }
);

export const getTournamentPaymentSettings = createAsyncThunk(
  `${tournamentSlice.name}/getTournamentPaymentSettings`,
  async (id, thunkAPI) => {
    return await getResponse(
      gsApi.get,
      `${tournamentsURL}/${id}/tournament-payment-setting`
    );
  }
);

export const getTournamentSponsors = createAsyncThunk(
  `${tournamentSlice.name}/getTournamentSponsors`,
  async (id, thunkAPI) => {
    return await getResponse(gsApi.get, `${tournamentsURL}/${id}/sponsors`);
  }
);

export const getTournamentTeams = createAsyncThunk(
  `${tournamentSlice.name}/getTournamentTeams`,
  async (id, thunkAPI) => {
    const teams = await getResponse(
      gsApi.get,
      `${tournamentsURL}/${id}/tournament-teams`
    );

    return teams;
  }
);

export const getUnassignedPlayers = createAsyncThunk(
  `${tournamentSlice.name}/getUnassignedPlayers`,
  async (id, thunkAPI) => {
    const teams = await getResponse(
      gsApi.get,
      `${tournamentsURL}/${id}/unassigned-players`
    );

    return teams;
  }
);

export const getTournamentPackages = createAsyncThunk(
  `${tournamentSlice.name}/getTournamentPackages`,
  async (id, thunkAPI) => {
    return await getResponse(
      gsApi.get,
      `${tournamentsURL}/${id}/tournament-packages`
    );
  }
);

export const getTournamentPackage = createAsyncThunk(
  `${tournamentSlice.name}/getTournamentPackage`,
  async (id, thunkAPI) => {
    return await getResponse(gsApi.get, `${tournamentPackages}/${id}`);
  }
);

export const getTournamentPackageFieldSets = createAsyncThunk(
  `${tournamentSlice.name}/getTournamentPackageFieldSets`,
  async (id, thunkAPI) => {
    let fieldSetResponse = await getResponse(
      gsApi.get,
      `${tournamentPackages}/${id}/tournament-package-registration-field-sets`
    );

    for (let fieldSet of fieldSetResponse) {
      let tprfs = await getResponse(
        gsApi.get,
        `${tournamentPackageRegistrationFieldSets}/${fieldSet.id}/registration-field-set`
      );
      let fs = await getResponse(
        gsApi.get,
        `${registrationFieldSets}/${tprfs.id}/registration-custom-fields`
      );
      tprfs.fields = fs;
      fieldSet.fieldSets = tprfs;
    }

    let itemResponse = await getResponse(
      gsApi.get,
      `${tournamentPackages}/${id}/tournament-package-items`
    );

    

    for (let item of itemResponse) {
      let i = await getResponse(
        gsApi.get,
        `${tournamentPackageItems}/${item.id}/package-item`
      );

      item.packageItem = i;
    }
    
    let roundResponse = await getResponse(
      gsApi.get,
      `${tournamentPackages}/${id}/tournament-rounds`
    );
    

    for (let item of roundResponse) {
      let i = await getResponse(
        gsApi.get,
        `${tournamentRoundsURL}/${item.id}/tournament-package-round-hole-assignments`
      );

      item.holeAssignments = i;
    }

    return { fieldSets: fieldSetResponse, packageItems: itemResponse, rounds: roundResponse };
  }
);

export const getTournamentRound = createAsyncThunk(
  `${tournamentSlice.name}/getTournamentRound`,
  async (id, thunkAPI) => {
    return await getResponse(gsApi.get, `${tournamentRoundsURL}/${id}`);
  }
);

export const applyDiscount = createAsyncThunk(
  `${tournamentSlice.name}/applyDiscount`,
  async (discountSearch, thunkAPI) => {
    const { tournamentID, discountCode, registrationOrder } = discountSearch;
    const response = await getResponse(
      gsApi.get,
      `${tournamentsURL}/${tournamentID}/discount-code-search?discount_code=${discountCode}`,
      null,
      thunkAPI
    );

    if (!response?.id) {
      return response;
    }

    const update = { ...registrationOrder };
    update.discountCodes = [...registrationOrder.discountCodes, response];

    return calculateOrderPricing(update, thunkAPI);
  }
);

export const getRoundHoleAssignments = createAsyncThunk(
  `${tournamentSlice.name}/getRoundHoleAssignments`,
  async (roundID, thunkAPI) => {
    return await getResponse(
      gsApi.get,
      getRouting(tournamentRoundsURL, [roundID, "round-hole-assignments"])
    );
  }
);

export const getRoundCourses = createAsyncThunk(
  `${tournamentSlice.name}/getRoundCourses`,
  async (roundID, thunkAPI) => {
    return await getResponse(
      gsApi.get,
      getRouting(tournamentRoundsURL, [roundID, "round-courses"])
    );
  }
);
export const getRoundHoles = createAsyncThunk(
  `${tournamentSlice.name}/getRoundHoles`,
  async (roundID, thunkAPI) => {
    return await getResponse(
      gsApi.get,
      getRouting(tournamentRoundsURL, [roundID, "round-holes"])
    );
  }
);
export const getRoundTees = createAsyncThunk(
  `${tournamentSlice.name}/getRoundTees`,
  async (roundID, thunkAPI) => {
    return await getResponse(
      gsApi.get,
      getRouting(tournamentRoundsURL, [roundID, "round-tees"])
    );
  }
);

export const getTournamentExternalLinks = createAsyncThunk(
  "tournaments/getTournamentExternalLinks",
  async (id, thunkAPI) => {
    let url = `${tournamentsURL}/${id}/external-links`;
    const response = await gsApi.get(url);
    return response;
  }
);

export const getTournamentAccessCode = createAsyncThunk(
  "tournaments/getTournamentAccessCode",
  async (filter, thunkAPI) => {
    const { tournamentID, accessIdentifier, accessEmail } = filter;
    const params = {
      access_identifier: accessIdentifier,
      access_email: accessEmail,
    };
    let response = await getResponse(
      gsApi.get,
      getRouting(tournamentsURL, [tournamentID, "access-group-member-search"]),
      { data: params },
      thunkAPI
    );
    if (response?.length > 0) {
      response = response.map?.((r) => {
        return { ...r, credentials: filter };
      });
    }
    return response;
  }
);

//cart info

const calculateOrderPricing = async (registrationOrder, thunkAPI) => {
  const response = await getResponse(
    gsApi.post,
    `${registrationOrdersURL}/calculate-order-pricing`,
    registrationOrder,
    thunkAPI
  );
  return { ...registrationOrder, ...response };
};

export const calculatePricing = createAsyncThunk(
  `${tournamentSlice.name}/calculatePricing`,
  calculateOrderPricing
);

export const updateRegistrationOrder = (state, order) => {
  if (order?.id !== state.registrationOrder?.id) {
    deleteOrderFromLocalStorage(state.registrationOrder);
  }
  state.registrationOrder = order;

  saveOrderToLocalStorage(order);
};

export const internationalCardCheck = createAsyncThunk(
  `${tournamentSlice.name}/internationalCardCheck`,
  async (data, thunkAPI) => {
    const { registrationOrder, cardInfo } = data;
    const response = await getResponse(
      gsApi.post,
      `${registrationOrdersURL}/international-card-check`,
      cardInfo,
      thunkAPI
    );

    const order = {
      ...registrationOrder,
      internationalCardUsed: response?.data?.["international_card"],
    };
    return calculateOrderPricing(order, thunkAPI);
  }
);
//create and pay
export const createAndPay = createAsyncThunk(
  `${tournamentSlice.name}/createAndPay`,
  async (order, thunkAPI) => {
    const registration = order.registrationOrder;
    const registrationOrder = {
      accessEmail: registration.accessEmail,
      accessIdentifier: registration.accessIdentifier,
      accessValidated: registration.accessValidated,
      cardFirstName: registration.cardFirstName,
      cardLastName: registration.cardLastName,
      changeFeeAmount: registration.changeFeeAmount,
      customFieldResponses: registration.customFieldResponses,
      customFormValid: true,
      discountAmount: registration.discountAmount,
      discountCodes: registration.discountCodes,
      discountMessage: registration.discountMessage,
      donationOrderItems: registration.donationOrderItems,
      internationalCardUsed: registration.internationalCardUsed,
      payAtCourse: registration.payAtCourse,
      paymentOptionKey: registration.paymentOptionKey,
      paymentType: registration.paymentType,
      globalToken: registration.globalToken,
      stripeToken: registration.stripeToken,
      last4: registration.last4,
      cardName: registration.cardName,
      brand: registration.brand,
      registrantCoversFees: registration.registrantCoversFees,
      registrationOrderItems: registration.registrationOrderItems.map?.(
        (item, index) => {
          return {
            accessGroupMemberId: item.accessGroupMemberId,
            inProgressPlayers: item.inProgressPlayers,
            inProgressSponsors: item.inProgressSponsors,
            inProgressTournamentTeams: item.inProgressTournamentTeams.map?.(t => {return {...t, id: null, tournamentPackageId: item?.tournamentPackage?.id}}),
            index,
            packageCost: item?.tournamentPackage?.cost,
            quantity: item.quantity,
            totalCost:
              (item?.tournamentPackage?.cost ?? 0) * (item?.quantity ?? 0),
            tournamentPackage: {
              ...item.tournamentPackage,
              tournament: registration.tournament.id,
            },
          };
        }
      ),
      roundUpToggle: registration.roundUpToggle,
      status: "ready",
      totalCost: registration.totalCost,
      totalCostWithFees: registration.totalCostWithFees,
      totalPlatformFee: registration.totalPlatformFee,
      tournament: registration.tournament,
    };

    const fullOrder = {
      registrationCustomer: order.registrationCustomer,
      registrationOrder,
    };

    fullOrder["captcha_data"] = { ...order["captcha_data"] };
    fullOrder.saveCard = registration?.saveCard

    return await getResponse(
      gsApi.post,
      `${registrationOrdersURL}/create-and-pay`,
      fullOrder,
      thunkAPI
    );
  }
);

export const joinWaitList = createAsyncThunk(
  `${tournamentSlice.name}/joinWaitList`,
  async (waitListMember, thunkAPI) => {
    let serializedData = getSerializedData(
      waitListMember,
      "wait-list-members",
      getOpts(waitListMember, [...getIDRelationship("tournament")])
    );

    const response = await getResponse(
      gsApi.post,
      waitlistMember,
      serializedData,
      thunkAPI
    );

    return response;
  }
);

export const {
  setWaitlistDetails,
  setAccessListCredentials,
  setAccessCodes,
  setTournamentList,
  setEventListFilter,
  addNotification,
  clearNotifications,
  updateCurrentPackage,
  addToCart,
  removeFromCart,
  addPackageToOrder,
  decrementItemQuantity,
  removePackageItemFromOrder,
  updateCustomer,
  updateOrderForm,
  updatePlayer,
  updateTeam,
  updateTeamOrderForm,
  updateSponsorOrderForm,
  updateSponsor,
  updateOrder,
  setEventListLocation,
  setTournamentRoundID,
  updateTournament,
  completeOrder,
  clearPayment,
  clearTokens,
  setWaitlistType,
  clearTournament
} = tournamentSlice.actions;

//export any selectors needed

export const selectTournamentRound = (state) =>
  state?.[tournamentSlice.name]?.tournamentRound;

export const selectNotifications = (state) =>
  state?.[tournamentSlice.name]?.notifications;

export const selectLoading = (state) => state?.[tournamentSlice.name]?.loading;

export const selectPublicTournament = (state) =>
  state?.[tournamentSlice.name]?.tournament;

export const selectTournamentTabs = (state) =>
  state?.[tournamentSlice.name]?.tournamentTabs;

export const selectTournaments = (state) =>
  state?.[tournamentSlice.name]?.tournamentList;

export const selectTournamentPaymentSettings = (state) =>
  state?.[tournamentSlice.name]?.tournamentPaymentSettings;

export const selectTournamentSponsors = (state) =>
  state?.[tournamentSlice.name]?.tournamentSponsors;

export const selectTournamentTeams = (state) =>
  state?.[tournamentSlice.name]?.tournamentTeams;

export const selectTournamentPackages = (state) =>
  state?.[tournamentSlice.name]?.tournamentPackages;

export const selectTournamentPackage = (state) =>
  state?.[tournamentSlice.name]?.tournamentPackage;

export const selectCart = (state) =>
  state?.[tournamentSlice.name]?.tournamentCart;

export const selectRegistrationOrder = (state) =>
  state?.[tournamentSlice.name]?.registrationOrder;

export const selectCompletedOrder = (state) =>
  state?.[tournamentSlice.name]?.completedOrder;

export const selectRegistrationCustomer = (state) =>
  state?.[tournamentSlice.name]?.registrationCustomer;

export const selectTournamentFilter = (state) =>
  state?.[tournamentSlice.name]?.tournamentFilter;

export const selectHoleAssignments = (state) =>
  state?.[tournamentSlice.name]?.holeAssignments;

export const selectRoundCourses = (state) =>
  state?.[tournamentSlice.name]?.roundCourses;

export const selectRoundHoles = (state) =>
  state?.[tournamentSlice.name]?.roundHoles;

export const selectRoundTees = (state) =>
  state?.[tournamentSlice.name]?.roundTees;

export const selectHasMoreTournaments = (state) =>
  state?.[tournamentSlice.name]?.hasMoreTournaments;

export const selectExternalLinks = (state) =>
  state?.[tournamentSlice.name]?.externalLinks;

export const selectTournamentAccessCodes = (state) =>
  state?.[tournamentSlice.name]?.tournamentAccessCodes;

export const selectAccessListCredentials = (state) =>
  state?.[tournamentSlice.name]?.accessListCredentials;

export const selectWaitlistDetails = (state) =>
  state?.[tournamentSlice.name]?.waitlistDetails;

export const selectUnassignedPlayers = (state) => state?.[tournamentSlice?.name]?.unassignedPlayers

export default tournamentSlice.reducer;

//ADD reducer to store.js
