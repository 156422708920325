import {
  defaultBorders,
  defaultLayouts,
  defaultPadding,
  defaultTypography,
  GSEmptyList,
  GSItemList,
} from "golfstatus_react_components";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectFavoriteTeams,
  selectFlights,
  selectLeaderboard,
  selectLeaderboards,
  selectLoading,
} from "../../reducers/leaderboardSlice";
import TournamentLeaderboardResultLineItem from "../events/event/leaderboards/tournament-leaderboard-result-line-item";
import { useTheme } from "../../hooks/themeHooks";
import { useNavigate } from "react-router-dom";
import { getRouting } from "../../helpers/Converters";
import { eventBaseRoute } from "../../routes/event";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getDateTimeOn } from "../../helpers/Dates";
import { useTournament } from "../../hooks/tournamentHooks";

export const getSkinName = (score) => {
  return score?.winner?.toLowerCase?.();
};

const LeaderboardFavoritesWidget = () => {
  const tournamentFlights = useSelector(selectFlights);
  const currentLeaderboard = useSelector(selectLeaderboard);
  const favorites = useSelector(selectFavoriteTeams);
  const tournament = useTournament()
  const leaderboards = useSelector(selectLeaderboards);
  const loading = useSelector(selectLoading);

  const navigate = useNavigate;

  const [, theme, getStyle] = useTheme();

  const getFavorites = (leaderboard) => {
    const results =
      leaderboard?.scoringSystem === "match_play" &&
      leaderboard?.type === "round_match"
        ? leaderboard.scores?.filter?.((s) =>
            s.teamKeys?.some?.((k) => favorites.includes?.(k))
          )
        : leaderboard?.scores?.filter?.((s) =>
            favorites?.includes?.(s.teamKey)
          );

    return results;
  };

  const faves = getFavorites(currentLeaderboard) ?? [];

  const getTeamIndex = (flight) => {
    const index = getFavorites(currentLeaderboard)?.findIndex?.(
      (s) => s.teamKey === flight?.teamFlights?.[0]?.teamScores?.teamKey
    );
    return index;
  };

  const currentFlights = tournamentFlights?.filter?.(
    (f) =>
      f.leaderboardTypeSetting?.id ===
      currentLeaderboard?.leaderboardTypeSetting?.id
  );

  let flights = [];
  if (currentFlights?.length > 0 && currentLeaderboard?.type !== "skins") {
    flights = currentFlights?.map?.((f, index) => ({
      index: getTeamIndex(f),
      view: (
        <div
          style={{
            ...defaultTypography.headline1,
            ...defaultPadding.xLargePad,
          }}
        >{`${f.name} Flight`}</div>
      ),
    }));
  }

  const selectTeam = (team) => {
    const key = team?.teamKey ?? team?.teamKeys?.[0];
    navigate(
      getRouting(eventBaseRoute, [
        tournament?.publicUrl,
        "leaderboards",
        currentLeaderboard?.id,
        "results",
        key,
      ])
    );
  };

  const getEmptyListMessage = () => {
    if (currentLeaderboard?.id) {
      return (
        <GSEmptyList
          title={currentLeaderboard.name}
          detail={`This leaderboard does not yet have results to show.`}
          actions={
            leaderboards?.length > 0
              ? [
                  {
                    title: "Change Leaderboard",
                    type: "grey",
                    rightIcon: faArrowRight,
                    onClick: () => {
                      navigate("select");
                    },
                  },
                ]
              : []
          }
        />
      );
    }
    return (
      <GSEmptyList
        title="No Leaderboards Yet"
        detail={`Leaderboards will become available just before the tournament starts${
          tournament?.startAt ? ` at ${getDateTimeOn(tournament?.startAt)}` : ""
        }`}
        actions={[
          {
            title: "View Event Details",
            type: "grey",
            onClick: () => {
              navigate(getRouting(eventBaseRoute, [tournament?.publicUrl]));
            },
          },
        ]}
      />
    );
  };

  

  return (
    <GSItemList
      style={{ ...defaultLayouts.fullWidth }}
      type="vertical selectable small-gap"
      items={faves}
      indexItems={flights}
      listItem={(item) => (
        <TournamentLeaderboardResultLineItem
          className="hover-color-shift"
          style={{
            ...getStyle(theme.secondaryContainer),
            ...defaultBorders.mediumLargeBorderRadius,
          }}
          leaderboard={currentLeaderboard}
          {...item}
        />
      )}
      itemSelected={(team) => {
        selectTeam(team);
      }}
      emptyMessage={getEmptyListMessage()}
      loading={loading?.includes?.("getPublicLeaderboards")}
      loadingMainText="Loading Leaderboards"
    ></GSItemList>
  );
};

export default LeaderboardFavoritesWidget;
