import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { baseURL, getThunkResponse } from "../app/api";
import { getResponse } from "../helpers/JSONapi";
import gsApi from "../auth/auth";
import { getRouting } from "../helpers/Converters";

export const organizationURL = `${baseURL}/organizations`;

export const organizationRoute = (nested) => {
  return getRouting(organizationURL, nested)
};

//replace all occurences of template with the name of your new slice
export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    organizationDetails: {},
    organizationLookup: {},
    organizationEventSponsors: [],
    organizationSettings: {},
    notifications: [],
    loading: [],
  },
  //be sure to export any reducers on line 65
  reducers: {
    setOrganization: (state, action) => {
      state.list = action.payload;
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    getThunkResponse(builder, getOrganization, (state, action) => {
      state.organizationDetails = action.payload;
    });
    getThunkResponse(builder, getOrganizationLookup, (state, action) => {
      state.organizationLookup = {...action.payload,
         bannerImage:"https://api.golfstatus.dev/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbk5XIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--948d15c3c140763bcc550fee5e2b76e2f60f7cc1/5f96dfac-b1ae-446b-bef9-af4426b14d28.jpeg"};
    });
    getThunkResponse(builder, getOrganizationEventSponsors, (state, action) => {
      state.organizationEventSponsors = action.payload;
    });
    getThunkResponse(builder, getOrganizationSettings, (state, action) => {
      state.organizationSettings = action.payload;
    });
  },
});

export const getOrganization = createAsyncThunk(
  `${organizationSlice.name}/getOrganization`,
  async (id, thunkAPI) => {
    const response = getResponse(
      gsApi.get,
      organizationRoute([id]),
      {},
      thunkAPI
    );
    return response;
  }
);

export const getOrganizationLookup = createAsyncThunk(
  `${organizationSlice.name}/getOrganizationLookup`,
  async (id, thunkAPI) => {
    const response = getResponse(
      gsApi.get,
      organizationRoute([id, "events-organization-lookup"]),
      {},
      thunkAPI
    );
    return response;
  }
);

export const getOrganizationEventSponsors = createAsyncThunk(
  `${organizationSlice.name}/getOrganizationEventSponsors`,
  async (id, thunkAPI) => {
    const response = getResponse(
      gsApi.get,
      organizationRoute([id, "organization-events-sponsors"]),
      {},
      thunkAPI
    );
    return response;
  }
);

export const getOrganizationSettings = createAsyncThunk(
  `${organizationSlice.name}/getOrganizationSettings`,
  async (id, thunkAPI) => {
    const response = getResponse(
      gsApi.get,
      organizationRoute([id, "organization-setting"]),
      {},
      thunkAPI
    );
    return response;
  }
);

export const {
  setOrganization,
  addNotification,
  clearNotifications,
} = organizationSlice.actions;

//export any selectors needed
export const selectOrganizationDetails = (state) =>
  state?.[organizationSlice.name]?.organizationDetails;

export const selectOrganizationLookup = (state) =>
  state?.[organizationSlice.name]?.organizationLookup;

export const selectOrganizationEventSponsors = (state) =>
  state?.[organizationSlice.name]?.organizationEventSponsors;

export const selectOrganizationSettings = (state) =>
  state?.[organizationSlice.name]?.organizationSettings;

export const selectNotifications = (state) =>
  state?.[organizationSlice.name]?.notifications;

export const selectLoading = (state) =>
  state?.[organizationSlice.name]?.loading;

export default organizationSlice.reducer;

//ADD reducer to store.js
