import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  GSSelect,
  GSToggle,
  defaultLayouts,
  states,
} from "golfstatus_react_components";

export const getEventListFilterForm = (context) => {
  const formSections = [];
  formSections.push({
    title: "Date",
    type: "xx-large-gap vertical",
    fields: [
      {
        label: "Start Date",
        style: { ...defaultLayouts.fullWidth },
        isEditable: true,
        type: "date",
        rightIcon: faTimesCircle,
        rightIconClick: () => {
          context?.updateData("", "start_date");
        },
        ...context.setBinding("start_date"),
      },
      {
        label: "End Date",
        style: { ...defaultLayouts.fullWidth },
        isEditable: true,
        type: "date",
        rightIcon: faTimesCircle,
        rightIconClick: () => {
          context?.updateData("", "end_date");
        },
        ...context.setBinding("end_date"),
      },
    ],
  });
  formSections.push({
    title: "Location",
    type: "vertical xx-large-gap",
    fields: [
      {
        label: "City",
        placeholder: "City",
        isEditable: true,
        ...context.setBinding("city"),
      },
      {
        label: "State",
        placeholder: "State",
        isEditable: true,
        value: (
          <GSSelect
            isClearable
            options={states.USA}
            selectedOption={states?.USA?.find?.(
              (s) => {
                return s.value === context?.getData?.()?.state
              }
            )}
            onChange={(option) => {
              context?.updateData?.(option?.value, "state");
            }}
          />
        ),
        customView: true,
      },
      {
        label: "Postal Code",
        placeholder: "Postal Code",
        isEditable: true,
        ...context.setBinding("postal"),
      },
    ],
  });
  formSections.push({
    title: "Additional Filters",
    type: "vertical xx-large-gap",
    fields: [
      {
        label: "Private Events",
        isEditable: true,
        customView: true,
        description: "Include events that don’t allow public registration in search results.",
        value: (
          <GSToggle {...context.setToggleBinding("include_private_events")} />
        ),
      },
      {
        label: "Closed Registration",
        isEditable: true,
        customView: true,
        description: "Include events that are no longer accepting registrations in search results.",
        value: (
          <GSToggle {...context.setToggleBinding("include_closed_events")} />
        ),
      },
      {
        label: "Past Events",
        isEditable: true,
        customView: true,
        description: "Include events that have already happened in search results.",
        value: <GSToggle {...context.setToggleBinding("past_events")} />,
      },
    ],
  });
  return formSections;
};
