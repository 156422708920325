import React from "react";
import { useSelector } from "react-redux";
import { selectCompletedOrder, selectRegistrationOrder } from "../../reducers/tournamentSlice";
import CartSubtotalItem from "../events/event/cart/cart-subtotal-item";

const CheckoutTotalWidget = (props) => {
  const { complete } = props;
    const completedOrder = useSelector(selectCompletedOrder);
    const inProgressOrder = useSelector(selectRegistrationOrder);
    const registrationOrder = complete ? completedOrder : inProgressOrder;

  return (
    <CartSubtotalItem
      header="Total"
      registrationOrder={registrationOrder}
      total={registrationOrder?.totalCostWithFees}
    />
  );
};

export default CheckoutTotalWidget;
