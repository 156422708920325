import React from "react";
import "./registration-order-summary.scss";
import { GSItemList, GSToggle } from "golfstatus_react_components";
import { moneyWithCommas } from "../helpers/RegexHelper";
import RegistrationOrderSummaryLineItem from "./registration-order-summary-line-item";

const RegistrationOrderSummary = ({
  details,
  billingAddress,
  paymentDetails,
  selectedPaymentOption,
  registrantCanOptToCoverFees,
  isRegistrantCoveringFees,
  toggleRegistrantCoversFees,
}) => {
  const compareCosts = (a, b) => {
    return b.cost - a.cost;
  };

  const isRegistrationOrderPaid = () => {
    return details?.registrationOrder?.status === "paid";
  };

  const registrantCoversFees = () => {
    if (isRegistrationOrderPaid()) {
      return details?.registrationOrder?.registrantCoversFees;
    } else {
      // TODO: this is clearly a function, but throws an error if you try to call it like a function...
      return isRegistrantCoveringFees;
    }
  };

  const getRegistrationOrderTotal = () => {
    return Number(details?.registrationOrder?.purchaseCost) - Number(details?.registrationOrder?.totalFees);
  };

  const calculateFees = () => {
    if (details?.registrationOrder?.totalPlatformFee) {
      return details?.registrationOrder?.totalPlatformFee;
    }
    let fee = 0.0;
    const minimumFee = 1.0;
    if (isRegistrantCoveringFees() && selectedPaymentOption) {
      fee = getRegistrationOrderTotal() * selectedPaymentOption.fee;
      if (fee < minimumFee) {
        fee = minimumFee;
      }
    }
    return fee;
  };

  const calculateInternationalFees = () => {
    return details?.registrationOrder?.internationalFee || 0;
  };

  const getOrderSummaryItems = () => {
    let registrationOrderItems = [];

    details?.registrationOrderItems?.forEach((roi) => {
      const tournamentPackage = details?.packages?.find(
        (p) => p.id === roi.tournamentPackageId
      );
      if (tournamentPackage) {
        registrationOrderItems.push({
          label: `${tournamentPackage.name} (${roi.quantity}x)`,
          value: moneyWithCommas(tournamentPackage.cost),
          cost: tournamentPackage.cost,
        });
      }
    });
    // sort items from largest to smallest before adding fees
    registrationOrderItems.sort(compareCosts);

    if (details?.donationOrderItems?.length > 0) {
      details?.donationOrderItems?.forEach((doi) => {
        registrationOrderItems.push({
          label: "Donation",
          value: moneyWithCommas(doi.amount),
        });
      });
    }

    if (details?.discountCodes?.length > 0) {
      registrationOrderItems.push({
        label: `Discount Code ${details?.discountCodes[0]?.code}`,
        value: `-${moneyWithCommas(
          details?.registrationOrder?.discountAmount
        )}`,
      });
    }

    if (registrantCoversFees()) {
      if (isRegistrationOrderPaid()) {
        const fees = calculateFees();
        registrationOrderItems.push({
          label: "Fees",
          value: moneyWithCommas(fees),
          cost: fees,
        });
        if (details?.registrationOrder?.internationalCardUsed) {
          const internationalFee = calculateInternationalFees();
          registrationOrderItems.push({
            label: "International Card Fee",
            value: moneyWithCommas(internationalFee),
            cost: internationalFee,
          });
        }
      } else {
        if (registrantCanOptToCoverFees()) {
          registrationOrderItems.push({
            label: `Cover ${details?.organization?.name}'s Fees`,
            value: (
              <GSToggle
                value={paymentDetails?.registrantCoversFees}
                trueDescription="Yes"
                falseDescription="No"
                onClick={() => toggleRegistrantCoversFees()}
                rowReverse={true}
              />
            ),
            customView: true,
            isEditable: true,
          });
        }
        if (isRegistrantCoveringFees()) {
          const fees = calculateFees();
          registrationOrderItems.push({
            label: "Fees",
            value: selectedPaymentOption
              ? moneyWithCommas(fees)
              : "Select a Payment Method to Calculate Fees",
            cost: fees,
          });
        }
        // if international card, include international card fee
        if (selectedPaymentOption?.paymentType === "credit_card" && billingAddress?.country && billingAddress?.country !== "US") {
          const internationalFee = calculateInternationalFees();
          registrationOrderItems.push({
            label: "International Card Fee",
            value: moneyWithCommas(internationalFee),
            cost: internationalFee,
          });
        }
      }
    }

    return registrationOrderItems;
  };

  return (
    <registration-order-summary>
      <div className="title">Order Summary</div>
      <GSItemList
        items={getOrderSummaryItems()}
        type="vertical"
        listItem={(item) => <RegistrationOrderSummaryLineItem item={item} />}
      ></GSItemList>
    </registration-order-summary>
  );
};

export default RegistrationOrderSummary;
