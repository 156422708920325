import React from "react";
import "golfstatus_react_components/dist/index.css";
import "./App.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTheme } from "./reducers/appSlice";
import { defaultTheme, defaultLayouts, useMediaQuery } from "golfstatus_react_components";
import GolfstatusPayments from "./components/golfstatus-payments";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


function App() {
  const theme = useSelector(selectTheme);

  const {innerHeight} = useMediaQuery()

  return (
    <Elements stripe={stripePromise}>
        <div
          className={`golfstatus-app ${theme}`}
          style={{
            ...defaultTheme.background?.[theme],
            width: "100vw",
            height: innerHeight,
            ...defaultLayouts.vStack,
            flex: 1
          }}
        >
          <Router>
            <GolfstatusPayments></GolfstatusPayments>
          </Router>
        </div>
    </Elements>
  );
}

export default App;
