import React from "react";
import {
  GSItemList,
  GSSidePanelPage,
  defaultPadding,
  defaultLayouts,
  defaultBorders,
  golfstatusTypography,
  useMediaQuery,
} from "golfstatus_react_components";
import { faArrowRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./event-round-select.scss";
import TournamentRoundSelectItem from "./tournament-round-select-item";
import { getRouting } from "../../../../helpers/Converters";
import {
  ROUND_ID,
  TOURNAMENT_ID,
  eventBaseRoute,
  getProp,
} from "../../../../routes/event";
import { useTheme } from "../../../../hooks/themeHooks";
import PageHeader from "../../../page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTournament } from "../../../../hooks/tournamentHooks";

//Name the component
const EventRoundSelect = (props) => {
  const navigate = useNavigate();
  const tournament = useTournament();

  const match = useMatch(
    getRouting(eventBaseRoute, [TOURNAMENT_ID, "rounds", ROUND_ID, "select"])
  );
  const roundID = getProp(match?.params, ROUND_ID);

  const tournamentID = tournament?.id;

  const { innerWidth } = useMediaQuery();

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Rounds`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const [, theme, getStyle] = useTheme();

  const getborder = (round) => {
    if (round?.id === roundID) {
      return {
        borderColor: getStyle(theme.background).color,
      };
    }
  };

  const getAction = () => {
    if (innerWidth < 900){
      return
    }
    return <FontAwesomeIcon
      style={{
        fontSize: golfstatusTypography.mediumFont,
        lineHeight: golfstatusTypography.squareLineHeight,
      }}
      icon={faArrowRight}
    />;
  };

  const getContent = () => {
    return (
      <gs-form style={{ ...defaultLayouts.vStack }}>
        <PageHeader header="Rounds" />
        <GSItemList
          type="vertical medium-large-gap selectable"
          style={{
            ...defaultPadding.smallLayoutPad.apply("vertical"),
            ...defaultPadding.xLargePad.apply("horizontal"),
          }}
          items={tournament?.tournamentRounds}
          itemSelected={(round) => {
            navigate(
              getRouting(eventBaseRoute, [tournamentID, "rounds", round?.id])
            );
          }}
          listItem={(round) => (
            <TournamentRoundSelectItem
              className="hover-color-shift"
              headerType="body"
              style={{
                ...defaultLayouts.fullWidth,
                ...defaultPadding.xLargePad,
                ...defaultBorders.mediumLargeBorderRadius,
                borderWidth: "4px",
                borderStyle: "solid",
                ...getStyle(theme.outlineVariant),
                ...getStyle(theme.surface),
                ...getborder(round),
              }}
              {...round}
              action={getAction()}
            />
          )}
        />
      </gs-form>
    );
  };

  return (
    //name the component tag
    <event-round-select>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
      ></GSSidePanelPage>
    </event-round-select>
  );
};

//export the new namet
export default EventRoundSelect;
