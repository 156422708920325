import React from "react";
import { Navigate, useMatch } from "react-router-dom";
import { getProp } from "../routes/event";

const GolfstatusRedirect = (props) => {
  const { from, to } = props;

  const match = useMatch(from)

  const routing = to.split("/").map?.(r => getProp(match.params, r) ?? r)

  const redirect = routing.join("/")
  
  return <Navigate replace to={redirect} />;
};

export default GolfstatusRedirect;
