import {
  GSInfoGroup,
  GSSplitView,
  defaultLayouts,
  defaultBorders,
  defaultGapping,
  GSButton,
} from "golfstatus_react_components";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectRoundCourses,
  selectTournamentRound,
} from "../../reducers/tournamentSlice";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { faExternalLinkSquare } from "@fortawesome/free-solid-svg-icons";
import { openExternalLink } from "../../helpers/Utilities";

var head = document.getElementsByTagName("head")[0];

// Save the original method
var insertBefore = head.insertBefore;

// Replace it!
head.insertBefore = function(newElement, referenceElement) {
  if (
    newElement.href &&
    newElement.href.indexOf(
      "https://fonts.googleapis.com/css?family=Roboto"
    ) === 0
  ) {
    return;
  }

  insertBefore.call(head, newElement, referenceElement);
};

const CourseDetailsWidget = () => {
  const currentRound = useSelector(selectTournamentRound);
  const roundCourses = useSelector(selectRoundCourses);

  const { facility } = currentRound ?? {};

  const {
    name,
    address,
    address2,
    city,
    state,
    country,
    website,
    phone,
    latitude,
    longitude,
  } = facility ?? {};

  const facilityNameSection = {
    sectionItems: [{ type: "headline-3", value: name }],
  };

  const addressSection = {
    sectionItems: [
      { type: "secondary", value: address },
      { type: "secondary", value: address2 },
      { type: "secondary", value: `${city}, ${state}` },
      { type: "secondary", value: country },
    ],
  };

  const websiteSection = {
    sectionItems: [{ type: "secondary", value: <div style={{textDecoration: "underline", cursor:"pointer"}} onClick={() => {openExternalLink(website)}}>{website}</div> }],
  };

  const courseSection = {
    sectionItems: roundCourses?.map?.((c) => {
      return {
        type: "primary",
        value: `${c?.course?.name} Course`,
      };
    }),
  };

  const phoneSection = {
    sectionItems: [{ type: "secondary", value: phone }],
  };


  const courseInfoColumn = {
    fit: "stretch",
    type: "full-width",
    sections: [
      facilityNameSection,
      addressSection,
      websiteSection,
      phoneSection,
    ],
  };

  if(roundCourses?.length > 1){
    courseInfoColumn.sections.splice(1, 0, courseSection)
  }

  const columns = [courseInfoColumn];

  return (
    <GSSplitView
      style={{ ...defaultLayouts.fullWidth }}
      left={
        <div
          style={{
            ...defaultLayouts.fill,
            ...defaultLayouts.vStack.align("flex-start", "center"),
          }}
        >
          <GSInfoGroup
            style={{ ...defaultLayouts.fullWidth }}
            dataGroups={columns}
          />
        </div>
      }
      right={
        latitude ? (
          <div
            style={{
              ...defaultLayouts.fill,
              ...defaultLayouts.vStack.align("flex-start", "center"),
              ...defaultGapping.mediumLargeGap
            }}
          >
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <Map
                style={{
                  height: "300px",
                  width: "100%",
                  ...defaultBorders.mediumBorderRadius,
                  overflow: "hidden",
                }}
                center={{ lat: latitude, lng: longitude }}
                defaultZoom={15}
                mapTypeId="satellite"
              />
            </APIProvider>
            <GSButton
            style={{ ...defaultLayouts.fullWidth }}
            type="grey"
            rightIcon={faExternalLinkSquare}
            title="Open in google maps"
            onClick={() => {
              openExternalLink(
                `http://maps.google.com/maps?&z=10&q=${latitude}+${longitude}`
              );
            }}
          />
          </div>
        ) : null
      }
    />
  );
};

export default CourseDetailsWidget;
