import React from "react";
import { useSelector } from "react-redux";
import { selectPublicTournament, selectTournamentSponsors } from "../../reducers/tournamentSlice";
import LeaderboardPresentedBy from "../events/event/leaderboards/leaderboard-presented-by";

const LeaderboardSponsorsWidget = () => {

  const sponsorships = useSelector(selectTournamentSponsors)
  const tournament = useSelector(selectPublicTournament)

  const techSponsors = sponsorships.filter(
    (sponsor) => sponsor.sponsorshipType === "leaderboard"
  );

  return <LeaderboardPresentedBy techSponsors={techSponsors} {...tournament?.tournamentPromotion} />
}

export default LeaderboardSponsorsWidget