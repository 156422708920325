import {
  GSCircleImage,
  GSInfoGroup,
  GSItemList,
  defaultGapping,
  defaultTypography,
} from "golfstatus_react_components";
import React from "react";
import "./team-line-item.scss";

/**
 * Team and player info group.
 * @typedef player
 * @type {object}
 * @property {string} avatar avatar url of the player
 * @property {string} name name of the player
 *
 * @typedef Properties
 * @type {object}
 * @property {string} name name of team
 * @property {string} contactName name of the team contact
 * @property {Array.<player>} players players on the team
 * @property {object} style style for the component container
 *
 * @param {Properties} props component properties
 */

const TeamLineItem = (props) => {
  const { name, contactName, players, style } = props;

  const defaultStyle = { ...defaultGapping.mediumLargeGap };

  const avatarSection = (avatar) => {
    return {
      sectionItems: [
        {
          type: " no-wrap full-width",
          value: <GSCircleImage url={avatar} size="large" />,
        },
      ],
    };
  };

  const playerInfoSection = (player) => {
    return {
      sectionItems: [
        {
          type: "secondary no-wrap full-width",
          value: player.name,
        },
      ],
    };
  };

  const avatarColumn = (player) => {
    return { sections: [avatarSection(player.avatar)] };
  };

  const teamInfoColumn = (player) => {
    return {
      sections: [playerInfoSection(player)],
    };
  };

  const getPlayer = (player) => {
    let group = [avatarColumn(player), teamInfoColumn(player)];
    return group;
  };

  return (
    <team-line-item style={{ ...defaultStyle, ...style }}>
      <div style={{ ...defaultTypography.body }}>
        {name ?? `${contactName}'s Team`}
      </div>
      <GSItemList
        type="horizontal wrapped large-gap mobile-vertical"
        items={players}
        listItem={(player) => (
          <GSInfoGroup
            listStyle={{ ...defaultGapping.mediumGap }}
            dataGroups={getPlayer(player)}
          ></GSInfoGroup>
        )}
      />
    </team-line-item>
  );
};

export default TeamLineItem;
