import { defaultLayouts, defaultPadding, GSItemList } from "golfstatus_react_components";
import React from "react";
import { useTheme } from "../../hooks/themeHooks";
import { useSelector } from "react-redux";
import { selectCompletedOrder, selectRegistrationOrder } from "../../reducers/tournamentSlice";
import AbbreviatedOrderItem from "../events/event/cart/abbreviate-order-item";

const CheckoutItemsWidget = (props) => {

  const {complete} = props
  const completedOrder = useSelector(selectCompletedOrder)
  const inProgressOrder = useSelector(selectRegistrationOrder)
  const registrationOrder = complete ? completedOrder : inProgressOrder 
  
  const [, theme, getStyle] = useTheme()

   return <GSItemList
   type="vertical"
   listStyle={{ gap: "1px" }}
   style={{ ...defaultLayouts.fullWidth, ...getStyle?.(theme?.surfaceDim) }}
   items={registrationOrder.registrationOrderItems}
   listItem={(item) => (
     <AbbreviatedOrderItem
       style={{
         ...defaultLayouts.fullWidth,
         ...getStyle(theme.surfaceVariant),
         ...defaultPadding.largePad.apply("vertical")
       }}
       {...item}
     />
   )}
 />
}

export default CheckoutItemsWidget