import React from "react";

import { useNavigate } from "react-router-dom";
import { getRouting } from "../../helpers/Converters";
import { eventBaseRoute } from "../../routes/event";
import BecomeASponsor from "../events/event/sponsors/become-a-sponsor";
import { useTournament } from "../../hooks/tournamentHooks";

const BecomeASponsorWidget = () => {

  const tournament = useTournament()
  const navigate = useNavigate()


  return <BecomeASponsor
  tournament={tournament}
  navigate={() => {
    navigate(
      getRouting(eventBaseRoute, [
        tournament?.publicUrl,
        "packages?view=sponsor",
      ])
    );
  }}
/>
}

export default BecomeASponsorWidget