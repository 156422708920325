import {
  faArrowRight,
  faCheckCircle,
  faExclamationCircle,
  faFilter,
  faPen,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  GSEmptyList,
  defaultPadding,
  GSPageSection,
  defaultLayouts,
} from "golfstatus_react_components";
import React, { useEffect, useMemo } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePricing,
  getTournamentPaymentSettings,
  selectRegistrationOrder,
  updateOrder,
} from "../../../../reducers/tournamentSlice";
import "./event-cart.scss";
import {
  useHeaderStyle,
  useSectionStyles,
  useTheme,
} from "../../../../hooks/themeHooks";
import CartStepItem from "./cart-step-item";
import {
  RegistrationOrder,
  getOrderItemCount,
  getPrice,
  getRegistrationSummary,
  updatePlayerDetails,
  updateSponsorDetails,
  updateTeamContact,
  validateOrder,
} from "../../../../models/Registration";
import { getRouting } from "../../../../helpers/Converters";
import {
  ORDER_ID,
  TOURNAMENT_ID,
  eventBaseRoute,
} from "../../../../routes/event";
import PageHeader from "../../../page-header";
import { moneyWithCommas } from "../../../../helpers/RegexHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigationButtons } from "../../../../hooks/pageHooks";
import PageFooter from "./page-footer";
import CartContactWidget from "../../../widgets/cart-contact-widget";
import CartMyPackagesWidget from "../../../widgets/cart-my-packages-widget";

import CartCustomResponses from "../../../widgets/cart-custom-responses";
import CartDonationWidget from "../../../widgets/cart-donation-widget";
import { usePermissions } from "../../../../hooks/permissionHooks";
import { getUserPaymentOptions } from "../../../../reducers/userSlice";

import {
  useCustomer,
  useLoading,
  usePaymentOptions,
  useTournament,
} from "../../../../hooks/tournamentHooks";
import { getCurrentUser } from "../../../../app/gs-session";
import { BANK_ACCOUNT, CREDIT_CARD, CUSTOMER_INVOICE, getActivePaymentMethods, PAY_AT_COURSE } from "../../../../forms/CartSections";

const EventCart = (props) => {
  const { style } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const route = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "cart",
    ORDER_ID,
    "*",
  ]);
  const match = useMatch(route);

  const tournament = useTournament();
  const publicUrl = tournament?.publicUrl
  const tournamentID = tournament?.id
    
    useEffect(() => {
      if(tournamentID){
        dispatch(getTournamentPaymentSettings(tournamentID))
      }
    },[dispatch, tournamentID])

  const [isLoading] = useLoading("getPublicTournamentDetails")

  
  const registrationOrder = useSelector(selectRegistrationOrder);
  const {customer, customerComplete} = useCustomer();
  const savedPaymentOptions = usePaymentOptions().availablePayments;

  const lastCard = savedPaymentOptions?.findLast?.((po) => {
    return po.paymentType === CREDIT_CARD && po.hasGlobal;
  });

  const [, theme, getStyle] = useTheme();
  const { defaultSectionStyle, headerSectionStyle } = useSectionStyles();

  const { headerStyle } = useHeaderStyle();

  const permissions = usePermissions();

  const getPageActions = useNavigationButtons("cart");

  useEffect(() => {
    const user = getCurrentUser();
    if (user?.uid) {
      dispatch(getUserPaymentOptions(user.uid));
    } 
  }, [dispatch]);

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Your Cart Is Empty"
        detail=" First, you’ll need to add a package."
        actions={[
          {
            title: "View All Packages",
            type: "black",
            onClick: () => {
              navigate(getRouting(eventBaseRoute, [publicUrl, "packages"]));
            },
          },
        ]}
        style={{ ...defaultLayouts.fullWidth, ...defaultPadding.emptyListPad }}
      />
    );
  };

  const getEmptyListMessagePrivateEvent = () => {
    return (
      <GSEmptyList
        title="Your Cart Is Empty"
        detail={
          <center>
            {
              "This is a private event, please contact the organizer for access to the packages."
            }
          </center>
        }
        actions={[
          {
            title: "View Event Details",
            type: "black",
            onClick: () => {
              navigate(getRouting(eventBaseRoute, [publicUrl]));
            },
          },
        ]}
        style={{ ...defaultLayouts.fullWidth, ...defaultPadding.emptyListPad }}
      />
    );
  };

  const getEmptyListMessageDonationsActive = () => {
    return (
      <GSEmptyList
        title="Your Cart Is Empty"
        detail={
          <center>
            {
              "Registration has been closed for this event, but you can still donate."
            }
          </center>
        }
        actions={[
          {
            title: "Donate",
            type: "black",
            onClick: () => {
              navigate(getRouting(eventBaseRoute, [publicUrl, "donate"]));
            },
          },
        ]}
        style={{ ...defaultLayouts.fullWidth, ...defaultPadding.emptyListPad }}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getNextStep = () => {
    if (!customerComplete()) {
      return {
        nextStep: "Add Contact Details",
        icon: (
          <FontAwesomeIcon
            style={{ ...theme.error.light }}
            icon={faExclamationCircle}
          />
        ),
        actions: [
          {
            title: "Add Details",
            buttonIcon: faPen,
            type: "orange",
            onClick: () => {
              navigate("customer");
            },
          },
          {
            title: "sign in",
            type: "grey",
            onClick: () => {
              window.location.href = process.env.REACT_APP_GOLFSTATUS_LOGIN_URL;
            },
          },
        ],
      };
    }

    const validations = validateOrder(registrationOrder, navigate);
    if (validations.length > 0) {
      return validations[0];
    }

    const itemCount = registrationOrder?.registrationOrderItems?.length;
    const donationCount = registrationOrder?.donationOrderItems?.length;

    if (itemCount + donationCount === 0) {
      return {
        nextStep: "Add Packages",
        icon: (
          <FontAwesomeIcon
            style={{ ...theme.error.light }}
            icon={faExclamationCircle}
          />
        ),
        actions: [
          {
            title: "Packages",
            rightIcon: faArrowRight,
            type: "grey",
            onClick: () => {
              navigate(getRouting(eventBaseRoute, [publicUrl, "packages"]));
            },
          },
        ],
      };
    }

    return {
      nextStep: "Next Step: Payment & Review",
      icon: (
        <FontAwesomeIcon
          style={{ ...getStyle(theme.tertiary) }}
          icon={faCheckCircle}
        />
      ),
      actions: [
        {
          title: "Continue",
          rightIcon: faArrowRight,
          type: "black",
          onClick: () => {
            continueToPayment();
          },
        },
      ],
    };
  };

  const continueToPayment = () => {
    const order = { ...registrationOrder };
    updateTeamContact(order, customer);
    updatePlayerDetails(order, customer);
    updateSponsorDetails(order, customer);
    order.paymentOptionKey = lastCard?.id;
    const activePaymentMethods = getActivePaymentMethods(permissions)?.map?.(apm => apm.id)
    if(activePaymentMethods?.includes?.(1)){
      order.paymentType = CREDIT_CARD
    }
    else if(activePaymentMethods?.includes?.(2)){
      order.paymentType = BANK_ACCOUNT
    }
    else if (activePaymentMethods?.includes?.(4)){
      order.paymentType = CUSTOMER_INVOICE
    }
    else if(activePaymentMethods?.includes?.(3)){
      order.paymentType = PAY_AT_COURSE
    }
    else{
      console.log(permissions)
      console.log(activePaymentMethods)
    }

    dispatch(updateOrder(order));
    dispatch(calculatePricing(order));
    navigate("checkout");
  };

  const cartActions = [
    {
      title: "restart order",
      buttonIcon: faRotateLeft,
      onClick: () => {
        dispatch(
          updateOrder({
            ...RegistrationOrder,
            id: registrationOrder.id,
            tournament,
          })
        );
      },
    },
  ];

  const showEmptyList = () => {
    if (
      !hasRegistrationOrderItems &&
      !hasDonation &&
      !permissions.publicRegistration
    ) {
      return getEmptyListMessagePrivateEvent();
    }

    if (
      !hasRegistrationOrderItems &&
      !hasDonation &&
      !permissions.registrationClosed
    ) {
      return getEmptyListMessage();
    }

    if (
      !hasRegistrationOrderItems &&
      !hasDonation &&
      permissions.donationsActive()
    ) {
      return getEmptyListMessageDonationsActive();
    }
  };

  const hasDonation = useMemo(() => {
    return (
      registrationOrder?.donationOrderItems?.filter?.((d) => !d.roundUp)
        ?.length > 0
    );
  }, [registrationOrder?.donationOrderItems]);

  const hasRegistrationOrderItems =
    registrationOrder?.registrationOrderItems?.length > 0;

  const getItemList = () => {
    const reviewItems = [<CartMyPackagesWidget />];

    if (registrationOrder?.customFieldResponses?.length > 0) {
      reviewItems.push(<CartCustomResponses />);
    }

    return (
      <div style={{ ...defaultLayouts.vStack }}>
        <PageHeader header="My Cart" actions={cartActions} />

        {hasRegistrationOrderItems || hasDonation ? (
          <GSPageSection
            {...headerSectionStyle}
            body={[<CartContactWidget />]}
          />
        ) : null}

        {showEmptyList()}

        {hasRegistrationOrderItems ? (
          <GSPageSection
            title="My Packages"
            bodyStyle={{ ...defaultPadding.noPad }}
            style={defaultSectionStyle?.style}
            body={reviewItems}
          />
        ) : null}

        {hasDonation ? (
          <GSPageSection
            title="My Donation"
            bodyStyle={{ ...defaultPadding.noPad }}
            style={defaultSectionStyle?.style}
            body={[<CartDonationWidget />]}
          />
        ) : null}

        <PageFooter showFooter={hasRegistrationOrderItems || hasDonation}>
          <CartStepItem
            itemCount={getOrderItemCount(registrationOrder)}
            
            {...getNextStep()}
            description={getRegistrationSummary(
              registrationOrder,
              moneyWithCommas(registrationOrder?.totalCost ? registrationOrder?.totalCost : getPrice(registrationOrder))
            )}
            style={{
              ...defaultLayouts.vStack,
              ...defaultLayouts.fullWidth,
              maxWidth: "1080px",
            }}
          />
        </PageFooter>
      </div>
    );
  };

  return (
    <event-cart style={{ ...style }}>
      <GSListPage
        title=""
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        headerStyle={headerStyle}
        loading={isLoading}
      ></GSListPage>
    </event-cart>
  );
};

export default EventCart;
