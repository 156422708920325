import { faLock } from "@fortawesome/free-solid-svg-icons";
import {
  defaultGapping,
  defaultLayouts,
  defaultTypography,
  GSButton,
  GSItemList,
  GSSplitView,
} from "golfstatus_react_components";
import React, { useEffect } from "react";
import CartPaymentMethod from "../events/event/cart/cart-payment-method";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePricing,
  clearPayment,
  selectRegistrationOrder,
  updateOrder,
} from "../../reducers/tournamentSlice";
import { setSelectedPaymentOption } from "../../reducers/invoiceSlice";
import { BANK_ACCOUNT, CREDIT_CARD, getActivePaymentMethods } from "../../forms/CartSections";
import { usePermissions } from "../../hooks/permissionHooks";
import { usePaymentOptions } from "../../hooks/tournamentHooks";



const PaymentSelectWidget = () => {
  const dispatch = useDispatch();

  const registrationOrder = useSelector(selectRegistrationOrder);
  const permissions = usePermissions()
  const savedPaymentOptions = usePaymentOptions().availablePayments

  const lastCard = savedPaymentOptions?.findLast?.(po => {
    return po.paymentType === CREDIT_CARD
  })

  const lastBank = savedPaymentOptions?.findLast?.(po => {
    return po.paymentType === BANK_ACCOUNT
  })

  const currentPaymentMethod = getActivePaymentMethods(permissions).find?.(
    (m) => m.type === registrationOrder?.paymentType
  );

  const updatePaymentType = (type) => {
    let defaultPaymentKey = null
    if(type === BANK_ACCOUNT && lastBank?.id){
      defaultPaymentKey = lastBank?.id
      dispatch(setSelectedPaymentOption(lastBank))
    }
    else if(type === CREDIT_CARD ){
      if(lastCard?.id){
        defaultPaymentKey = lastCard.id
        dispatch(setSelectedPaymentOption(lastCard))
      }
      else{
        dispatch(setSelectedPaymentOption(null))
      }
      
    }
    
    const ru =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => d?.roundUp)
        ?.map?.((d) => {
          return { round_up: true };
        }) ?? [];
    const don =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => !d?.roundUp)
        ?.map?.((d) => {
          return { id: d?.id, amount: d?.amount };
        }) ?? [];

    const update = {
      ...registrationOrder,
      paymentType: type,
      globalToken: null,
      stripeToken: null,
      cardFirstName: null,
      cardLastName: null,
      card: null,
      internationalCardUsed: false,
      paymentOptionKey: defaultPaymentKey,
      donationOrderItems: [...ru, ...don],
      saveCard: false
    };
    dispatch(updateOrder(update));
    dispatch(calculatePricing(update));
  };

  useEffect(() => {
    return () => {dispatch(clearPayment())}
  },[dispatch])

  const activePayments = getActivePaymentMethods({...permissions, hasBankAccountAttached: !!lastBank})

  return (
    <GSSplitView
      style={{ ...defaultLayouts.fullWidth }}
      dividerStyle={{ width: 0 }}
      left={
        <div
          style={{
            ...defaultLayouts.vStack.align("flex-start", "center"),
            ...defaultLayouts.fill,
            ...defaultGapping.xLargeGap,
          }}
        >
          <GSButton
            title="Secure Checkout"
            type="white pill secondary"
            buttonIcon={faLock}
          />
          <div style={{
            ...defaultLayouts.vStack.align("flex-start", "center"),
            ...defaultGapping.mediumLargeGap,
          }}>
            <h2 style={{ ...defaultTypography.headline2 }}>
              Let’s Wrap This Up
            </h2>
            <div style={{ ...defaultTypography.bodyRegular, opacity: 0.7 }}>
              Select how you’d like to pay.
            </div>
          </div>
        </div>
      }
      right={
        <GSItemList
          type="inline-grid medium-large-gap"
          style={{ ...defaultLayouts.fullWidth }}
          gridColumns={Math.min(activePayments.length, 2)}
          items={activePayments}
          listItem={(item) => (
            <CartPaymentMethod
              className="hover-outline"
              style={{ ...defaultLayouts.fill }}
              {...item}
              isSelected={item?.id === currentPaymentMethod?.id}
              onClick={() => {
                updatePaymentType(item?.type);
              }}
            />
          )}
        />
      }
    />
  );
};

export default PaymentSelectWidget;
