import { GSItemList,defaultPadding, defaultLayouts, defaultBorders, GSEmptyList } from "golfstatus_react_components";
import React from "react";
import TeamLineItem from "../events/event/teams/team-line-item";
import { useSelector } from "react-redux";
import { selectLoading, selectTournamentTeams } from "../../reducers/tournamentSlice";
import { useTheme } from "../../hooks/themeHooks";
import { usePermissions } from "../../hooks/permissionHooks";

const TournamentRegistrantsWidget = (props) => {

  const loading = useSelector(selectLoading)
  const tournamentTeams = useSelector(selectTournamentTeams)?.filter?.(t => !t?.disqualified)
  const permissions = usePermissions()

  const [,theme, getStyle] = useTheme()

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Registrants"
        detail= {permissions?.packagesEnabled() ? "No one has registered for this event yet... be the first to register!" : ""}
        actions={permissions?.packagesEnabled() ? [
          {
            title: "Register",
            type: "black",
            onClick: () => {},
          },
        ]:[]}
      />
    );
  };

  return (
    <GSItemList
      style={{ width: "100%" }}
      type="vertical medium-large-gap"
      items={tournamentTeams}
      listItem={(team) => (
        <TeamLineItem
          style={{
            ...defaultPadding.xLargePad,
            ...getStyle(theme.surfaceVariant),
            ...defaultLayouts.fullWidth,
            ...defaultBorders.mediumLargeBorderRadius,
          }}
          {...team}
        />
      )}
      emptyMessage={getEmptyListMessage()}
      loading={loading.includes?.("getTournamentTeams")}
      loadingMainText="Loading Teams..."
      loadingSubtext="please wait."
    />
  );
};


export default TournamentRegistrantsWidget