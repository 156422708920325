import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRegistrationOrder, selectTournamentPaymentSettings, updateOrder } from "../../reducers/tournamentSlice";
import DonationLineItem from "../events/donation-line-item";
import { useNavigate } from "react-router-dom";
import { getRouting } from "../../helpers/Converters";
import { eventBaseRoute } from "../../routes/event";
import { newDonationOrderItem } from "../../models/Registration";
import { useTournament } from "../../hooks/tournamentHooks";

const TournamentDonationWidget = () => {
  const tournament = useTournament()
  const tournamentPaymentSettings = useSelector(selectTournamentPaymentSettings)
  const registrationOrder = useSelector(selectRegistrationOrder)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const donationSelected = (amount) => {
    const ru =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => d?.roundUp)
        ?.map?.((d) => {
          return { round_up: true };
        }) ?? [];
    const update = {
      ...registrationOrder,
      donationOrderItems: [...ru, newDonationOrderItem(amount)],
    };
    dispatch(updateOrder(update));
    navigate(getRouting(eventBaseRoute, [tournament?.publicUrl, "donate"]))
  }

  return <DonationLineItem {...tournament} {...tournamentPaymentSettings} donationSelected={donationSelected} />;
};

export default TournamentDonationWidget;
