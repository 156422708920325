import React from "react";
import { useSelector } from "react-redux";
import {
  selectPublicTournament,
  selectTournamentSponsors,
} from "../../reducers/tournamentSlice";
import TechSponsorLineItem from "../events/tech-sponsor-line-item";
import { GSItemList } from "golfstatus_react_components";

export const useTechSponsors = () => {
  const tournament = useSelector(selectPublicTournament);
  const sponsorships = useSelector(selectTournamentSponsors);

  const techSponsors = sponsorships.filter(
    (sponsor) => sponsor.sponsorshipType === "leaderboard"
  );

  const getTechSponsorSections = () => {
    const sponsors = [];

    if (tournament?.tournamentPromotion?.secondaryEventPhoto) {
      sponsors.unshift(
        <TechSponsorLineItem
          logo={tournament?.tournamentPromotion?.secondaryEventPhoto}
          message={tournament?.tournamentPromotion?.secondaryEventPhotoText}
        />
      );
    }

    if (techSponsors?.length > 0) {
      sponsors.unshift(
        <GSItemList
          items={techSponsors}
          type="vertical"
          style={{ width: "100%" }}
          listItem={(sponsor) => (
            <TechSponsorLineItem style={{ width: "100%" }} {...sponsor} />
          )}
        />
      );
    }

    return sponsors;
  };

  return [getTechSponsorSections]
};

