import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectRegistrationOrder, setAccessCodes } from "../../reducers/tournamentSlice";
import { useNavigate } from "react-router-dom";
import { defaultTypography, GSButton, GSInfoGroup } from "golfstatus_react_components";
import { faArrowRight, faCircleCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { getRouting } from "../../helpers/Converters";
import { eventBaseRoute } from "../../routes/event";
import { useTournament } from "../../hooks/tournamentHooks";

const PackageAccessWidget = (props) => {
  const {style} = props
  const tournament = useTournament()
  const registrationOrder = useSelector(selectRegistrationOrder)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const noAccessColumns = () => {
    const titleSection = {
      gap: "medium-large-gap",
      sectionItems: [
        {
          type: "headline-2",
          value: "Exclusive Packages",
        },
        {
          type: "secondary",
          value: `To purchase certain packages for this event, you must have a code.`,
        },
      ],
    };
  
    const actionSection = {
      sectionItems: [
        {
          type: "mobile-stretch",
          value: (
            <GSButton
              style={{ flexGrow: 1 }}
              title="Get Access"
              rightIcon={faArrowRight}
              type="black"
              onClick={() => {
                navigate(
                  getRouting(eventBaseRoute, [
                    tournament?.publicUrl,
                    "packages",
                    "access"
                  ])
                );
              }}
            />
          ),
        },
      ],
    };
  
    const titleColumn = {
      fit: "stretch",
      sections: [titleSection],
    };
  
    const actionColumn = {
      type: "mobile-stretch",
      sections: [actionSection],
    };
  
    return [titleColumn, actionColumn];
  }

  const accessGrantedColumns = () => {
    const titleSection = {
      sectionItems: [
        {
          type: "headline-2",
          value: "Access List Credentials",
        },
      ],
    };

    const infoSection = {

      sectionItems: [
        {
          type: "",
          sectionItemStyle: {...defaultTypography.body}, 
          value: registrationOrder?.accessIdentifier,
        },
        {
          type: "secondary",
          value: registrationOrder?.accessEmail,
        },
      ],
    };

    const statusSection = {
      sectionItems: [
        {
          type: "",
          value: <GSButton type="secondary green pill" title="Validated" buttonIcon={faCircleCheck}/>,
        },
      ],
    };
  
    const actionSection = {
      sectionItems: [
        {
          type: "mobile-stretch",
          value: (
            <GSButton
              style={{ flexGrow: 1 }}
              rightIcon={faTrash}
              type="grey"
              onClick={() => {
                dispatch(setAccessCodes([]))
              }}
            />
          ),
        },
      ],
    };
  
    const titleColumn = {
      fit: "stretch",
      sections: [titleSection, infoSection, statusSection],
    };
  
    const actionColumn = {
      type: "mobile-stretch",
      sections: [actionSection],
    };
  
    return [titleColumn, actionColumn];
  }


  

  return (
    <GSInfoGroup
      listType="mobile-vertical"
      style={style}
      dataGroups={registrationOrder?.accessValidated ? accessGrantedColumns(): noAccessColumns()}
    />
  );
}

export default PackageAccessWidget