import React from "react";

import { useSelector } from "react-redux";
import { selectPublicTournament } from "../../reducers/tournamentSlice";
import { defaultBorders } from "golfstatus_react_components";
const TournamentVideoWidget = () => {
  const tournament = useSelector(selectPublicTournament);
  return (
    <video
      style={{width: "100%", ...defaultBorders.mediumLargeBorderRadius}}
      src={tournament?.tournamentPromotion?.promotionalVideo}
      controls
    ></video>
  );
};

export default TournamentVideoWidget;
