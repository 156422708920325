import {
  GSInfoGroup,
  GSProgressBar,
  defaultPadding,
  GSItemList,
  defaultLayouts,
  defaultBorders,
  defaultTypography,
  defaultGapping,
  useMediaQuery,
} from "golfstatus_react_components";
import React from "react";
import "./donation-line-item.scss";
import { moneyWithCommas } from "../../helpers/RegexHelper";
import { useTheme } from "../../hooks/themeHooks";
import { usePermissions } from "../../hooks/permissionHooks";

/**
 * Donation info group.
 *
 * @typedef Properties
 * @type {object}
 * @property {string} donationGoal goal for donation set by organization
 * @property {string} totalDonationAmount current amount of donations
 * @property {Array} customDonationValues values of donations to select from
 * @property {function} donationSelected function to run when donation is selected from options
 *
 * @param {Properties} props
 */
const DonationLineItem = (props) => {
  const {
    donationGoal,
    totalDonationAmount,
    customDonationValues,
    donationSelected,
    style,
    hideValues,
  } = props;

  const [, theme, getStyle] = useTheme();

  const { isTablet, isPhone } = useMediaQuery();

  const permissions = usePermissions();

  const trackColor = getStyle(theme.secondaryContainer)?.backgroundColor;



  const donationInfoSection = {
    sectionItems: [
      {
        type: "no-wrap full-width",
        style: { ...defaultLayouts.hStack.align("center", "center") },
        value: (
          <div
            style={{
              ...defaultLayouts.fullWidth,
              ...defaultLayouts.hStack.align("flex-start", "center"),
              ...defaultGapping.smallGap,
              flexWrap: "wrap",
            }}
          >
            {permissions.showDonationAmount() ?
            <>
            <span style={{ ...defaultTypography.body }}>
              {moneyWithCommas(totalDonationAmount)} Raised
            </span>{" of "}
            </>
             :null}
            
            <span style={{ ...defaultTypography.bodyRegular }}>
              {moneyWithCommas(donationGoal)} Donation Goal
            </span>
          </div>
        ),
      },
    ],
  };

  const progressBarSection = {
    sectionItems: [
      {
        type: " no-wrap full-width",
        value: (
          <GSProgressBar
            value={totalDonationAmount}
            max={donationGoal}
            trackStyle={{
              background: trackColor,
            }}
            style={{
              ...getStyle(theme.background),
              width: "100%",
              paddingTop: "0",
              paddingBottom: "0",
              color: "transparent",
            }}
          />
        ),
      },
    ],
  };

  const donationInfoColumn = {
    fit: "stretch",
    style: { width: "100%" },
    sections: [donationInfoSection, progressBarSection],
  };

  const getGroup = () => {
    let group = [donationInfoColumn];
    return group;
  };

  return (
    <donation-line-item style={style}>
      <div
        style={{
          ...defaultLayouts.vStack,
          ...defaultLayouts.fullWidth,
          ...defaultGapping.xLargeGap,
        }}
      >

        {donationGoal ? <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup()} /> :null}
        

        {!hideValues ? (
          <GSItemList
            type="inline-grid medium-large-gap selectable"
            style={{ ...defaultLayouts.fullWidth }}
            gridColumns={isTablet() ? 2 : isPhone() ? 1 : 4}
            items={customDonationValues ?? []}
            itemSelected={(item) => {
              donationSelected?.(item);
            }}
            listItem={(donation) => (
              <div
                className="donation-option hover-outline"
                style={{
                  ...defaultPadding.xLargePad,
                  ...defaultLayouts.fullWidth,
                  ...getStyle(theme.surface),
                  ...defaultLayouts.vStack.align("center", "center"),
                  ...defaultBorders.mediumLargeBorderRadius,
                  ...defaultTypography.body,
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    ...defaultTypography.overline2,
                    opacity: 0.7,
                    ...defaultTypography.uppercase,
                  }}
                >
                  Donate
                </div>
                {moneyWithCommas(donation, true)}
              </div>
            )}
          />
        ) : null}
      </div>
    </donation-line-item>
  );
};

export default DonationLineItem;
