import React from "react";
import {
  GSAddressForm,
  GSGlobalCardElement,
  GSInfoGroup,
  GSQuickFilter,
  GSRadioGroup,
  GSToggle,
  defaultLayouts,
  defaultBorders
} from "golfstatus_react_components";
import PaymentOption from "../components/payment-option";
import RegistrationOrderSummary from "../components/registration-order-summary";
import StripeCard from "../components/stripe-card";
import {moneyWithCommas} from "../helpers/RegexHelper";
import CustomerInvoiceSummary from "../components/customer-invoice/customer-invoice-summary";

export const getPaymentFormSections = (context) => {
  if (context) {
    const paymentOptionSection = {
      type: "vertical large-gap",
      title: "Payment Method",
      fields: [
        {
          value: (
            <GSQuickFilter
              searchListType={"medium-large-gap"}
              selectedItem={context.selectedPaymentOption}
              filteredList={context.getPaymentOptions()}
              itemSelected={(item) => context.onSelectedPaymentOptionChange(item)}
              itemRemoved={() => context.onSelectedPaymentOptionChange(null)}
              itemStyle={{...defaultBorders.primaryBorder.themed(context.mode), ...defaultBorders.mediumBorderRadius}}
              getItem={(paymentOption) => (
                <PaymentOption {...paymentOption} displayFees={context.isRegistrantCoveringFees} />
              )}
            />
          ),
          customView: true,
          isEditable: true,
          required: true,
        },
      ],
    };

    const orderSummary = {
      type: "vertical large-gap",
      fields: [
        {
          value: (<RegistrationOrderSummary {...context} />),
          customView: true,
        },
      ],
    };

    const getInvoiceTotal = () => {
      const total = context?.getTotalCostWithFees();
      let groups = [
        {
          sections: [
            {
              sectionItems: [
                {
                  type: "primary no-wrap",
                  value: "Invoice Total",
                },
              ],
            },
          ],
        },
        {
          sections: [
            {
              sectionItems: [
                {
                  type: "primary no-wrap total",
                  value: moneyWithCommas(total),
                },
              ],
            },
          ],
        },
      ];

      return (
        <div style={context?.totalStyle} className={"total-container"}>
          <GSInfoGroup dataGroups={groups} listType={"total-info"} />
        </div>
      );
    };

    const totalBanner = {
      type: "no-pad",
      style: {...defaultLayouts.stickyBottom, ...defaultLayouts.stickyTop, zIndex: "1"},
      fields: [
        {
          value: (getInvoiceTotal()),
          customView: true,
        },
      ],
    };

    const cardDetails = {
      type: "vertical large-gap",
      title: "Card Details",
      fields: [
        {
          label: "Cardholder First Name",
          placeholder: "First Name",
          value: context.paymentDetails?.firstName,
          failedValidation: context?.validationFailed,
          onChange: (e) => context.onPaymentDetailsChange({...context.paymentDetails, firstName: e.target.value}),
          isEditable: true,
          required: true,
        },
        {
          label: "Cardholder Last Name",
          placeholder: "Last Name",
          value: context.paymentDetails?.lastName,
          failedValidation: context?.validationFailed,
          onChange: (e) => context.onPaymentDetailsChange({...context.paymentDetails, lastName: e.target.value}),
          isEditable: true,
          required: true,
        },
        {
          customView: true,
          value: context.details?.organization?.isEscrowAccount ? (
            <GSGlobalCardElement
              apiKey={process.env.REACT_APP_GLOBAL_KEY}
              environment={process.env.REACT_APP_GLOBAL_ENV}
              autoComplete={true}
              onSuccess={context.submitPayment}
              onError={(e) => console.log(e)} // TODO: provide an error handler?
              formValid={Boolean(context.captchaToken)}
              saveBtnQueryStr={"customer-invoice-pay gs-button.black.primary"}
            />
          ) : (
            <StripeCard onChange={context.onCreditCardChange} />
          ),
          isEditable: true,
          required: true,
        },
      ],
    };
    // only allow new saved payments for escrow accounts using Global
    if (context.details?.organization?.isEscrowAccount && context.isUserLoggedIn()) {
      cardDetails.fields.push({
        label: "Add Card To My Profile",
        value: (
          <GSToggle
            value={context.paymentDetails?.savePayment}
            label="Use this card for future purchases."
            trueDescription="Yes"
            falseDescription="No"
            onClick={() => context.onPaymentDetailsChange({...context.paymentDetails, savePayment: !context.paymentDetails?.savePayment})}
          />
        ),
        customView: true,
        isEditable: true,
      });
    }

    const billingAddress = {
      type: "vertical large-gap",
      title: "Billing Address",
      fields: [
        {
          customView: true,
          failedValidation: context.validationFailed,
          validation: {
            isValid: () => Boolean(context.billingAddress?.country && context.billingAddress?.address && context.billingAddress?.city && context.billingAddress?.state && context.billingAddress?.postal),
            invalidLabel: 'Must complete billing address.',
          },
          isEditable: true,
          label: "Billing Address *",
          required: true,
          value: (
            <GSAddressForm
              {...context.billingAddress}
              onChange={context.onBillingAddressChange}
              requiredFields={['country', 'address', 'city', 'state', 'postal']}
            />
          ),
        },
      ],
    };

    const accountTypeOptions = [
      { label: "Checking", value: "checking" },
      { label: "Savings", value: "savings" },
    ];
    const accountDetails = {
      type: "vertical large-gap",
      title: "Account Details",
      fields: [
        {
          label: "Account Type",
          value: (
            <GSRadioGroup
              selectedOption={accountTypeOptions.find((at) => at.value === context.paymentDetails?.accountType)}
              selectionChanged={(e) => context.onPaymentDetailsChange({...context.paymentDetails, accountType: e.value})}
              options={accountTypeOptions}
              isLtr={true}
            />
          ),
          failedValidation: context.validationFailed,
          customView: true,
          isEditable: true,
          required: true,
        },
        {
          label: "Account Holder First Name",
          placeholder: "First Name",
          value: context.paymentDetails?.firstName,
          failedValidation: context.validationFailed,
          onChange: (e) => context.onPaymentDetailsChange({...context.paymentDetails, firstName: e.target.value}),
          isEditable: true,
          required: true,
        },
        {
          label: "Account Holder Last Name",
          placeholder: "Last Name",
          value: context.paymentDetails?.lastName,
          failedValidation: context.validationFailed,
          onChange: (e) => context.onPaymentDetailsChange({...context.paymentDetails, lastName: e.target.value}),
          isEditable: true,
          required: true,
        },
        {
          label: "Routing Number",
          placeholder: "e.g. 123456789",
          value: context.paymentDetails?.routingNumber,
          failedValidation: context.validationFailed,
          description: "Routing numbers must be 9 digits.",
          onChange: (e) => context.onPaymentDetailsChange({...context.paymentDetails, routingNumber: e.target.value}),
          isEditable: true,
          required: true,
        },
        {
          label: "Account Number",
          placeholder: "e.g. 1234567890",
          value: context.paymentDetails?.accountNumber,
          failedValidation: context.validationFailed,
          description: "Account numbers must be 17 digits or less.",
          onChange: (e) => context.onPaymentDetailsChange({...context.paymentDetails, accountNumber: e.target.value}),
          isEditable: true,
          required: true,
        },
        {
          label: "Confirm Account Number",
          placeholder: "e.g. 1234567890",
          value: context.paymentDetails?.confirmAccountNumber,
          failedValidation: context.validationFailed,
          onChange: (e) => context.onPaymentDetailsChange({...context.paymentDetails, confirmAccountNumber: e.target.value}),
          isEditable: true,
          required: true,
        },
      ],
    };
    if (context.details?.organization?.isEscrowAccount && context.isUserLoggedIn()) {
      accountDetails.fields.push({
        label: "Add Account To My Profile",
        value: (
          <GSToggle
            value={context.paymentDetails?.savePayment}
            label="Use this account for future event registrations."
            trueDescription="Yes"
            falseDescription="No"
            onClick={() => context.onPaymentDetailsChange({...context.paymentDetails, savePayment: !context.paymentDetails?.savePayment})}
          />
        ),
        customView: true,
        isEditable: true,
      });
    }

    const captcha = {
      type: 'horizontal',
      fields: [
        {
          fit: 'stretch',
          failedValidation: context.validationFailed,
          validation: {
            isValid: () => Boolean(context.captchaToken),
            invalidLabel: 'Must complete captcha.',
          },
          value: (context.getCaptcha),
          customView: true,
        },
      ],
    };

    let sections = [{ ...paymentOptionSection }, { ...orderSummary }, { ...totalBanner }];
    if (context.selectedPaymentOption) {
      if (context.selectedPaymentOption.saved) {
        sections.push({...captcha});
      } else {
        if (context.selectedPaymentOption.paymentType === "credit_card") {
          sections.push({...cardDetails}, {...billingAddress}, {...captcha});
        } else {
          sections.push({...accountDetails}, {...captcha});
        }
      }
    }

    return sections;
  }
};

export const getPaymentConfirmationFormSections = (context) => {
  const getInvoiceTotal = () => {
    const total = context.details?.registrationOrder?.purchaseCost || 0.00;
    let groups = [
      {
        sections: [
          {
            sectionItems: [
              {
                type: "primary no-wrap",
                value: "Invoice Total",
              },
            ],
          },
        ],
      },
      {
        sections: [
          {
            sectionItems: [
              {
                type: "primary no-wrap total",
                value: moneyWithCommas(total),
              },
            ],
          },
        ],
      },
    ];

    return (
      <div className={"total-container"}>
        <GSInfoGroup dataGroups={groups} listType={"total-info"} />
      </div>
    );
  };

  const getTotalSummary = () => {
    return (
      <div className="container">
        {getInvoiceTotal()}
        <CustomerInvoiceSummary {...context} />
      </div>
    );
  }

  const invoiceSummary = {
    type: "vertical large-gap",
    fields: [
      {
        value: (<div className="payment-text">This invoice has been paid! A receipt has been sent to {context?.details?.invoice?.contactEmail}. To help you identify this transaction, the charge will appear as: <b>GolfStatus Golf Event Lincoln USA</b>.</div>),
        customView: true,
      },
      {
        value: (getTotalSummary()),
        customView: true,
      },
    ],
  };

  return [invoiceSummary];
};
