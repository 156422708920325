import { GSItemList, defaultLayouts, defaultPadding, defaultBorders, useMediaQuery } from "golfstatus_react_components";
import React from "react";
import PackageGroupLineItem from "../events/event/packages/package-group-line-item";
import { useTheme } from "../../hooks/themeHooks";
import { faFlag, faTicket, faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTournamentPackages } from "../../hooks/tournamentHooks";

const TournamentPackageGroupsWidget = () => {
  

  const {teamPackages, playerPackages, sponsorPackages, addonPackages} = useTournamentPackages();

  const [,theme,getStyle] = useTheme()

  const { isTablet, isPhone } = useMediaQuery();

  const navigate = useNavigate()

  const items = [];

  if (sponsorPackages?.length > 0) {
    items.push({
      title: "Sponsorships",
      icon: faFlag,
      route: "packages?view=sponsor",
    });
  }
  if (teamPackages?.length > 0) {
    items.push({
      title: "Team Registrations",
      icon: faUsers,
      route: "packages?view=team",
    });
  }

  if (playerPackages?.length > 0) {
    items.push({
      title: "Player Registrations",
      icon: faUser,
      route: "packages?view=player",
    });
  }

  if (addonPackages?.length > 0) {
    items.push({
      title: "Add-Ons & Extras",
      icon: faTicket,
      route: "packages?view=extras",
    });
  }

  return (
    <GSItemList
      type="inline-grid medium-large-gap selectable"
      style={{ ...defaultLayouts.fullWidth }}
      gridColumns={isTablet() ? Math.min(2, items.length) : isPhone() ? 1 : items?.length ?? 4}
      items={items}
      itemSelected={(item) => {navigate(item.route)} }
      listItem={(item) => (
        <PackageGroupLineItem
        className="hover-outline"
          style={{
            ...defaultLayouts.fill,
            ...defaultPadding.xLargePad,
            ...defaultBorders.mediumLargeBorderRadius,
            ...getStyle(theme.surface),
          }}
          navigate={() => {
            navigate(item.route);
          }}
          sponsorshipType={item.title}
          icon={item.icon}
        />
      )}
    />
  );
}

export default TournamentPackageGroupsWidget