import { GSInfoGroup, defaultTypography } from "golfstatus_react_components";
import React from "react";
import { getDateTimeOn } from "../../../../helpers/Dates";

const TournamentRoundSelectItem = (props) => {
  const { startAt, numberOfHoles, facility, roundName, number, style, tournamentFormats, startType, action, className, headerType} = props ?? {}

  const {name, city, state } = facility ?? {}

  const format = tournamentFormats?.[0]?.name

  const formattedStartType = startType?.replaceAll?.("_", " ")

  const roundInfoSection = {
    sectionItems: [
      {
        type: headerType ? headerType: "headline-2",
        value: `Round ${roundName ?? number}`,
      },{
        type: "secondary",
        style: defaultTypography.capitalize,
        value: `${getDateTimeOn(startAt)}`,
      }
    ]
  }
  const roundFormatSection = {
    sectionItems: [
      {
        type: "secondary",
        value: format,
      },{
        type: "secondary",
        value: `${numberOfHoles} Holes`,
      },
      {
        type: "secondary capitalize",
        value: formattedStartType,
      }
    ]
  }

  const roundFacilitySection = {
    sectionItems: [
      {
        type: "secondary",
        value: name,
      },{
        type: "secondary",
        value: `${city}, ${state}`,
      }
    ]
  }

  const changeRoundSection = {
    sectionItems: [
      {
        type: "mobile-stretch",
        value: action,
      }
    ]
  }

  const roundColumn = {
    fit:"stretch",
    sections: [
      roundInfoSection, roundFormatSection, roundFacilitySection
    ]
  }


  const actionColumn = {
    type: "mobile-stretch",
    sections: [
      changeRoundSection
    ]
  }

  const columns = [roundColumn]

  if(action){
    columns.push(actionColumn)
  }

  return <tournament-round-select-item class={className} style={style}><GSInfoGroup listType="mobile-vertical" dataGroups={columns}/></tournament-round-select-item>
}

export default TournamentRoundSelectItem