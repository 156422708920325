import { GSInfoGroup, GSButton, defaultLayouts, defaultGapping, defaultTypography, GSHTMLViewer } from "golfstatus_react_components";
import React from "react";
import "./package-line-item.scss";
import { moneyWithCommas } from "../../../../helpers/RegexHelper";
/**
 * package info group to display information about a tournament package.
 * @typedef Properties
 * @type {object}
 * @property {string} sponsorshipType the type of sponsorship this filters on
 * @property {number} packagesAvailable the number of packages available
 * @property {string} startingAt the starting price for the packages
 * @property {string} cartActionLabel Label for the button that interacts wi
 * @property {object} cartActionIcon icon for cart button
 * @property {function} cartAction the navigation function
 * @property {string} style the style for this component
 *
 * @param {Properties} props name, cost, remainingPackages, description, cartActionIcon, cartActionLabel, cartAction, style
 */

const PackageLineItem = (props) => {
  const {
    name,
    cost,
    description,
    action,
    className,
    headerType,
    accessGroup,
    style,
  } = props;

  const header = headerType ?? ""

  const packageTypeInfoSection = {
    gap: "medium-gap",
    sectionItems: [
      {
        sectionItemStyle: header !== "" ? {} : {...defaultTypography.mediumBoldBody, opacity: 1 },
        type: `${header} full-width`,
        value: name,
      },
      {
        type: " full-width",
        sectionItemStyle: {...defaultTypography.body},
        value: moneyWithCommas(cost),
      },
    ],
  };

  const navigateSection = {
    type: "mobile-stretch",
    sectionItems: [
      {
        type: "primary full-width",
        value: <GSButton {...action} />,
      },
    ],
  };

  const exclusiveSection = {
    sectionItems: [
      {
        type: "primary full-width",
        value: <GSButton type="secondary black pill" title="Exclusive" />,
      },
    ],
  };

  const packageInfoColumn = {
    fit: "stretch",
    sections: [packageTypeInfoSection],
  };

  if(accessGroup?.id && description){
    packageInfoColumn.sections?.unshift(exclusiveSection)
  }

  const packageAddToCartColumn = {
    type: "mobile-stretch",
    sections: [navigateSection],
  };

  const getGroup = () => {
    let group = [packageInfoColumn];
    if (action) {
      group.push(packageAddToCartColumn);
    }
    return group;
  };

  return (
    <package-line-item class={className} style={{...defaultLayouts.vStack, ...defaultGapping.xLargeGap, ...style }}>
      <GSInfoGroup
        listType="mobile-vertical"
        dataGroups={getGroup()}
      ></GSInfoGroup>
      {description ? (
        <div style={{ opacity: 0.7, whiteSpace: "pre-line" }}>
          <GSHTMLViewer html={description}/>
        </div>
      ) : null}
    </package-line-item>
  );
};

export default PackageLineItem;
