import { faArrowRight, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  GSPageSection,
  addAlphaChannel,
  defaultLayouts,
  defaultPadding,
  useMediaQuery,
} from "golfstatus_react_components";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import "./event-details.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentPaymentSettings,
  getTournamentSponsors,
  getTournamentTeams,
  selectTournamentPaymentSettings,
  selectTournamentSponsors,
} from "../../../reducers/tournamentSlice";
import { useSectionStyles, useTheme } from "../../../hooks/themeHooks";
import TournamentDetailsWidget from "../../widgets/tournament-details-widget";
import TournamentPackageGroupsWidget from "../../widgets/tournament-package-groups-widget";
import TournamentHTMLWidget, { stripHTML } from "../../widgets/tournament-html-widget";
import TournamentDonationWidget from "../../widgets/tournament-donation-widget";
import DownloadAppWidget from "../../widgets/download-app-widget";
import AdditionalPagesWidget from "../../widgets/additional-pages-widget";
import TournamentVideoWidget from "../../widgets/tournament-video-widget";
import TournamentPhotoWidget from "../../widgets/tournament-photo-widget";
import { useTechSponsors } from "../../widgets/tech-sponsor-widget";
import { useNavigationButtons } from "../../../hooks/pageHooks";
import { usePermissions } from "../../../hooks/permissionHooks";
import TournamentSponsorsWidget from "../../widgets/tournament-sponsors-widget";
import { useLoading, useTournament, useTournamentPackages } from "../../../hooks/tournamentHooks";

const EventDetails = () => {
  const tournament = useTournament();
  const tournamentPaymentSettings = useSelector(
    selectTournamentPaymentSettings
  );
  const {accessiblePackages} = useTournamentPackages(true);
  const sponsorships = useSelector(selectTournamentSponsors);
  const [isLoading] = useLoading(["getPublicTournamentDetails"])

  const { style, defaultSectionStyle, headerSectionStyle } = useSectionStyles();

  const [, theme, getStyle] = useTheme();
  const transparentBackground = addAlphaChannel(
    getStyle(theme.surface).backgroundColor,
    0.8
  );

  const navigate = useNavigate();

  const tournamentID = tournament?.id;

  const dispatch = useDispatch();

  let images = [tournament?.logo];

  const { isPhone, isTablet } = useMediaQuery();
  const headerStyle = isPhone() || isTablet() ? { display: "none" } : {};

  if ((tournament?.sponsorLogo ?? "") !== "") {
    images.push(tournament?.sponsorLogo);
  }

  const getPageActions = useNavigationButtons("");

  const permissions = usePermissions();

  useEffect(() => {
    if (tournamentID) {
      dispatch(getTournamentPaymentSettings(tournamentID));
      dispatch(getTournamentSponsors(tournamentID));
      dispatch(getTournamentTeams(tournamentID));
    }
  }, [dispatch, tournamentID]);

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const isSidePanelOpen = () => {
    //remove this when you add your match route.
    return false;
    //
    //return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const [techSponsors] = useTechSponsors();

  const photosVideo = () => {
    const media = [];
    if (tournament?.tournamentPromotion?.promotionalPhoto) {
      media.push(<TournamentPhotoWidget />);
    }
    if (tournament?.tournamentPromotion?.promotionalVideo) {
      media.push(<TournamentVideoWidget />);
    }

    return media;
  };

  const isPresent = (html) => {
    return stripHTML(html) !== ""
  }

  const hasAdditionalPages = () => {
    return !permissions.hideCourseDetails || !permissions.hideLeaderboards || !permissions.hideRegistrants
  }

  const getItemList = () => {
    return (
      <event-details>
        {tournament?.tournamentPromotion?.heroBannerPhoto ? (
          <GSPageSection
            style={{
              ...style,
              ...defaultLayouts.hero.image(
                tournament?.tournamentPromotion?.heroBannerPhoto,
                `33vh`
              ),
            }}
            bodyStyle={{
              ...headerSectionStyle.bodyStyle,
              background: transparentBackground,
              backdropFilter: "blur(10px)",
            }}
            body={[]}
          />
        ) : null}

        <GSPageSection
          {...headerSectionStyle}
          body={[<TournamentDetailsWidget />]}
        />

        {isPresent(tournament?.description) && (
          <GSPageSection
            style={style}
            bodyStyle={{ ...defaultPadding.noPad }}
            title="Event Description"
            body={[<TournamentHTMLWidget html={tournament?.description} />]}
          />
        )}

        {tournament?.tournamentPromotion?.additionalTournamentDetails && (
          <GSPageSection
            style={style}
            bodyStyle={{ ...defaultPadding.noPad }}
            title="Additional Event Description"
            body={[
              <TournamentHTMLWidget
                html={
                  tournament?.tournamentPromotion?.additionalTournamentDetails
                }
              />,
            ]}
          />
        )}

        {tournament?.promotionDetails && (
          <GSPageSection
            style={style}
            bodyStyle={{ ...defaultPadding.noPad }}
            title="Registration Details"
            body={[
              <TournamentHTMLWidget html={tournament?.promotionDetails} />,
            ]}
          />
        )}

        {accessiblePackages?.length > 0 && permissions.packagesEnabled() ? (
          <GSPageSection
            {...defaultSectionStyle}
            title="Packages"
            sectionActions={[
              {
                title: "View Packages",
                rightIcon: faArrowRight,
                type: "black mobile-icon",
                onClick: () => {
                  navigate("packages");
                },
              },
            ]}
            body={[<TournamentPackageGroupsWidget />]}
          />
        ) : null}

        {sponsorships?.[0] && !permissions.hideSponsorships ? (
          <GSPageSection
            {...defaultSectionStyle}
            title="Sponsors"
            sectionActions={[
              {
                title: "View Sponsors",
                rightIcon: faArrowRight,
                type: "black mobile-icon",
                onClick: () => {
                  navigate("sponsorships");
                },
              },
            ]}
            body={[...techSponsors(), <TournamentSponsorsWidget />]}
          />
        ) : null}

        {(tournament?.tournamentPromotion?.promotionalVideo ||
          tournament?.tournamentPromotion?.promotionalPhoto) && (
          <GSPageSection
            {...defaultSectionStyle}
            title="Photos & Video"
            body={photosVideo()}
          />
        )}

        {permissions.donationsActive?.() ? (
          <GSPageSection
            {...defaultSectionStyle}
            title="Make a Donation"
            sectionActions={[
              {
                title: "Donate Now",
                rightIcon: faArrowRight,
                type: "black mobile-icon",
                onClick: () => {
                  navigate("donate");
                },
              },
            ]}
            description={tournamentPaymentSettings?.donationMessage}
            body={[<TournamentDonationWidget />]}
          />
        ) : null}

        {hasAdditionalPages() ? 
        <GSPageSection
        {...defaultSectionStyle}
        title="Additional Pages"
        body={[<AdditionalPagesWidget />]}
      />
        : null}

        

        <GSPageSection
          {...defaultSectionStyle}
          title="Live Scoring Powered by GolfStatus"
          body={[<DownloadAppWidget />]}
        />
      </event-details>
    );
  };

  return (
    <event-details>
      <GSListPage
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        alignment="left"
        headerStyle={headerStyle}
        loading={isLoading}
      ></GSListPage>
    </event-details>
  );
};

export default EventDetails;
