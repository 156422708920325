import React from "react";
import SummaryLineItem from "../events/event/cart/summary-line-item";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePricing,
  selectCompletedOrder,
  selectPublicTournament,
  selectRegistrationOrder,
} from "../../reducers/tournamentSlice";
import { moneyWithCommas } from "../../helpers/RegexHelper";
import { GSToggle } from "golfstatus_react_components";

const CheckoutRoundUpWidget = (props) => {
  const { complete } = props;
  const completedOrder = useSelector(selectCompletedOrder);
  const inProgressOrder = useSelector(selectRegistrationOrder);
  const registrationOrder = complete ? completedOrder : inProgressOrder;

  const tournament = useSelector(selectPublicTournament);

  const dispatch = useDispatch();

  const toggleRoundUp = () => {
    const useRoundUp = !registrationOrder.roundUpToggle;
    const noRoundUP = registrationOrder?.donationOrderItems?.filter?.(
      (donation) => !donation.roundUp
    );
    const withRoundUp = [...noRoundUP, { amount: 0, round_up: true }];

    const update = {
      ...registrationOrder,
      roundUpToggle: useRoundUp,
      donationOrderItems: useRoundUp ? withRoundUp : noRoundUP,
    };

    dispatch(calculatePricing(update));
  };

  const roundUp = registrationOrder?.donationOrderItems?.find?.(
    (donation) => donation?.["roundUp"]
  )?.amount;

  return (
    <SummaryLineItem
      label="Round Up"
      description={`${tournament.name} will receive an extra donation, when you round up to the next whole dollar.`}
      value={roundUp ? moneyWithCommas(roundUp) : undefined}
      content={
        !complete ? (
          <GSToggle
            value={registrationOrder?.roundUpToggle}
            trueDescription="Yes"
            falseDescription="No"
            onClick={() => {
              toggleRoundUp();
            }}
          />
        ) : null
      }
    />
  );
};

export default CheckoutRoundUpWidget;
