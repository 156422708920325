import React, { useEffect } from "react";
import {
  GSForm,
  GSSidePanelPage,
  useFormDataValidation,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";

//import scss
import "./registration-waitlist-form.scss";
import {
  useNotificationAction,
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  joinWaitList,
  selectNotifications,
  selectWaitlistDetails,
  setWaitlistDetails,
  setWaitlistType,
} from "../../../../reducers/tournamentSlice";
import { getWaitlistForm } from "../../../../forms/WaitlistForm";
import { useTournament } from "../../../../hooks/tournamentHooks";
import { PLAYER_PACKAGES } from "./package-list";

//Name the component
const RegistrationWaitlistForm = (props) => {
  const waitlistDetails = useSelector(selectWaitlistDetails);
  const tournament = useTournament();

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const waitlistType = searchParams.get("type");

  useEffect(() => {
    if (waitlistType) {
      const type = waitlistType === PLAYER_PACKAGES ? "individual" : "team";
      dispatch(setWaitlistType(type))
    }
  }, [waitlistType, dispatch]);

  const [context, isValid, setIsValid] = useFormDataValidation({
    initialValid: false,
    data: waitlistDetails,
    setData: (update) => {
      dispatch(setWaitlistDetails(update));
    },
  });

  context.tournament = tournament

  const navigate = useNavigate();
  const notifications = useSelector(selectNotifications).filter?.(
    (n) => n.id === "joinWaitList"
  );

  useNotificationAction(
    "fulfilled",
    notifications,
    () => {
      navigate("confirmation", { replace: true });
    },
    "joinWaitList"
  );

  const save = () => {
    const update = { ...waitlistDetails };
    update.tournament = tournament;
    setIsValid(false);
    dispatch(joinWaitList(update));
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Join Waitlist",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Waitlist Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle="Join Waitlist"
        formSections={getWaitlistForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <registration-waitlist-form>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </registration-waitlist-form>
  );
};

//export the new namet
export default RegistrationWaitlistForm;
