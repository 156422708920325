import {
  defaultPadding,
  defaultTheme,
  invertSyle,
  mergedTheme,
  useMediaQuery,
} from "golfstatus_react_components";
import { useSelector } from "react-redux";
import { selectTheme } from "../reducers/appSlice";

  /**
   * use the theming engine 
   * 
   * @typedef style 
   * @type {object}
   * @property {object} light
   * @property {object} dark 
   * 
   * @typedef theme
   * @type {object}
   * @property {style} primary
   * @property {style} secondary
   * @property {style} primaryContainer
   * @property {style} secondaryContainer
   * @property {style} background
   * @property {style} surface
   * @property {style} surfaceDim
   * @property {style} surfaceLight
   * @property {style} surfaceContainer
   * @property {style} surfaceContainerLow
   * @property {style} surfaceContainerLowest
   * @property {style} surfaceContainerHigh
   * @property {style} surfaceContainerHighest
   * @property {style} surfaceVariant
   * @property {style} outline
   * @property {style} outlineVariant
   * @property {style} scrim
   * @property {style} error
   * @property {style} errorContainer
   * @property {style} tertiary
   * @property {style} tertiaryContainer
   *
   * @return {[theme, theme, getThemeStyle: object]}
   */

export const useTheme = () => {
  const currentMode = useSelector(selectTheme);
  const customTheme = mergedTheme({});
  const getThemeStyle = (style, invert) => {
    if (invert) {
      return invertSyle(style?.[currentMode]);
    }
    return style?.[currentMode];
  };

  return [defaultTheme, customTheme, getThemeStyle];
};


  /**
   * use the theming engine 
   * 
   * @typedef bodyStyle 
   * @type {object}
   * @property {string} background
   * @property {string} color 
   * 
   * @typedef style 
   * @type {object}
   * @property {object} borderColor
   * 
   * @typedef defaultSectionStyle
   * @type {object}
   * @property {bodyStyle} bodyStyle
   * @property {style} style
   * 
   * @typedef headerSectionStyle
   * @type {object}
   * @property {bodyStyle} bodyStyle
   * @property {style} style
   *
   * @return {[bodyStyle, style, defaultSectionStyle, headerSectionStyle]}
   */
export const useSectionStyles = () => {
  const [,theme, getStyle] = useTheme()

  const bodyStyle = { ...getStyle(theme.surfaceVariant) };
  const style = { ...getStyle(theme.outlineVariant) };
  const defaultSectionStyle = { style, bodyStyle };
  const headerSectionStyle = {
    style,
    bodyStyle: {...bodyStyle, ...defaultPadding.smallLayoutPad.apply("vertical")}
  }

  return{bodyStyle, style, defaultSectionStyle, headerSectionStyle}
}


export const useHeaderStyle = () => {
  const { isPhone, isTablet } = useMediaQuery();
  const headerStyle = isPhone() || isTablet() ? { display: "none" } : {};
  return {headerStyle}
}