import { defaultLayouts, defaultPadding } from "golfstatus_react_components";
import React from "react";
import { useTheme } from "../../../../hooks/themeHooks";

const PageFooter = (props) => {

  const {showFooter} = props

  const [, theme, getStyle] = useTheme()

  return (
    <page-footer
      style={{
        position: "sticky",
        bottom: "0",
        ...defaultLayouts.fullWidth,
        ...defaultLayouts.vStack.align("center", "flex-start"),
        ...defaultPadding.xLargePad,
        ...getStyle(theme.surface),
        display: showFooter ? "inline-flex" : "none",
        borderTop: "1px solid",
      }}
    >
      {props.children}
    </page-footer>
  );
};

export default PageFooter