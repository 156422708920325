import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { getDateTime } from "../../../../helpers/Dates";

/**
 * Donation info group.
 * 
 * @typedef Properties
 * @type {object}
 * @property {string} style the style for this component
 *
 * @param {Properties} props
 */

const CurrentLeaderboardItem = (props) => {

  const {name, refreshedAt, navigate, style} = props
  
  const titleSection = {
    gap: "medium-large-gap",
    sectionItems: [
      {
        type: "headline-2 full-width",
        value: name,
      },
      {
        type: "secondary  full-width",
        value: `Leaderboard updated ${getDateTime(refreshedAt)}`,
      },
    ]
  }

  const titleColumn ={
    fit: "stretch",
    sections: [
      titleSection,
    ],
  }

  const actionSection = {
    sectionItems: [
      {
        type: "mobile-stretch",
        value: <GSButton style={{flexGrow: "1"}} title="Change" rightIcon={faArrowRight} type="black" onClick={() => {navigate?.()}}/>,
      },
    ]
  }

  const actionColumn ={
    type: "mobile-stretch",
    sections: [
      actionSection,
    ],
  }

  const getGroup = () => {
    let group = [titleColumn, actionColumn]
    return group
  }

  return <current-leaderboard-item style={style}>
    <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup()}></GSInfoGroup>
  </current-leaderboard-item>
}


export default CurrentLeaderboardItem