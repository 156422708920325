import { Route } from "react-router-dom";
import CustomerInvoicePay from "../components/customer-invoice/customer-invoice-pay";
import CustomerInvoicePaymentConfirmation from "../components/customer-invoice/customer-invoice-payment-confirmation";
import CustomerInvoiceDetails from "../components/customer-invoice/customer-invoice-details";
import { getRouting } from "../helpers/Converters";
import { ORDER_ID } from "./event";
import CustomerInvoiceRedirect from "../components/customer-invoice/customer-invoice-redirect";

export const invoiceBaseRoute = "/invoice"

export const getInvoiceRoutes = () => {
  return (
    <>
    <Route path={getRouting("", [ORDER_ID])} element={<CustomerInvoiceRedirect/>}/>
    <Route path={getRouting(invoiceBaseRoute, [ORDER_ID])} element={<CustomerInvoiceDetails />}>
      <Route path={"pay"} element={<CustomerInvoicePay />} />
      <Route
        path={"receipt"}
        element={<CustomerInvoicePaymentConfirmation />}
      />
    </Route>
    </>
  );
};
