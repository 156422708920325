import {
  defaultPadding,
  defaultLayouts,
  defaultBorders,
  defaultGapping,
  golfstatusTypography,
  defaultTypography,
} from "golfstatus_react_components";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "../../../../hooks/themeHooks";
import "./cart-payment-method.scss";

/**
 *
 * @typedef Properties
 * @type {object}
 * @property {string} paymentType name of the type of payment
 * @property {object} icon name of the type of payment
 * @property {boolean} isSelected is the paymentType selected
 * @property {function} onClick function to run when payment is clicked
 * @property {object} style style of the component

 * @param {Properties} props paymentType,
    icon
 */

//Name the component

const CartPaymentMethod = (props) => {
  const { id, paymentType, icon, isSelected, onClick, style, className } = props;

  const [, theme, getStyle] = useTheme();

  let componentStyle = () => {
    const style = {
      ...defaultPadding.xLargePad,
      ...getStyle(theme.surface),
      ...defaultLayouts.fill,
      ...defaultBorders.mediumLargeBorderRadius,
      ...defaultLayouts.vStack.align("center", "center"),
      ...defaultGapping.mediumLargeGap,
      borderWidth: "4px",
      borderStyle: "solid",
      cursor: "pointer",
    };
    if (!isSelected) {
      return { ...style, ...getStyle(theme.outlineVariant) };
    }
    return style;
  };

  return (
    <cart-payment-method
     class={className}
      style={{ ...componentStyle(), ...style }}
      onClick={() => {
        onClick?.({ id, paymentType, icon });
      }}
    >
      <FontAwesomeIcon style={{fontSize: golfstatusTypography.mediumFont, lineHeight: golfstatusTypography.squareLineHeight}} icon={icon} />
      <div
        style={{
          ...defaultLayouts.fullWidth,
          ...defaultLayouts.vStack.align("center", "center"),
          textAlign: "center",
          ...defaultTypography.body
        }}
      >
        {paymentType}
      </div>
    </cart-payment-method>
  );
};

export default CartPaymentMethod;
