import React from 'react';
import './registration-order-summary-line-item.scss';
import { defaultTypography } from 'golfstatus_react_components';
const RegistrationOrderSummaryLineItem = ({item}) => {
  return (
    <registration-order-summary-line-item>
      <div style={{...defaultTypography.bodyRegular.withOpacity()}} className="label">{item.label}</div>
      <div style={{...defaultTypography.bodyRegular.withOpacity()}} className="value">{item.value}</div>
    </registration-order-summary-line-item>
  );
}

export default RegistrationOrderSummaryLineItem;
