import React, { useState } from "react";
import {
  GSForm,
  GSSidePanelPage,
  useFormDataValidation,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./guest-checkout-page.scss";
import { useNotificationBanner } from "../../../../hooks/notificationHooks";
import { getCustomerForm } from "../../../../forms/PackageForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  selectRegistrationCustomer,
  updateCustomer,
} from "../../../../reducers/tournamentSlice";
import { getCurrentUser } from "../../../../app/gs-session";

//Name the component
const GuestCheckoutPage = (props) => {
  const currentUser = getCurrentUser();

  const registrationCustomer = useSelector(selectRegistrationCustomer);
  const dispatch = useDispatch();

  const [tempCustomer, setTempCustomer] = useState({ ...registrationCustomer });

  const [context, isValid, setIsValid] = useFormDataValidation({
    initialValid: false,
    setData: (update) => {
      setTempCustomer(update)
    },
    data: tempCustomer,
  });

  const navigate = useNavigate();
  const notifications = [];

  const save = () => {
    setIsValid(false);
    dispatch(updateCustomer(tempCustomer))
    leftNavigation();
  };

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Save & Continue",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "cancel", action: leftNavigation, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Contact Details`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContactActions = () => {
    if (currentUser?.uid) {
      return [];
    }
    return [
      {
        title: "Sign In",
        type: "no-wrap grey",
        onClick: () => {
          window.location.href = process.env.REACT_APP_GOLFSTATUS_LOGIN_URL;
        },
      },
    ];
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle="Contact Details"
        formActions={getContactActions()}
        formSections={getCustomerForm(context)}
      ></GSForm>
    );
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  const [banner] = useNotificationBanner({ notifications, timeoutAction });

  return (
    //name the component tag
    <guest-checkout-page>
      <GSSidePanelPage
        header={getNavigation()}
        banner={banner}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </guest-checkout-page>
  );
};

//export the new namet
export default GuestCheckoutPage;
