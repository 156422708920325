import React from "react";
import { useSelector } from "react-redux";
import {
  selectCompletedOrder,
  selectRegistrationCustomer,
} from "../../reducers/tournamentSlice";
import {
  GSInfoGroup,
} from "golfstatus_react_components";
import { usePaymentOptions } from "../../hooks/tournamentHooks";

const ConfirmationPaymentInfoWidget = () => {
  const savedPaymentOptions = usePaymentOptions().savedPaymentOptions;
  const registrationOrder = useSelector(selectCompletedOrder);
  const customer = useSelector(selectRegistrationCustomer);

  const savedPaymentOption = savedPaymentOptions?.find?.(
    (po) => po?.id === registrationOrder?.paymentOptionKey
  );

  const getPaymentTitle = () => {
    if (registrationOrder?.paymentType === "credit_card") {
      if (savedPaymentOption) {
        return `${savedPaymentOption?.title} ${savedPaymentOption?.subTitle}  `;
      } else {
        return "Credit/Debit Card";
      }
    }
    if (registrationOrder?.paymentType === "bank_account") {
      if (savedPaymentOption) {
        return `${savedPaymentOption?.title} ${savedPaymentOption?.subTitle}  `;
      } else {
        return "Bank Account";
      }
    }
    if (registrationOrder?.paymentType === "customer_invoice") {
      return "Invoice";
    }
    else {
      return "Pay at Course"
    }
  };

  const paymentSection = {
    sectionItems: [
      {
        type: "body",
        value: getPaymentTitle(),
      },
    ],
  };

  if (savedPaymentOption?.accountHolderName) {
    paymentSection.sectionItems.push({
      type: "secondary",
      value: savedPaymentOption?.accountHolderName,
    });
  }

  if (registrationOrder?.cardFirstName) {
    paymentSection.sectionItems.push({
      type: "secondary",
      value: `${registrationOrder?.cardFirstName} ${registrationOrder?.cardLastName}`,
    });
  }

  if (registrationOrder?.last4) {
    paymentSection.sectionItems.push({
      type: "secondary",
      value: `${registrationOrder?.last4}`,
    });
  }

  if (registrationOrder?.paymentType === "customer_invoice") {
    paymentSection.sectionItems.push(
      {
        type: "secondary",
        value: `${customer?.firstName} ${customer?.lastName}`,
      },
      {
        type: "secondary",
        value: customer?.email,
      },
      {
        type: "secondary",
        value: customer?.phone
      }
    );
  }

  const paymentColumn = {
    sections: [paymentSection],
  };

  const columns = [paymentColumn];

  return <GSInfoGroup dataGroups={columns} />;
};

export default ConfirmationPaymentInfoWidget;
