import { faExternalLinkSquare } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSImage, GSInfoGroup, defaultLayouts, defaultGapping } from "golfstatus_react_components";
import React from "react";
import { openExternalLink } from "../../../../helpers/Utilities";

/**
 * Donation info group.
 * 
 * @typedef Properties
 * @type {object}
 * @property {string} style the style for this component
 *
 * @param {Properties} props
 */

const SponsorListItem = (props) => {

  const {logo, name, website, style, contentStyle} = props
  
  const sponsorInfoSection = {
    sectionItems: [
      {
        type: "primary  full-width",
        value: name,
      },
    ]
  }

  const sponsorInfoColumn ={
    fit: "stretch",
    sections: [
      sponsorInfoSection
    ],
  }

  const sponsorLinkSection = {
    sectionItems: [
      {
        type: "no-wrap full-width",
        value: <GSButton type="grey" buttonIcon={faExternalLinkSquare} onClick={()=> {openExternalLink(website)}} />,
      },
    ]
  }

  const sponsorLinkColumn ={
    sections: [
      sponsorLinkSection
    ],
  }

  const getGroup = () => {
    let group = [sponsorInfoColumn, sponsorLinkColumn]
    return group
  }

  return <sponsor-list-item style={style}>
    <div style={{...defaultLayouts.vStack, ...defaultGapping.largeGap, ...defaultLayouts.fullWidth, ...contentStyle}}>
    <GSImage  onClick={() => {if(website) openExternalLink(website)}} src={logo} />
    <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
    </div>
    
  </sponsor-list-item>
}


export default SponsorListItem