import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSFileSelect } from "golfstatus_react_components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useImageSelector } from "../../hooks/formHooks";

const SponsorLogoSelect = (props) => {
  const { sponsorID, context } = props;

  const [, , setLogo, removeLogo, getLogo, ,] = useImageSelector(
    context,
    "logo"
  );

  const navigate = useNavigate();

  return (
    <GSFileSelect
      id="logo"
      description="Tap or drag a file to upload. The image ratio is 2:1.  Accepted file types are .JPG, .PNG, .GIF"
      setSelectedFiles={(files) => {
        setLogo(files);
      }}
      removeSourceItem={() => removeLogo("logo")}
      sourceList={getLogo()}
      accept=".jpg, .jpeg, .png"
      editImage={() => {
        navigate(`${sponsorID}/image?property=logo`);
      }}
      minDimension={25} // images come back resized, and sometimes off by <= 1px
      ratio={2}
      warningClicked={() => {
        navigate(`${sponsorID}/image?property=logo`);
      }}
      title={<GSButton buttonIcon={faArrowCircleUp} title="upload"></GSButton>}
      failedValidation={context.validationFailed}
    ></GSFileSelect>
  );
};

export default SponsorLogoSelect;
