import React from "react";
import RegistrationOrderLineItem from "../events/event/packages/registration-order-line-item";
import { useTheme } from "../../hooks/themeHooks";
import {
  defaultBorders,
  defaultLayouts,
  defaultPadding,
} from "golfstatus_react_components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePricing,
  selectRegistrationOrder,
  updateOrder,
} from "../../reducers/tournamentSlice";
import { RegistrationOrder } from "../../models/Registration";
import { usePermissions } from "../../hooks/permissionHooks";
import { BANK_ACCOUNT, CREDIT_CARD, CUSTOMER_INVOICE, getActivePaymentMethods, PAY_AT_COURSE } from "../../forms/CartSections";

const CartDonationWidget = () => {
  const [, theme, getStyle] = useTheme();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const registrationOrder = useSelector(selectRegistrationOrder);

  const permissions = usePermissions();

  const removeDonation = () => {
    const ru =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => d?.roundUp)
        ?.map?.((d) => {
          return { round_up: true };
        }) ?? [];

    const donations = [...ru];

    const update = {
      ...registrationOrder,
      donationOrderItems: donations,
    };

    if (
      update?.registrationOrderItems?.length === 0 &&
      update?.donationOrderItems?.length === 0
    ) {
      dispatch(updateOrder({ ...RegistrationOrder, id: update.id }));
    } else {
      const activePaymentMethods = getActivePaymentMethods(permissions)?.map?.(
        (apm) => apm.id
      );
      if (activePaymentMethods?.includes?.(1)) {
        update.paymentType = CREDIT_CARD;
      } else if (activePaymentMethods?.includes?.(2)) {
        update.paymentType = BANK_ACCOUNT;
      } else if (activePaymentMethods?.includes?.(4)) {
        update.paymentType = CUSTOMER_INVOICE;
      } else if (activePaymentMethods?.includes?.(3)) {
        update.paymentType = PAY_AT_COURSE;
      }
      dispatch(calculatePricing(update));
    }
  };

  const donations = registrationOrder?.donationOrderItems?.filter?.(
    (d) => !d.roundUp
  );

  const donation = donations?.[0];

  const item = {
    quantity: 1,
    tournamentPackage: {
      name: "Donation",
      cost: donation.amount,
    },
  };

  return (
    <RegistrationOrderLineItem
      donation={donation}
      style={{
        ...defaultLayouts.fullWidth,
        ...getStyle(theme.surfaceVariant),
        ...defaultPadding.xLargePad,
        ...defaultBorders.largeBorderRadius,
      }}
      removePackage={(orderItem) => {
        removeDonation();
      }}
      formSelected={(form) => {
        navigate(`donation/message`);
      }}
      {...item}
    />
  );
};

export default CartDonationWidget;
