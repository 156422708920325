import {
  faBank,
  faCreditCard,
  faFileContract,
  faGolfBallTee,
} from "@fortawesome/free-solid-svg-icons";

/////SUMMARY SECTION ---------------------------------------------------------------------------------------------------------------------------------------------

export const PAY_AT_COURSE = "pay_at_course";
export const CUSTOMER_INVOICE = "customer_invoice";
export const CREDIT_CARD = "credit_card"
export const BANK_ACCOUNT = "bank_account"

export const getPaymentMethods = () => {
  const methods = [
    {
      id: 1,
      paymentType: "Credit Card",
      icon: faCreditCard,
      type: "credit_card",
      enabled: (permissions) => {
        return permissions?.creditCardPaymentsEnabled?.();
      },
    },
    {
      id: 2,
      paymentType: "Bank Account",
      icon: faBank,
      type: "bank_account",
      enabled: (permissions) => {
        return (
          permissions?.bankAccountPaymentsAEnabled?.() &&
          permissions?.hasBankAccountAttached
        );
      },
    },
    {
      id: 3,
      paymentType: "Pay at Course",
      icon: faGolfBallTee,
      type: "pay_at_course",
      enabled: (permissions) => {
        return permissions?.payAtCourseEnabled?.();
      },
    },
    {
      id: 4,
      paymentType: "Invoice",
      icon: faFileContract,
      type: "customer_invoice",
      enabled: (permissions) => {
        return permissions?.invoicesEnabled?.();
      },
    },
  ];
  return methods;
};

export const getActivePaymentMethods = (permissions) => {
  const activeMethods =  getPaymentMethods().filter?.((p) => p.enabled(permissions));
  return activeMethods
};

export const isCardAttached = (registrationOrder) => {
  const savedCardSelected = (registrationOrder?.paymentOptionKey ?? "") !== "";
  const newCardSelected = (registrationOrder?.globalToken ?? "") !== "";
  const stripeCardAttached = (registrationOrder?.stripeToken ?? "") !== "";
  return savedCardSelected || newCardSelected || stripeCardAttached;
};

export const isBankAttached = (registrationOrder) => {
  return (registrationOrder?.paymentOptionKey ?? "") !== "";
};
