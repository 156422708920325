import {
  defaultBorders,
  defaultLayouts,
  defaultPadding,
} from "golfstatus_react_components";
import React from "react";
import { useTheme } from "../../hooks/themeHooks";
import { checkCustomFieldResponses } from "../../models/Registration";
import { useSelector } from "react-redux";
import { selectRegistrationOrder } from "../../reducers/tournamentSlice";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import CartFormLineItem from "../events/event/cart/cart-form-line-item";

const CartCustomResponses = () => {
  const [, theme, getStyle] = useTheme();
  const navigate = useNavigate();

  const registrationOrder = useSelector(selectRegistrationOrder);

  const getOrderFormStatus = () => {
    const warnings = checkCustomFieldResponses(registrationOrder, "", []);
    if (warnings.length > 0) {
      return {
        status: "incomplete",
        required: true,
        actions: [
          {
            title: "Add Details",
            buttonIcon: faPen,
            style: {
              ...getStyle(theme.errorContainer),
              ...defaultBorders.mediumLargeBorderRadius,
            },
          },
        ],
      };
    }
    return {
      status: "complete",
      required: true,
      actions: [
        {
          title: "Edit",
          type: "grey",
          buttonIcon: faPen,
          style: {
            ...getStyle(theme.surfaceContainerHigh),
            ...defaultBorders.mediumLargeBorderRadius,
          },
        },
      ],
    };
  };

  const getStatusBorder = (status) => {
    if (status === "incomplete") {
      return {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: getStyle(theme.error)?.color,
      };
    } else {
      return {};
    }
  };

  const status = getOrderFormStatus();

  return (
    <div
      style={{
        ...defaultLayouts.fullWidth,
        ...getStyle(theme.surfaceVariant),
        ...defaultPadding.xLargePad,
        ...defaultBorders.mediumBorderRadius,
      }}
    >
      <div
        style={{
          ...defaultLayouts.fullWidth,
          ...getStyle(theme.surface),
          ...getStatusBorder(status.status),
          ...defaultPadding.xLargePad,
          ...defaultBorders.mediumLargeBorderRadius,
        }}
        onClick={() => {
          navigate(`orderForm`);
        }}
      >
        <CartFormLineItem formName="Additional Forms" {...status} />
      </div>
    </div>
  );
};

export default CartCustomResponses;
