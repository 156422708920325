import { useSelector } from "react-redux";
import {
  selectCompletedOrder,
  selectRegistrationOrder,
} from "../../reducers/tournamentSlice";
import SummaryLineItem from "../events/event/cart/summary-line-item";
import { moneyWithCommas } from "../../helpers/RegexHelper";

const InternationalFeeWidget = (props) => {
  const { complete } = props;
  const completedOrder = useSelector(selectCompletedOrder);
  const inProgressOrder = useSelector(selectRegistrationOrder);
  const registrationOrder = complete ? completedOrder : inProgressOrder;

  const getFee = () => {
    const fee = Number.parseFloat(registrationOrder?.internationalFee ?? 0)
    const rounded = Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2, roundingMode: "ceil"}).format(fee)
    return `${moneyWithCommas(rounded) }` 
  }

  return (
    <SummaryLineItem
      label="International Card Fee"
      description={
        "This is an international card and is subject to additional fees."
      }
      value={
        getFee()
      }
    />
  );
};

export default InternationalFeeWidget;