import React from "react";
import {
  GSSidePanelPage,
  defaultPadding,
  defaultLayouts,
  defaultGapping,
  defaultTypography,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./round-rules.scss";
import { selectTournamentRound } from "../../../../reducers/tournamentSlice";
import { useSelector } from "react-redux";
import { useTheme } from "../../../../hooks/themeHooks";
import PageHeader from "../../../page-header";

//Name the component
const RoundRules = (props) => {
  const navigate = useNavigate();

  const currentRound = useSelector(selectTournamentRound);
  const format = currentRound?.tournamentFormats?.[0] ?? {};
  const { description, rules, name } = format ?? {};

  const [, theme, getStyle] = useTheme();

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Round ${currentRound.roundName ?? currentRound.number} Rules`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getRules = () => {
    return (
      <div style={{ ...defaultLayouts.vStack, ...defaultPadding.xLargePad }}>
        <div
          style={{
            ...defaultPadding.xLargePad.apply("vertical"),
            borderBottom: "1px solid",
            ...getStyle(theme.outlineVariant),
            ...defaultGapping.mediumGap,
            ...defaultLayouts.vStack,
          }}
        >
          <div style={{ ...defaultTypography.body }}>{name}</div>
          <div style={{ ...defaultTypography.bodyRegular, opacity: 0.7 }}>
            {description}
          </div>
        </div>

        <div
          style={{
            ...defaultPadding.xLargePad.apply("vertical"),
            ...defaultTypography.bodyRegular,
            opacity: 0.7,
          }}
        >
          {rules}
        </div>
      </div>
    );
  };

  const getContent = () => {
    return (
      <gs-form>
        <PageHeader
          header={`Round ${currentRound.roundName ??
            currentRound.number} Rules`}
        />
        {getRules()}
      </gs-form>
    );
  };

  return (
    //name the component tag
    <round-rules>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
      ></GSSidePanelPage>
    </round-rules>
  );
};

//export the new namet
export default RoundRules;
