import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  GSEmptyList,
  defaultPadding,
  defaultLayouts,
  GSPageSection,
} from "golfstatus_react_components";
import React, { useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getRoundCourses,
  getRoundTees,
  selectRoundCourses,
  selectTournamentRound,
  setTournamentRoundID,
} from "../../../../reducers/tournamentSlice";

import "./event-rounds.scss";
import { getRouting } from "../../../../helpers/Converters";
import {
  ROUND_ID,
  TOURNAMENT_ID,
  eventBaseRoute,
  getProp,
} from "../../../../routes/event";
import { useHeaderStyle, useSectionStyles } from "../../../../hooks/themeHooks";
import CurrentRoundWidget from "../../../widgets/current-round-widget";
import RoundNavigationWidget from "../../../widgets/round-navigation-widget";
import CourseDetailsWidget from "../../../widgets/course-details-widget";
import RoundScorecardWidget from "../../../widgets/round-scorecard-widget";
import PageHeader from "../../../page-header";
import { useNavigationButtons } from "../../../../hooks/pageHooks";
import { usePermissions } from "../../../../hooks/permissionHooks";
import { useLoading, useTournament } from "../../../../hooks/tournamentHooks";

const EventRounds = (props) => {
  const tournament = useTournament();
  const tournamentRound = useSelector(selectTournamentRound);
  const roundCourses = useSelector(selectRoundCourses);
  const navigate = useNavigate();

  const [isLoading] = useLoading(["getRoundCourses","getRoundTees","getPublicTournamentDetails"])

  const route = getRouting(eventBaseRoute, [TOURNAMENT_ID, "rounds", "*"]);
  const routeWithID = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "rounds",
    ROUND_ID,
    "*",
  ]);

  const matchNoID = useMatch(route);
  const matchID = useMatch(routeWithID);

  const match = matchID ?? matchNoID;
  const roundID = getProp(match?.params, ROUND_ID);

  const tournamentID = tournament?.id;

  const firstRound = tournament?.tournamentRounds?.[0];

  const { defaultSectionStyle, headerSectionStyle } = useSectionStyles();

  const dispatch = useDispatch();

  const getPageActions = useNavigationButtons("rounds");

  const permissions = usePermissions();

  useEffect(() => {
    if (!roundID) {
      navigate(firstRound?.id, { replace: true });
    }
  }, [navigate, roundID, firstRound?.id]);

  useEffect(() => {
    if (
      !!roundID &&
      roundID !== tournamentRound?.id &&
      tournament?.tournamentRounds?.length > 0
    ) {
      dispatch(setTournamentRoundID(roundID));
      dispatch(getRoundCourses(roundID));
      dispatch(getRoundTees(roundID));
    }
  }, [
    dispatch,
    roundID,
    tournamentRound?.id,
    tournament?.tournamentRounds?.length,
  ]);

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Rounds"
        detail="This event is currently does not have any rounds associated with it..."
        actions={[
          {
            title: "Event Details",
            type: "black",
            onClick: () => {
              navigate(getRouting(eventBaseRoute, [tournamentID]));
            },
          },
        ]}
        style={defaultPadding.emptyListPad}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getRoundDetailsBody = () => {
    let body = [<RoundNavigationWidget />];

    if (!tournament?.tournamentPromotion?.hideCourseDetails) {
      body.unshift(<CourseDetailsWidget />);
    }

    return body;
  };

  const getItemList = () => {
    return (
      <div style={{ ...defaultLayouts.vStack }}>
        <PageHeader header="Rounds" />
        {permissions.hasRounds ? (
          <GSPageSection
            {...headerSectionStyle}
            body={[<CurrentRoundWidget />]}
          />
        ) : (
          getEmptyListMessage()
        )}

        {tournamentRound?.facility ? (
          <GSPageSection
            title="Round Details"
            {...defaultSectionStyle}
            body={getRoundDetailsBody()}
          />
        ) : null}

        {roundCourses?.length > 0 && !permissions.hideCourseDetails ? (
          <GSPageSection
            title="Tees"
            {...defaultSectionStyle}
            body={[<RoundScorecardWidget />]}
          />
        ) : null}
      </div>
    );
  };

  const { headerStyle } = useHeaderStyle();

  return (
    <event-rounds>
      <GSListPage
        title=""
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        headerStyle={headerStyle}
        loading={isLoading}
      ></GSListPage>
    </event-rounds>
  );
};

export default EventRounds;
