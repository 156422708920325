import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getCustomerInvoiceDetailsPath,
  payCustomerInvoicePath,
  payThunkResponse,
  downloadCustomerInvoicePath,
  fileDownloadThunkResponse,
  thunkResponse,
  getThunkResponse,
  defaultNotificationSettings,
  registrationOrdersURL,
} from "../app/api";
import gsApi from "../auth/auth";
import {getResponse} from "../helpers/JSONapi";


export const getCustomerInvoiceDetails = createAsyncThunk(
  "invoice/getCustomerInvoiceDetails",
  async (id, thunkAPI) => {
    return await getResponse(gsApi.get, getCustomerInvoiceDetailsPath(id));
  }
);

export const payCustomerInvoice = createAsyncThunk(
  "invoice/payCustomerInvoice",
  async (paymentInfo, thunkAPI) => {
    return await getResponse(
      gsApi.post,
      payCustomerInvoicePath(paymentInfo.registrationOrderId),
      paymentInfo,
      thunkAPI,
    );
  }
);

export const downloadCustomerInvoice = createAsyncThunk(
  "invoice/downloadCustomerInvoice",
  async (id, thunkAPI) => {
    return await gsApi.get(downloadCustomerInvoicePath(id), {
      responseType: "arraybuffer"
    });
  }
);

export const checkInternationalCard = createAsyncThunk(
  "invoice/checkInternationalCard",
  async (data, thunkAPI) => {
    const { registrationOrder, cardInfo } = data;
    const response = await getResponse(
      gsApi.post,
      `${registrationOrdersURL}/international-card-check`,
      cardInfo,
      thunkAPI
    );

    const order = {
      ...registrationOrder,
      internationalCardUsed: response?.data?.["international_card"],
    };
    return calculatePricing(order, thunkAPI);
  }
);

const calculatePricing = async (registrationOrder, thunkAPI) => {
  const response = await getResponse(
    gsApi.post,
    `${registrationOrdersURL}/calculate-order-pricing`,
    registrationOrder,
    thunkAPI
  );
  return { ...registrationOrder, ...response };
};

export const calculateOrderPricing = createAsyncThunk(
  "invoice/calculateOrderPricing",
  calculatePricing
);

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    billingAddress: {
      country: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postal: '',
    },
    captchaData: {
      captcha_token: '',
      captcha_action: 'CHECKOUT',
      is_score: false,
    },
    invoiceDetails: null,
    loading: [],
    notifications: [],
    paymentDetails: {
      registrantCoversFees: true,
      savePayment: false,
    },
    selectedPaymentOption: null,
    publicTournament: {}
  },
  reducers: {
    addNotification: (state, action) => {
      state.notifications.push(action.payload)
    },
    clearNotifications: (state) => {
      state.notifications = [];
    },
    setBillingAddress: (state, action) => {
      state.billingAddress = action.payload;
    },
    setCaptchaToken: (state, action) => {
      state.captchaData = { ...state.captchaData, captcha_token: action.payload };
    },
    setPaymentDetails: (state, action) => {
      state.paymentDetails = action.payload;
    },
    setSelectedPaymentOption: (state, action) => {
      state.selectedPaymentOption = action.payload;
      state.paymentDetails.paymentOptionId = action.payload?.id;
    },
  },
  extraReducers: (builder) => {
    thunkResponse(builder, getCustomerInvoiceDetails, (state, action) => {
      state.invoiceDetails = action.payload;
    }, {rejected: {...defaultNotificationSettings.rejected, message : () => "Could not find invoice..."}});
    payThunkResponse(builder, payCustomerInvoice, (state, action) => {
      state.invoiceDetails = action.payload;
    });
    fileDownloadThunkResponse(builder, downloadCustomerInvoice, (state, action) => {
      const tournamentName = state.invoiceDetails?.tournament?.name.replaceAll(' ', '-');
      const invoiceNumber = state.invoiceDetails?.registrationOrder?.id.toString().padStart(8, '0');
      return `${tournamentName}-Invoice-${invoiceNumber}.pdf`;
    });
    getThunkResponse(builder, checkInternationalCard, (state, action) => {
      state.invoiceDetails.registrationOrder = { ...state.invoiceDetails.registrationOrder, ...action.payload };
      state.paymentDetails.internationalCardUsed = { ...state.paymentDetails, internationalCardUsed: action.payload.internationalCardUsed };
    });
    thunkResponse(builder, calculateOrderPricing, (state, action) => {
      state.invoiceDetails.registrationOrder = { ...state.invoiceDetails.registrationOrder, ...action.payload };
    });
  },
});

export const {
  clearNotifications,
  setBillingAddress,
  setCaptchaToken,
  setPaymentDetails,
  setSelectedPaymentOption,
  addNotification
} = invoiceSlice.actions;

export const selectBillingAddress = (state) => state.invoice.billingAddress;
export const selectCaptchaData = (state) => state.invoice.captchaData;
export const selectInvoiceDetails = (state) => state.invoice.invoiceDetails;
export const selectInvoiceNotifications = (state) => state.invoice.notifications;
export const selectInvoiceLoading = (state) => state.invoice.loading;
export const selectPaymentDetails = (state) => state.invoice.paymentDetails;
export const selectSelectedPaymentOption = (state) => state.invoice.selectedPaymentOption;

export default invoiceSlice.reducer;
