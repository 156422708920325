import {
  GSItemList,
  GSSplitView,
  useMediaQuery,
  defaultBorders,
  defaultGapping,
  defaultLayouts,
  defaultPadding,
  defaultTypography,
  GSField,
} from "golfstatus_react_components";
import React from "react";
import DonationLineItem from "../events/donation-line-item";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPublicTournament,
  selectRegistrationOrder,
  selectTournamentPaymentSettings,
  updateOrder,
} from "../../reducers/tournamentSlice";
import { moneyWithCommas } from "../../helpers/RegexHelper";
import { useTheme } from "../../hooks/themeHooks";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { newDonationOrderItem } from "../../models/Registration";

const FullDonationWidget = (props) => {
  const { style } = props;
  const tournament = useSelector(selectPublicTournament);
  const tournamentPaymentSettings = useSelector(
    selectTournamentPaymentSettings
  );
  const registrationOrder = useSelector(selectRegistrationOrder);

  const { customDonationValues } = tournamentPaymentSettings;

  const { isPhone } = useMediaQuery();

  const [, theme, getStyle] = useTheme();

  const dispatch = useDispatch();

  const donate = (amount) => {
    const ru =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => d?.roundUp)
        ?.map?.((d) => {
          return { round_up: true };
        }) ?? [];

    const donations = [...ru];

    if (amount !== "") {
      donations.push(newDonationOrderItem(amount));
    }

    const update = {
      ...registrationOrder,
      donationOrderItems: donations,
    };

    dispatch(updateOrder(update));
  };

  const donation = registrationOrder?.donationOrderItems?.find?.(
    (d) => !d.roundUp
  );

  const getBorderStyle = (value) => {
    if (value === donation?.amount) {
      return {
        border: "4px solid",
      };
    } else {
      return {
        border: "4px solid transparent",
      };
    }
  };

  return (
    <GSSplitView
      style={style}
      dividerStyle={{ width: "0" }}
      left={
        <div
          style={{
            ...defaultLayouts.fill,
            ...defaultLayouts.vStack.align("flex-start", "center"),
            ...defaultGapping.mediumLargeGap,
          }}
        >
          <h2 style={{...defaultTypography.headline2}}>Make a Donation</h2>
          <DonationLineItem
            {...tournament}
            {...tournamentPaymentSettings}
            hideValues={true}
            style={{ ...defaultLayouts.fullWidth }}
          />
        </div>
      }
      right={
        <div
          style={{
            ...defaultLayouts.vStack,
            ...defaultLayouts.fullWidth,
            ...defaultGapping.xLargeGap,
          }}
        >
          <GSItemList
            type="inline-grid medium-large-gap selectable"
            style={{ ...defaultLayouts.fullWidth }}
            gridColumns={isPhone() ? 1 : 2}
            items={customDonationValues ?? []}
            itemSelected={(donation) => {
              donate?.(donation);
            }}
            listItem={(donation) => (
              <div
                className="donation-option hover-outline"
                style={{
                  ...defaultPadding.xLargePad,
                  ...defaultLayouts.fullWidth,
                  ...getStyle(theme.surface),
                  ...defaultLayouts.vStack.align("center", "center"),
                  ...defaultBorders.mediumLargeBorderRadius,
                  ...defaultTypography.body,
                  ...getBorderStyle(donation),
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    ...defaultTypography.overline2,
                    opacity: 0.7,
                    ...defaultTypography.uppercase,
                  }}
                >
                  Donate
                </div>
                {moneyWithCommas(donation, true)}
              </div>
            )}
          />
          <GSField
            required={true}
            leftIcon={faDollarSign}
            value={donation?.amount}
            onChange={(e) => {
              donate(e?.target?.value);
            }}
            placeholder="0.00"
            label="donation amount"
            isEditable={true}
            style={{ ...defaultLayouts.fullWidth }}
          />
        </div>
      }
    />
  );
};

export default FullDonationWidget;
