import {
  GSImage,
  GSItemList,
  defaultLayouts,
} from "golfstatus_react_components";
import React from "react";
import {
  selectTournamentSponsors,
} from "../../reducers/tournamentSlice";
import { useSelector } from "react-redux";

const TournamentSponsorsWidget = () => {

  const sponsorships = useSelector(selectTournamentSponsors);

  return (
    <GSItemList
      style={{ ...defaultLayouts.fullWidth }}
      listStyle={{ ...defaultLayouts.hStack.align("center", "center") }}
      type="horizontal medium-large-gap wrapped"
      items={sponsorships}
      listItem={(sponsorship) => (
        <GSImage src={sponsorship.logo} size="small" ratio="wide" />
      )}
    />
  );
};

export default TournamentSponsorsWidget


