import { GSButton, GSImage, GSInfoGroup, defaultLayouts, defaultTypography } from "golfstatus_react_components";
import React from "react";
import './tech-sponsor-line-item.scss'
import { faExternalLinkSquare } from "@fortawesome/free-solid-svg-icons";
import { openExternalLink } from "../../helpers/Utilities";

const TechSponsorLineItem = (props) => {

  const {logo, name, sponsorshipType, message, website, showType, style} = props

  const logoSection = {
    type:"full-width",
    sectionItems: [
      {
        type: "primary no-wrap full-width",
        value: <GSImage onClick={()=> { if (website) openExternalLink(website)}} style={{...defaultLayouts.fullWidth}} src={logo} />,
      },
    ]
  }

  const logoColumn ={
    fit: "stretch",
    type: "full-width",
    sections: [
      logoSection
    ],
  }

  const sponsorInfoSection = {
    sectionItems: [
      
    ]
  }

  if(name){
    sponsorInfoSection.sectionItems.push(
      {
        type: "headline-3",
        value: name
      },
    )
  }

  if(sponsorshipType && showType){
    sponsorInfoSection.sectionItems.push(
      {
        type: "secondary",
        style: defaultTypography.capitalize ,
        value: `${sponsorshipType} sponsor`
      }
    )
  }

  const sponsorMessageSection = {
    sectionItems: [
      {
        type: "secondary",
        value: message,
      }
    ]
  }

  const sponsorwebsiteSection = {
    sectionItems: [
      {
        type: "primary",
        value: <GSButton title="Sponsor Website" rightIcon={faExternalLinkSquare} type="grey" onClick={() => {
          openExternalLink(website)
        }}/>
      }
    ]
  }


  const sponsorInfoColumn = {
    fit: "stretch",
    sections: [sponsorMessageSection ]
  }

  if(name || sponsorshipType){
    sponsorInfoColumn.sections.unshift(sponsorInfoSection)
  }

  const getGroup = () => {
    if((website ?? "") !== ""){
      sponsorInfoColumn?.sections?.push?.(sponsorwebsiteSection)
    }
    let group = [logoColumn, sponsorInfoColumn]
    return group
  }

  return <tech-sponsor-line-item style={style}>
    <GSInfoGroup listType="mobile-vertical" dataGroups={getGroup()}></GSInfoGroup>
  </tech-sponsor-line-item>
}


export default TechSponsorLineItem