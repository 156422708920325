import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  applyDiscount,
  calculatePricing,
  selectCompletedOrder,
  selectPublicTournament,
  selectRegistrationOrder,
} from "../../reducers/tournamentSlice";
import {
  defaultGapping,
  defaultLayouts,
  GSButton,
  GSInput,
} from "golfstatus_react_components";
import { useTheme } from "../../hooks/themeHooks";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import SummaryLineItem from "../events/event/cart/summary-line-item";
import { moneyWithCommas } from "../../helpers/RegexHelper";

const CheckoutDiscountWidget = (props) => {

  const {complete} = props
    const completedOrder = useSelector(selectCompletedOrder)
    const inProgressOrder = useSelector(selectRegistrationOrder)
    const registrationOrder = complete ? completedOrder : inProgressOrder 
  
  const [discountCode, setDiscountCode] = useState("");

  const dispatch = useDispatch();

  const tournament = useSelector(selectPublicTournament);

  const discount = registrationOrder?.discountCodes?.[0];

  const [, theme, getStyle] = useTheme();

  const addDiscount = () => {
    const ru =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => d?.roundUp)
        ?.map?.((d) => {
          return { round_up: true };
        }) ?? [];
    const don =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => !d?.roundUp)
        ?.map?.((d) => {
          return { id: d?.id, amount: d?.amount };
        }) ?? [];

    dispatch(
      applyDiscount({
        tournamentID: tournament?.id,
        discountCode,
        registrationOrder: {
          ...registrationOrder,
          donationOrderItems: [...ru, ...don],
        },
      })
    );
  };

  const removeDiscountCode = () => {
    const ru =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => d?.roundUp)
        ?.map?.((d) => {
          return { round_up: true };
        }) ?? [];
    const don =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => !d?.roundUp)
        ?.map?.((d) => {
          return { id: d?.id, amount: d?.amount };
        }) ?? [];

    const update = {
      ...registrationOrder,
      discountCodes: [],
      donationOrderItems: [...ru, ...don],
    };

    dispatch(calculatePricing(update));
  };

  const getView = () => {
    if (!complete) {
      if ((discount?.code ?? "") === "") {
        return (
          <div
            style={{
              ...defaultLayouts.hStack.align("flex-start", "center"),
              ...defaultLayouts.fullWidth,
              ...defaultGapping.largeGap,
            }}
          >
            <GSInput
              textValue={discountCode}
              style={{
                ...defaultLayouts.fullWidth,
                ...getStyle(theme.surface),
              }}
              placeholder="Discount Code"
              onChange={(e) => {
                setDiscountCode(e?.target?.value);
              }}
            />
            <GSButton
              title="Apply"
              type="black"
              isDisabled={(discountCode ?? "") === ""}
              onClick={() => {
                addDiscount();
              }}
            />
          </div>
        );
      }
      return (
        <GSButton
          title="remove"
          buttonIcon={faTimesCircle}
          type="grey secondary"
          onClick={() => {
            removeDiscountCode();
          }}
        />
      );
    }
  };

  return (
    <SummaryLineItem
      style={{ ...defaultLayouts.fullWidth }}
      label="Discount"
      description="Optional"
      value={
        discount?.discountDollarAmount
          ? `-${moneyWithCommas(discount?.discountDollarAmount)}`
          : undefined
      }
      content={getView()}
    />
  );
};

export default CheckoutDiscountWidget;
