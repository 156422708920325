import React, { useState } from "react";
import {
  GSForm,
  GSSidePanelPage,
  useFormDataValidation,
} from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

//import scss
import "./events-filter.scss";

import { useDispatch, useSelector } from "react-redux";
import { selectTournamentFilter, setEventListFilter, setTournamentList } from "../../reducers/tournamentSlice";
import { getEventListFilterForm } from "../../forms/EventListForms";
import { cleanFilter, tournamentFilter } from "./events-list";

//Name the component
const EventsFilter = (props) => {
  const dispatch = useDispatch();
  const currentFilter = useSelector(selectTournamentFilter);
  const [filter, setFilter] = useState(currentFilter);

  const [context, isValid, setIsValid] = useFormDataValidation({
    initialValid: false,
    data: filter,
    setData: (update) => {
      setFilter(update);
    },
  });

  const navigate = useNavigate();

  const save = () => {
    setIsValid(false);
    dispatch(setTournamentList([]))
    dispatch(setEventListFilter({...cleanFilter(filter), page: 1}))
    navigate(-1)
  };

  const revert = () => {
    const {latitude, longitude} = filter
    const filterReset = {...tournamentFilter, latitude, longitude}
    dispatch(setTournamentList([]))
    dispatch(setEventListFilter({...cleanFilter(filterReset), page: 1}))
    navigate(-1)
  }

  const getDrawerActions = () => {
    let actions = [
      {
        name: "Apply",
        isDisabled: !isValid,
        action: save,
        type: "black",
      },
      { name: "reset", action: revert, type: "grey" },
    ];
    return actions;
  };

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Filter Events`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getContent = () => {
    return (
      <GSForm
        formTitle="Filter Events"
        formSections={getEventListFilterForm(context)}
      ></GSForm>
    );
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  return (
    //name the component tag
    <events-filter>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </events-filter>
  );
};

//export the new namet
export default EventsFilter;
