import { useDispatch, useSelector } from "react-redux";
import {
  getPublicTournamentDetails,
  getTournamentPackages,
  selectLoading,
  selectPublicTournament,
  selectRegistrationCustomer,
  selectRegistrationOrder,
  selectTournamentPackages,
  updateCustomer,
  updateTournament,
} from "../reducers/tournamentSlice";
import { useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { eventBaseRoute, getProp, TOURNAMENT_ID } from "../routes/event";
import { getRouting } from "../helpers/Converters";
import { sortPackages } from "../components/events/event/event-packages";
import { selectUserPaymentOptions } from "../reducers/userSlice";

import { ACTIVE } from "./permissionHooks";
import { selectLoggedInUser } from "../reducers/appSlice";
import { registrationCustomerComplete } from "../models/Registration";

import { selectLoading as selectLeaderboardLoading } from "../reducers/leaderboardSlice";
import { selectInvoiceLoading } from "../reducers/invoiceSlice";
import { selectLoading as selectOrgLoading } from "../reducers/organizationSlice";
import { selectLoading as selectUserLoading } from "../reducers/userSlice";
import { selectLoading as selectAppLoading } from "../reducers/appSlice";
import { BANK_ACCOUNT, CREDIT_CARD } from "../forms/CartSections";

export const useTournament = (load) => {
  const tournament = useSelector(selectPublicTournament);
  const loading = useSelector(selectLoading).some?.(c => c === "getPublicTournamentDetails")

  const route = getRouting(eventBaseRoute, [TOURNAMENT_ID, "*"]);

  const match = useMatch(route);

  const id = getProp(match?.params, TOURNAMENT_ID);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const matchid = id?.replace?.(".com", "") 
    if(matchid && (matchid !== tournament?.id && matchid !== tournament.publicUrl) && !loading && load ){
     dispatch(getPublicTournamentDetails(id)).then(response => {
      if(response?.error){
        dispatch(updateTournament({publicUrl: response?.meta?.arg}))
        navigate(`/event/${id}/not-found`)
      }
     });
    }
  }, [id, dispatch, tournament?.id, tournament?.publicUrl, loading, load, navigate]);

  const event = tournament?.id ? tournament: { publicUrl: id};
  return event
};

export const packageHasWaitlist = (tournament, tournamentPackage) => {
  const isTeamRegistration = tournamentPackage?.packageType === "registration"
  const isIndRegistraton = tournamentPackage?.packageType === "individual"

  const isPlayerReg = isTeamRegistration || isIndRegistraton
  const waitlistEnabled = tournament.hasWaitList
  return isPlayerReg && waitlistEnabled && packageSoldOut(tournament, tournamentPackage)
}

export const packageSoldOut = (tournament, tournamentPackage) => {
  const noTeamsLeft = soldOut(tournament)
  const isPlayerReg = tournamentPackage?.hasTeamPackageItem || tournamentPackage?.hasPlayerPackageItem
  const playersSoldOut = isPlayerReg && noTeamsLeft  
  const packageSoldOut =  tournamentPackage?.remainingPackages <= 0
  return playersSoldOut || packageSoldOut
}

export const soldOut = (tournament) => {
  return tournament.numberRegisteredTeams >= tournament.numberOfTeams
}

export const useTournamentPackages = (load) => {
  const tournament = useSelector(selectPublicTournament);
  const tournamentPackages = useSelector(selectTournamentPackages);

  const registrationOrder = useSelector(selectRegistrationOrder) ?? {}
  const {accessGroupID} = registrationOrder

  const dispatch = useDispatch();

  const tournamentID = tournament?.id;

  const activePackages = tournamentPackages?.filter?.(
    (p) => {
      const now = new Date()
      const activate = p.activateOn ? new Date(p?.activateOn) : now
      const deactivate =p.deactivateOn ? new Date(p?.deactivateOn) : now
      const past = deactivate < now
      const future = activate > now
      const groupAccess = !p?.hasAccessGroup || p?.accessGroup?.id === accessGroupID
      const active = p?.state === ACTIVE && !past && !future
      return groupAccess && active  
    }
  );

  const accessiblePackages = tournamentPackages?.filter?.(
    (p) => {
      const noTeamsLeft = soldOut(tournament)
      const isPlayerReg = p?.hasTeamPackageItem || p?.hasPlayerPackageItem
      const now = new Date()
      const activate = p.activateOn ? new Date(p?.activateOn) : now
      const deactivate =p.deactivateOn ? new Date(p?.deactivateOn) : now
      const past = deactivate < now
      const future = activate > now
      const groupAccess = !p?.hasAccessGroup || p?.accessGroup?.id === accessGroupID
      const active = p?.state === ACTIVE && !past && !future
      const notSoldOut =  p?.remainingPackages > 0
      const playersSoldOut = isPlayerReg && noTeamsLeft 
      return groupAccess && active && notSoldOut && !playersSoldOut
    }
  );

  const hasAccessPackages = tournamentPackages?.some?.((p) => p.hasAccessGroup && p.state === ACTIVE);

  useEffect(() => {
    if (tournamentID && load) {
      dispatch(getTournamentPackages(tournamentID));
    }
  }, [dispatch, tournamentID, load]);

  const {
    teamPackages,
    playerPackages,
    sponsorPackages,
    addonPackages,
  } = sortPackages(activePackages, tournament);


  return {
    tournamentPackages,
    accessiblePackages,
    teamPackages,
    playerPackages,
    sponsorPackages,
    addonPackages,
    hasAccessPackages,
    activePackages,

  };
};


export const VERIFIED = "verified"

export const usePaymentOptions = () => {
  const savedPaymentOptions = useSelector(selectUserPaymentOptions);
  const tournament = useSelector(selectPublicTournament)

  const getAvailablePayments = () => {
    if(tournament.escrowOrg){
      return savedPaymentOptions?.filter(po => po.hasGlobal && po.status === VERIFIED )
    }
    else {
      return savedPaymentOptions?.filter(po => po.hasStripe && po.status === VERIFIED)
    }
  }

  const availablePayments = getAvailablePayments()

  const availableCreditCards = availablePayments?.filter?.(p => p.paymentType === CREDIT_CARD)
  const availableBanks = availablePayments?.filter?.(p => p.paymentType === BANK_ACCOUNT)

  return {savedPaymentOptions, availablePayments, availableCreditCards, availableBanks}

}


export const useCustomer = () => {
  const customer = useSelector(selectRegistrationCustomer)
  const loggedInUser = useSelector(selectLoggedInUser)

  const dispatch = useDispatch();

  useEffect(() => {
      const c = {firstName: customer?.firstName, lastName: customer?.lastName, email: customer?.email, phone: customer?.phone}
      const user = {firstName: loggedInUser?.firstName, lastName: loggedInUser?.lastName, email: loggedInUser?.email, phone: loggedInUser?.phone}
      if (!registrationCustomerComplete(c)) {
        if(registrationCustomerComplete(user)){
          dispatch(updateCustomer(user));
        }
      }
    }, [
      dispatch,
      loggedInUser?.firstName,
      loggedInUser?.lastName,
      loggedInUser?.email,
      loggedInUser?.phone,
      customer?.firstName,
      customer?.lastName,
      customer?.email,
      customer?.phone
    ]);

    const customerComplete = () => {
      return registrationCustomerComplete(customer)
    }

    return {customer, customerComplete}
}


export const useLoading = (filter) => {
  const tournamentLoading = useSelector(selectLoading)
  const leaderboardLoading = useSelector(selectLeaderboardLoading)
  const invoiceLoading = useSelector(selectInvoiceLoading)
  const orgLoading = useSelector(selectOrgLoading)
  const userLoading = useSelector(selectUserLoading)
  const appLoading = useSelector(selectAppLoading)

  const loading = [...tournamentLoading, ...leaderboardLoading, ...invoiceLoading, ...orgLoading, ...userLoading, ...appLoading ]

  const filteredLoading = filter?.length > 0 ? loading?.filter?.(l => filter?.includes?.(l)) : loading

  const isLoading = filteredLoading?.length > 0
  
  return [isLoading, loading]
}