import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import {
  GSButton,
  GSFileSelect,
} from "golfstatus_react_components";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useImageSelector } from "../../hooks/formHooks";

const MobileSponsorLogoSelect = (props) => {
  const {sponsorID, context } = props;

  const [
    ,
    ,
    setLogo,
    removeLogo,
    getLogo,
    ,
  ] = useImageSelector(context, "logoMobile");

  const navigate = useNavigate();

  return (
    <GSFileSelect
      id="logo"
      description="Tap or drag a file to upload. The image ratio is 4:1.  Accepted file types are .JPG, .PNG, .GIF"
      setSelectedFiles={(files) => {
        setLogo(files);
      }}
      removeSourceItem={() => removeLogo("logoMobile")}
      sourceList={getLogo()}
      accept=".jpg, .jpeg, .png"
      editImage={() => {
        navigate(`${sponsorID}/image?property=logoMobile`);
      }}
      minDimension={25} // images come back resized, and sometimes off by <= 1px
      ratio={4}
      warningClicked={() => {
        navigate(`${sponsorID}/image?property=logoMobile`);
      }}
      title={<GSButton buttonIcon={faArrowCircleUp} title="upload"></GSButton>}
      failedValidation={context.validationFailed}
    ></GSFileSelect>
  );
};

export default MobileSponsorLogoSelect;
