import {
  GSCircleImage,
  GSInfoGroup,
  defaultGapping,
} from "golfstatus_react_components";
import React from "react";
import "./unasigned-player-line-item.scss";
import { defaultAvatar } from "../cart/customer-line-item";

/**
 * Team and player info group.
 * @typedef player
 * @type {object}
 * @property {string} avatar avatar url of the player
 * @property {string} name name of the player
 *
 * @typedef Properties
 * @type {object}
 * @property {string} name name of team
 * @property {string} contactName name of the team contact
 * @property {Array.<player>} players players on the team
 * @property {object} style style for the component container
 *
 * @param {Properties} props component properties
 */

const UnassignedPlayerLineItem = (props) => {
  const { style } = props;

  const defaultStyle = { ...defaultGapping.mediumLargeGap };

  const avatarSection = (avatar) => {
    return {
      sectionItems: [
        {
          type: " no-wrap full-width",
          value: <GSCircleImage url={avatar} size="large" />,
        },
      ],
    };
  };

  const playerInfoSection = (player) => {
    return {
      sectionItems: [
        {
          type: "primary no-wrap full-width",
          value: player.name,
        },
      ],
    };
  };

  const avatarColumn = (player) => {
    return { sections: [avatarSection(defaultAvatar)] };
  };

  const teamInfoColumn = (player) => {
    return {
      sections: [playerInfoSection(player)],
    };
  };

  const getPlayer = (player) => {
    let group = [avatarColumn(player), teamInfoColumn(player)];
    return group;
  };

  return (
    <unasigned-player-line-item style={{ ...defaultStyle, ...style }}>
      <GSInfoGroup
        listStyle={{ ...defaultGapping.mediumGap }}
        dataGroups={getPlayer(props)}
      ></GSInfoGroup>
    </unasigned-player-line-item>
  );
};

export default UnassignedPlayerLineItem;
