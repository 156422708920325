import React from "react";
import { Navigate, useMatch } from "react-router-dom";
import { getRouting } from "../../helpers/Converters";
import { ORDER_ID, getProp } from "../../routes/event";
import { invoiceBaseRoute } from "../../routes/invoice";

const CustomerInvoiceRedirect = (props) => {

  const route = getRouting("",[ORDER_ID])
  const match = useMatch(getRouting(route))
  const orderID = getProp(match?.params, ORDER_ID)

  return <Navigate replace to={getRouting(invoiceBaseRoute, [orderID])}/>
}

export default CustomerInvoiceRedirect