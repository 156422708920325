import {
  defaultBorders,
  defaultLayouts,
  defaultPadding,
  GSItemList,
} from "golfstatus_react_components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePricing,
  removePackageItemFromOrder,
  selectRegistrationOrder,
  updateOrder,
} from "../../reducers/tournamentSlice";
import RegistrationOrderLineItem from "../events/event/packages/registration-order-line-item";
import { useTheme } from "../../hooks/themeHooks";
import { useNavigate } from "react-router-dom";
import { addPackageToRegistrationOrder, decrementOrderItem } from "../../models/Registration";

const CartMyPackagesWidget = () => {
  const registrationOrder = useSelector(selectRegistrationOrder);

  const [, theme, getStyle] = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addPackage = (tournamentPackage) => {
    let o = { ...registrationOrder };
    let p = { ...tournamentPackage };
    addPackageToRegistrationOrder(p, o);
    dispatch(calculatePricing(o));
    dispatch(updateOrder(o));
  };

  const decrementPackage = (orderItem) => {
    let order = { ...registrationOrder };
    let items = [...registrationOrder?.registrationOrderItems]
    let item = decrementOrderItem(orderItem, order);
    const itemIndex = registrationOrder?.registrationOrderItems.findIndex(i => i.id === orderItem.id)
    items[itemIndex] = item
    order.registrationOrderItems = items
    dispatch(calculatePricing(order))
    dispatch(updateOrder(order))
  };

  const removePackage = (orderItem) => {
    dispatch(removePackageItemFromOrder(orderItem));
  };

  return (
    <GSItemList
      type="vertical large-gap"
      style={{ ...defaultLayouts.fullWidth }}
      items={registrationOrder?.registrationOrderItems}
      listItem={(p) => (
        <RegistrationOrderLineItem
          {...p}
          style={{
            ...defaultLayouts.fullWidth,
            ...getStyle(theme.surfaceVariant),
            ...defaultPadding.xLargePad,
            ...defaultBorders.largeBorderRadius,
          }}
          formSelected={(form) => {
            navigate(`${p.id}/${form.type}?id=${form.id}`);
          }}
          addPackage={addPackage}
          decrementPackage={decrementPackage}
          removePackage={removePackage}
        />
      )}
    />
  );
};

export default CartMyPackagesWidget;
