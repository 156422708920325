import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  defaultPadding,
  GSPageSection,
  defaultLayouts,
  GSEmptyList,
} from "golfstatus_react_components";
import React, { useEffect } from "react";
import { useMatch } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTournamentTeams,
  getUnassignedPlayers,
  selectTournamentTeams,
  selectUnassignedPlayers,
} from "../../../reducers/tournamentSlice";
import "./event-registrants.scss";
import { useHeaderStyle, useSectionStyles } from "../../../hooks/themeHooks";
import { eventBaseRoute, TOURNAMENT_ID } from "../../../routes/event";
import { getRouting } from "../../../helpers/Converters";
import RegisterNowWidget from "../../widgets/register-now-widget";
import TournamentRegistrantsWidget from "../../widgets/tournament-registrants-widget";
import AvailablePackagesWidget from "../../widgets/available-packages-widget";
import PageHeader from "../../page-header";
import { useNavigationButtons } from "../../../hooks/pageHooks";
import { ACTIVE, usePermissions } from "../../../hooks/permissionHooks";
import {
  useLoading,
  useTournament,
  useTournamentPackages,
} from "../../../hooks/tournamentHooks";
import UnassignedPlayersWidget from "../../widgets/unassigned-players-widget";

const EventRegistrants = (props) => {
  const tournamentTeams = useSelector(selectTournamentTeams);
  const unassignedPlayers = useSelector(selectUnassignedPlayers)?.filter?.(
    (t) => t?.status === ACTIVE
  );
  const { teamPackages, playerPackages } = useTournamentPackages(true);
  const tournament = useTournament();
  const packages = [...teamPackages, ...playerPackages];

  const route = getRouting(eventBaseRoute, [TOURNAMENT_ID, "registrants", "*"]);
  const match = useMatch(route);

  const tournamentID = tournament?.id;

  const dispatch = useDispatch();
  const { headerStyle } = useHeaderStyle();

  const { style, defaultSectionStyle, headerSectionStyle } = useSectionStyles();

  const getPageActions = useNavigationButtons("registrants");

  const permissions = usePermissions();

  const [isLoading] = useLoading([
    "getPublicTournamentDetails",
    "getUnassignedPlayers",
    "getTournamentTeams"
  ]);

  useEffect(() => {
    if (tournamentID) {
      dispatch(getTournamentTeams(tournamentID));
      dispatch(getUnassignedPlayers(tournamentID));
    }
  }, [dispatch, tournamentID]);

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const isSidePanelOpen = () => {
    //remove this when you add your match route.
    //
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const hasTeams = () => {
    return tournamentTeams?.length > 0;
  };

  const hasUnassignedPlayers = () => {
    return unassignedPlayers?.length > 0;
  };

  const noRegistrants = () => {
    return !hasTeams() && !hasUnassignedPlayers();
  };

  const getItemList = () => {
    return (
      <div style={{ ...defaultLayouts.vStack }}>
        <PageHeader header="Registrants" />
        {permissions.packagesEnabled() && packages?.length > 0 ? (
          <GSPageSection
            {...headerSectionStyle}
            body={[<RegisterNowWidget />]}
          />
        ) : null}

        {hasTeams() ? (
          <GSPageSection
            bodyStyle={{ ...defaultPadding.noPad }}
            style={style}
            title={
              tournament?.numberOfPlayers === 1
                ? "Registered Players"
                : "Registered Teams"
            }
            body={[<TournamentRegistrantsWidget />]}
          />
        ) : null}

        {hasUnassignedPlayers() ? (
          <GSPageSection
            bodyStyle={{ ...defaultPadding.noPad }}
            style={style}
            title="Unassigned Players"
            body={[<UnassignedPlayersWidget />]}
          />
        ) : null}

        {noRegistrants() ? (
          <GSPageSection
            body={[
              <GSEmptyList
                style={{
                  ...defaultPadding.emptyListPad,
                  ...defaultLayouts.fullWidth,
                }}
                title="No Registrants Yet"
                detail={`${tournament?.name} does not have any registrants.`}
              />,
            ]}
          />
        ) : null}

        {permissions.packagesEnabled() && packages?.length > 0 ? (
          <GSPageSection
            title="Registration Packages"
            bodyStyle={{
              ...defaultSectionStyle.bodyStyle,
              ...defaultPadding.noPad,
            }}
            body={[<AvailablePackagesWidget />]}
          />
        ) : null}
      </div>
    );
  };

  return (
    <event-registrants>
      <GSListPage
        title=""
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        headerStyle={headerStyle}
        loading={isLoading}
      ></GSListPage>
    </event-registrants>
  );
};

export default EventRegistrants;
