
import {
  validations,
} from "golfstatus_react_components";

export const getAccessListForm = (context) => {
  return [
    {
      type: "vertical xx-large-gap",
      fields: [
        {
          label: "Access Code",
          isEditable: true,
          required: true,
          ...context.setBinding?.("accessIdentifier"),
          placeholder: "Access Code",
        },
        {
          label: "Email Address",
          isEditable: true,
          required: true,
          ...context.setBinding("accessEmail"),
          validation: {
            isValid: validations.validEmail,
            invalidLabel: "Must be a valid email.",
          },
          placeholder: "Email Address",
        },
      ],
    },
  ];
};
