import {
  GSPager,
  GSScorecard,
  defaultLayouts,
  useMediaQuery,
  defaultTypography,
  defaultGapping,
} from "golfstatus_react_components";
import React from "react";
import { useSelector } from "react-redux";
import { selectRoundCourses } from "../../reducers/tournamentSlice";
import { integerWithCommas } from "../../helpers/RegexHelper";

const RoundScorecardWidget = () => {
  const { isPhone, isTablet } = useMediaQuery();

  const roundCourses = useSelector(selectRoundCourses);

  const allTees =
    roundCourses?.flatMap((c, index) =>
      c?.roundTees?.map?.((t) => {
        return {
          ...t,
          courseName: `${c?.course?.name}`,
          scorecardPosition: c.scorecardPosition,
          courseIndex: index,
        };
      })
    ) ?? [];
  const allHoles = allTees?.flatMap((t, index) =>
    t?.roundHoles?.map?.((h) => {
      return {
        ...h,
        courseName: t.courseName,
        scorecardPosition: t.scorecardPosition,
        courseIndex: t.courseIndex,
        teeIndex: index,
      };
    })
  );

  const getTeeHoles = (tee) => {
    const teeHoles = allHoles?.filter?.((h) => h?.teeId === tee?.id);
    teeHoles.sort(
      (a, b) => a.scorecardPosition - b.scorecardPosition || a.number - b.number
    );
    return teeHoles?.map?.((t) => ({
      position: t?.number,
      par: t?.par,
      yardage: t?.yardage,
      handicap: t?.handicap,
    }));
  };

  return (
    <GSPager
      items={allTees}
      listItem={(tee) => (
        <div style={{ ...defaultLayouts.vStack, ...defaultLayouts.fullWidth, ...defaultGapping.xxLargeGap }}>
          <div
            style={{ ...defaultLayouts.vStack, ...defaultGapping.mediumGap }}
          >
            <h3 style={{ ...defaultTypography.headline3 }}>
            {tee?.name} Tee
            </h3>
            <div style={{ ...defaultTypography.bodyRegular, opacity: 0.7 }}>
            {tee?.roundHoles?.length} Holes - Par {tee?.tee?.par}
            </div> 
            <div style={{ ...defaultTypography.bodyRegular, opacity: 0.7 }}>
            {tee?.tee?.slope}/{tee?.tee?.rating} - {integerWithCommas(tee?.tee?.yards)} yards
            </div>
            <div style={{ ...defaultTypography.bodyRegular, opacity: 0.7 }}>
            {tee?.courseName}
            </div>
          </div>

          <GSScorecard
            showTotalColumn={true}
            style={{ ...defaultLayouts.fullWidth }}
            nineStyle={{ ...defaultLayouts.fullWidth }}
            splitNines={isPhone() || isTablet()}
            holes={getTeeHoles?.(tee)}
          />
        </div>
      )}
      nextButton={(item) => `${item?.name} Tee`}
      previousButton={(item) => `${item?.name} Tee`}
    />
  );
};

export default RoundScorecardWidget;
