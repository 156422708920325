import React from "react";
import './customer-invoice-contact-information.scss';
import {formattedPhoneNumber} from "../../helpers/RegexHelper";
import {GSInfoGroup} from "golfstatus_react_components";

const CustomerInvoiceContactInformation = ({ details }) => {

  let groups = [
    {
      title: "Need Help?",
      sections: [
        {
          sectionItems: [
            {
              type: "secondary",
              value: "If you have any questions regarding this invoice, please reach out to:",
            },
          ],
        },
        {
          sectionItems: [
            {
              type: "primary no-wrap",
              value: `${details?.invoice?.contactName}`,
            },
            {
              type: "secondary no-wrap",
              value: `${details?.invoice?.contactEmail}`,
            },
            {
              type: "secondary no-wrap",
              value: `${formattedPhoneNumber(details?.invoice?.contactPhone)}`,
            },
          ],
        },
      ],
    },
  ];

  return (
    <customer-invoice-contact-information>
      <GSInfoGroup dataGroups={groups} />
    </customer-invoice-contact-information>
  )
};

export default CustomerInvoiceContactInformation;
