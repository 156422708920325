import { GSHTMLViewer, defaultLayouts } from "golfstatus_react_components";
import React from "react";

export const stripHTML = (html) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

const TournamentHTMLWidget = (props) => {
  const { html } = props;
  if(stripHTML(html) === ""){
    return
  }
  return (
    <GSHTMLViewer
      html={html}
      style={{
        ...defaultLayouts.fullWidth,
        ...defaultLayouts.vStack,
      }}
      htmlContainerStyle={{
        ...defaultLayouts.fullWidth,
        ...defaultLayouts.vStack,
      }}
    />
  );
};

export default TournamentHTMLWidget