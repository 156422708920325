import React from "react";
import CurrentLeaderboardItem from "../events/event/leaderboards/current-leaderboard-item";
import { selectLeaderboard } from "../../reducers/leaderboardSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRouting } from "../../helpers/Converters";
import { eventBaseRoute } from "../../routes/event";
import { useTournament } from "../../hooks/tournamentHooks";

const CurrentLeaderboardWidget = () => {

  const currentLeaderboard = useSelector(selectLeaderboard)
  const navigate = useNavigate()

  const tournament = useTournament()

  return  <CurrentLeaderboardItem
  {...currentLeaderboard}
  navigate={() => {
    navigate?.(
      getRouting(eventBaseRoute, [
        tournament?.publicUrl,
        "leaderboards",
        currentLeaderboard?.id,
        "select",
      ])
    );
  }}
/>

}

export default CurrentLeaderboardWidget