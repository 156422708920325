import { useState, useMemo, useEffect } from "react";
import { clearUtilityNotifications, selectCroppedTarget, setCroppedTarget, uploadFile } from "../reducers/utilitySlice";
import { useDispatch, useSelector } from "react-redux";
import { createFormFromBlob, createFormFromFile } from "../helpers/Utilities";

export const useFormValidation = (initialValid) => {
  const [isValid, setIsValid] = useState(initialValid);
  const [warnings, setWarnings] = useState([]);
  let context = useMemo(() => ({}), []);
  context.validationFailed = (e) => {
    if (isValid) {
      setIsValid(false);
      setWarnings([...warnings, e]);
    }
  };
  return [context, isValid, setIsValid];
};


//Image forms
export const useImageSelector = (context, property) => {
  const [imageReady, setImageReady] = useState(false);
  const croppedTarget = useSelector(selectCroppedTarget);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearUtilityNotifications())
  },[dispatch])

  const setLogo = (files) => {
    const file = files[0];
    if (file) {
      const datauRL = URL.createObjectURL(file)
      context.updateData(datauRL, property);
      const data = createFormFromFile(file);
      let target = {
        ...croppedTarget,
        id: context.getData().id,
      };
      target[property] = data;
      setImageReady(false);
      dispatch(setCroppedTarget(target));
    }
  };

  const removeLogo = () => {
    context.updateData("", property);
  };

  const getLogo = () => {
    if (
      context?.getData?.()?.[property] &&
      context?.getData?.()?.[property] !== "/logos/default/missing.png"
    ) {
      return [{ url: context?.getData?.()?.[property], type: "image" }];
    }
    return [];
  };

  const uploadSelectedFile = () => {
    if (croppedTarget?.id === context?.getData()?.id) {
      if(croppedTarget?.[property]){
        dispatch(uploadFile(croppedTarget?.[property])).then?.((response) => {
          setImageReady(response?.payload?.data?.location ?? false);
        });
      }
      else if(context?.getData?.()?.[property]){
        setImageReady(true)
      }
    }
  };

  return [imageReady, setImageReady, setLogo, removeLogo, getLogo, uploadSelectedFile];
};

//crop image forms
export const useImageCropper = (context, property) => {
  const [croppedImg, setCroppedImg] = useState();
  const [croppedImgBlob, setCroppedImageBlob] = useState();
  const [showCroppedImage, setShowCroppedImage] = useState(false);

  const croppedTarget = useSelector(selectCroppedTarget);
  const dispatch = useDispatch()

  useEffect(() => {
    const editor = document.getElementById("image-editor")
    editor?.focus?.()
  }, [])

  const saveLogo = () => {
    let target = {
      ...croppedTarget,
      id: context?.getData?.()?.id,
    }
    target[property] = createFormFromBlob(croppedImgBlob, "png")
    dispatch(
      setCroppedTarget(target)
    );
    context.updateData(croppedImg, property);
  };

  context.setCroppedDataURL = (dataURL) => {
    setCroppedImg(dataURL);
  };
  context.setCroppedBlob = (blob) => {
    setCroppedImageBlob(blob);
  };

  const applyCrop = () => {
    setShowCroppedImage(true);
  };
  const undoCrop = () => {
    setShowCroppedImage(false);
  };

  return [croppedImg, showCroppedImage, saveLogo, applyCrop, undoCrop]
}