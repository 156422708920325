import React from "react";
import './customer-invoice-billing-information.scss';
import {formattedPhoneNumber} from "../../helpers/RegexHelper";
import {GSInfoGroup} from "golfstatus_react_components";

const CustomerInvoiceBillingInformation = ({ details }) => {

  let groups = [
    {
      title: "Billed To",
      sections: [],
    }
  ];
  if (details?.registrationCustomer?.companyName) {
    groups[0].sections.push({
      sectionItems: [
        {
          type: "primary no-wrap",
          value: `${details?.registrationCustomer?.companyName}`,
        },
      ],
    });
  }
  groups[0].sections.push({
    sectionItems: [
      {
        type: "primary no-wrap",
        value: `${details?.registrationCustomer?.firstName} ${details?.registrationCustomer?.lastName}`,
      },
      {
        type: "secondary no-wrap",
        value: `${details?.registrationCustomer?.email}`,
      },
      {
        type: "secondary no-wrap",
        value: `${formattedPhoneNumber(details?.registrationCustomer?.phone)}`,
      },
    ],
  });
  if (details?.invoice?.memo) {
    groups[0].sections.push({
      sectionItems: [
        {
          type: "secondary",
          value: `${details?.invoice?.memo}`,
        },
      ],
    });
  }

  return (
    <customer-invoice-billing-information>
      <GSInfoGroup dataGroups={groups} />
    </customer-invoice-billing-information>
  );
};

export default CustomerInvoiceBillingInformation;
