import React, {useEffect} from 'react';
import './customer-invoice-payment-confirmation.scss';
import {GSButton, GSForm, GSSidePanelPage} from "golfstatus_react_components";
import {faChevronLeft, faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import {
  clearNotifications,
  getCustomerInvoiceDetails,
  selectInvoiceDetails,
  selectInvoiceNotifications
} from "../../reducers/invoiceSlice";
import {useNotificationBanner} from "../../hooks/notificationHooks";
import {useDispatch, useSelector} from "react-redux";
import {useMatch, useNavigate} from "react-router-dom";
import {getPaymentConfirmationFormSections} from "../../forms/PaymentForm";

const CustomerInvoicePaymentConfirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notifications = useSelector(selectInvoiceNotifications);
  const match = useMatch("/invoice/:id/receipt");

  const details = useSelector(selectInvoiceDetails);

  useEffect(() => {
    // if refreshing or landing for the first time, fetch details
    if (match?.params?.id) {
      dispatch(getCustomerInvoiceDetails(match.params.id));
    }
  }, [dispatch, match?.params?.id]);

  const getNavigation = () => {
    return {
      title: `Payment Confirmation`,
      leftIcon: faChevronLeft,
      leftButtonClick: backToDetailsPage,
    };
  };

  const backToDetailsPage = () => {
    if (match?.params?.id) {
      dispatch(clearNotifications());
      navigate(`/${match?.params?.id}`);
    }
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  let notificationSettings = {
    notifications: notifications,
    timeoutAction,
  };

  const [bannerNotifications] = useNotificationBanner(
    notificationSettings
  );

  const getContent = () => {
    let context = {details: details};

    return (
      <GSForm
        formTitle={<div className="header"><GSButton buttonIcon={faCircleCheck}/>Payment Confirmation</div>}
        formSections={getPaymentConfirmationFormSections(context)}
      ></GSForm>
    );
  };

  const getDrawerActions = () => {
    return {
      actions: [
        {
          name: "Done",
          action: backToDetailsPage,
          type: "grey",
        },
      ],
    }
  };

  return (
    <customer-invoice-payment-confirmation>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawerActions()}
      ></GSSidePanelPage>
    </customer-invoice-payment-confirmation>
  );
};

export default CustomerInvoicePaymentConfirmation;
