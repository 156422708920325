import { Serializer as JSONAPISerializer } from 'jsonapi-serializer'

/**
 * Not all of these methods are being used currently, they are copied over from TMS app.
 * The delay constants are used to timebox notifications
 */
export const shortDelay = 2000;
export const mediumDelay = 5000;
export const longDelay = 10000;
export const extraLongDelay = 10000;

export function getOpts(object, refs) {
    let opts = getAttributes(object)
    if (refs) {
        for (let i = 0; i < refs.length; i++) {
            opts[refs[i].name] = refs[i].opt
        }
    }
    return opts;
}

export function getAttributes(object) {
    return { attributes: Object.getOwnPropertyNames(object).filter((name) => name !== 'id') };
}

export function getErrors(action) {
    let errors = "Data could not be saved"
    if (action?.payload?.errors?.length > 0) {
        errors = action.payload.errors.map(e => `${e.source?.pointer} : ${e.detail ?? e.error}`).join(', ')
    }
    return errors
}

export async function getResponse(method, url, serializedData, thunkAPI){
    try {
        const response = await method(url, serializedData);
        return response;
    }
    catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }
}

export function getSerializedData(data, type, opts){
    const dataSerializer = new JSONAPISerializer(type, opts);
    const serializedData = dataSerializer.serialize(data)
    if(serializedData)
    {
        return serializedData;
    }
    return {}
}

export function getIDRelationship(name) {
    const relationships = [];
    relationships.push({
        name: name,
        opt: { ref: "id", included: true },
    });
    return relationships;
}

export function getAttributeRelationship(name, relationship) {
    const relationships = [];
    relationships.push({
        name: name,
        opt: { ref: "id", included: true, ...getAttributes(relationship) },
    });
    return relationships;
}

//recursive function to add defined serialized properties to the relationship data
export const addSerializedRelationships = (
    serializedData,
    relationshipProperty,
    relationship,
    whitelist
) => {
    if (!Object.isExtensible(serializedData)) {
        return serializedData;
    }

    if (serializedData?.relationships === undefined) {
        serializedData.relationships = {};
    }

    if (Array.isArray(relationship)) {
        let arrayData = {
            data: relationship?.map?.((ai) => {
                let data = getSerializedData(ai, relationshipProperty, getOpts(ai, []))
                    .data;
                let d = Object.getOwnPropertyNames(data.attributes)?.filter?.(
                    (p) =>
                        typeof data?.attributes?.[p] === "object" &&
                        whitelist?.includes?.(p)
                );
                d.forEach((prop) =>
                    addSerializedRelationships(
                        data,
                        prop,
                        data.attributes[prop],
                        whitelist
                    )
                );
                return data;
            }),
        };
        serializedData.relationships[relationshipProperty] = arrayData;
    } else {
        let d = Object.getOwnPropertyNames(relationship)?.filter?.(
            (p) => typeof relationship[p] === "object" && whitelist?.includes?.(p)
        );
        d.forEach((prop) =>
            addSerializedRelationships(
                relationship,
                prop,
                relationship[prop],
                whitelist
            )
        );
        serializedData.relationships[relationshipProperty] = getSerializedData(
            relationship,
            relationshipProperty,
            getOpts(relationship, [])
        );
    }

    return serializedData;
};
