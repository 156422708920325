import React from "react";
import { useSelector } from "react-redux";
import { selectPublicTournament } from "../../reducers/tournamentSlice";
import { GSImage, defaultLayouts } from "golfstatus_react_components";

const TournamentPhotoWidget = () => {
  const tournament = useSelector(selectPublicTournament);
  return (
    <GSImage
      style={{ ...defaultLayouts.fullWidth }}
      src={tournament?.tournamentPromotion?.promotionalPhoto}
      alt="promotional"
    />
  );
};

export default TournamentPhotoWidget;
