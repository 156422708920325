import React, { useState } from "react";
import DonationLineItem from "../events/donation-line-item";
import {
  GSButton,
  GSInput,
  defaultLayouts,
  defaultGapping,
} from "golfstatus_react_components";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePricing,
  selectCompletedOrder,
  selectPublicTournament,
  selectRegistrationOrder,
  selectTournamentPaymentSettings,
} from "../../reducers/tournamentSlice";
import { newDonationOrderItem } from "../../models/Registration";
import { faDollarSign, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../hooks/themeHooks";
import SummaryLineItem from "../events/event/cart/summary-line-item";
import { moneyWithCommas } from "../../helpers/RegexHelper";

const CheckoutDonationWidget = (props) => {
  const { complete } = props;
  const completedOrder = useSelector(selectCompletedOrder);
  const inProgressOrder = useSelector(selectRegistrationOrder);
  const registrationOrder = complete ? completedOrder : inProgressOrder;

  const [customDonation, setCustomDonation] = useState("");
  const dispatch = useDispatch();

  const tournament = useSelector(selectPublicTournament);
  const paymentSettings = useSelector(selectTournamentPaymentSettings);

  const donation = registrationOrder?.donationOrderItems?.find?.(
    (donation) => !donation?.["roundUp"]
  )?.amount;

  const [, theme, getStyle] = useTheme();

  const donate = (amount) => {
    const ru =
      registrationOrder?.donationOrderItems
        ?.filter?.((d) => d?.roundUp)
        ?.map?.((d) => {
          return { round_up: true };
        }) ?? [];
    const update = {
      ...registrationOrder,
      donationOrderItems: [...ru, newDonationOrderItem(amount)],
    };
    dispatch(calculatePricing(update));
  };

  const removeDonation = () => {
    const ru = registrationOrder?.donationOrderItems
      ?.filter?.((d) => d?.roundUp)
      ?.map?.((d) => {
        return { round_up: true };
      });
    const update = {
      ...registrationOrder,
      donationOrderItems: ru,
    };
    dispatch(calculatePricing(update));
  };

  const donationView = () => {
    if (complete){
      return
    }
    if (donation ) {
      return registrationOrder?.registrationOrderItems?.length > 0 ? (
        <GSButton
          title="remove"
          buttonIcon={faTimesCircle}
          type="grey secondary"
          onClick={() => {
            removeDonation();
          }}
        />
      ): null;
    }
    return (
      <div
        style={{
          ...defaultLayouts.vStack.align("flex-start", "center"),
          ...defaultLayouts.fullWidth,
          ...defaultGapping.largeGap,
        }}
      >
        <DonationLineItem
          style={{ ...defaultLayouts.fullWidth }}
          {...tournament}
          {...paymentSettings}
          donationSelected={(amount) => {
            donate(amount);
          }}
        />
        <div
          style={{
            ...defaultLayouts.hStack.align("flex-start", "center"),
            ...defaultLayouts.fullWidth,
            ...defaultGapping.xLargeGap,
          }}
        >
          <GSInput
            textValue={customDonation}
            placeholder="0.00"
            leftIcon={faDollarSign}
            style={{ ...defaultLayouts.fullWidth, ...getStyle(theme.surface) }}
            onChange={(e) => {
              if (!isNaN(e?.target?.value)) setCustomDonation(e?.target?.value);
            }}
          />
          <GSButton
            title="Apply"
            type="black"
            isDisabled={customDonation === ""}
            onClick={() => {
              donate(customDonation);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <SummaryLineItem
      label="Donation"
      description= {complete ? false :  "Optional"}
      value={donation ? moneyWithCommas(donation) : false}
      content={donationView()}
    />
  );
};

export default CheckoutDonationWidget;
