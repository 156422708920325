import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { IntercomProvider } from 'react-use-intercom';

const container = document.getElementById('root');
const root = createRoot(container);

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

root.render(
  // comment out strict mode to avoid double rendering issues during development
  // <React.StrictMode>
  <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
    <Provider store={store}>
      <App />
    </Provider>
  </IntercomProvider>
    
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
