import { defaultLayouts, GSEmptyList, GSItemList, useMediaQuery } from "golfstatus_react_components";
import React from "react";
import { useSelector } from "react-redux";
import { selectTournamentSponsors } from "../../reducers/tournamentSlice";
import SponsorListItem from "../events/event/sponsors/sponsor-list-item";

const AllSponsorsWidget = () => {

  const {isPhone, isTablet} = useMediaQuery()

  const sponsorships = useSelector(selectTournamentSponsors)

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        style={{ ...defaultLayouts.fullWidth }}
        title="Sponsorships"
        detail="Become a sponsor today!"
        actions={[
          {
            title: "Sponsor this tournament",
            type: "black",
            onClick: () => {},
          },
        ]}
      />
    );
  };

  return <GSItemList
                  type="inline-grid x-large-gap"
                  style={{...defaultLayouts.fullWidth}}
                  listStyle={{...defaultLayouts.fullWidth}}
                  gridColumns={isTablet() ? 2 : isPhone() ? 1 : 3}
                  items={sponsorships}
                  listItem={(sponsorship) => <SponsorListItem style={{...defaultLayouts.fullWidth}} {...sponsorship} />}
                  emptyMessage={getEmptyListMessage()}
                />
}

export default AllSponsorsWidget