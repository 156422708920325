import React, { useEffect, useState } from 'react';
import './stripe-card.scss';
import {CardCvcElement, CardExpiryElement, CardNumberElement} from '@stripe/react-stripe-js';
import {GSField} from "golfstatus_react_components";

// The React app using this component must have stripe setup for use,
// as this must be used inside of Stripe's Elements component
const StripeCard = (props) => {

  const {failedValidation, onChange} = props


  const [cardNumber, setCardNumber] = useState(false)
  const [cardExpiry, setCardExpiry] = useState(false)
  const [cardCvc, setCardCVC] = useState(false)

  const onElementChange = (event) => {
    onChange?.()
    setCardNumber(event?.complete)
  }

  const onElementChangeExpiry = (event) => {
    onChange?.()
    setCardExpiry(event?.complete)
  }

  const onElementChangeCVV = (event) => {
    onChange?.()
    setCardCVC(event.complete)
  }

  useEffect(() => {
    if(!cardNumber || !cardExpiry || !cardCvc){
      failedValidation?.()
    }
  })

  const style = {
    style: {
      base: {
        '::placeholder': {
          color: 'rgba(35,35,35,.35)',
        }
      },
      complete: {

      },
      empty: {

      },
      invalid: {

      },
    }
  };

  return (
    <stripe-card>
      <GSField
        customView={true}
        isEditable={true}
        label='Card Number'
        required={true}
        value={<CardNumberElement onChange={onElementChange} options={style} />}
      />
      <div className='row'>
        <GSField
          customView={true}
          isEditable={true}
          label='Expiration Date'
          required={true}
          value={<CardExpiryElement onChange={onElementChangeExpiry} options={style} />}
        />
        <GSField
          customView={true}
          isEditable={true}
          label='CVV'
          required={true}
          value={<CardCvcElement onChange={onElementChangeCVV} options={style} />}
        />
      </div>
    </stripe-card>
  );
};

export default StripeCard;
