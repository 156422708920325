import { faPen } from "@fortawesome/free-solid-svg-icons";
import {
  GSButton,
  GSInfoGroup,
  defaultLayouts,
} from "golfstatus_react_components";
import React from "react";
import { useNavigate } from "react-router-dom";

export const navigateToLogin = () => {
  window.location.href = process.env.REACT_APP_GOLFSTATUS_LOGIN_URL;
}

/**
 * View to display a section of a page with a bottom divider
 *
 * @typedef Action
 * @type {object}
 *
 * @typedef Properties
 * @type {object}
 * @property {object} style the style for the component
 * @property {object} context context for the form
 *
 * @param {Properties} props style
 */

const UserCheckoutInfo = (props) => {
  const { style } = props;
  //sign in

  const navigate = useNavigate();

  const checkoutAsGuest = () => {
    navigate("customer");
  };

  const expressSection = {
    gap: "medium-gap",
    sectionItems: [
      { type: "headline-2", value: "Add Your Contact Details" },
      {
        type: "secondary",
        value:
          "If you have an account already, sign in and we’ll auto-fill it for you.",
      },
    ],
  };
  const signInSection = {
    type: 'mobile-stretch',
    sectionItems: [
      {
        type: "primary full-width",
        value: (
          <GSButton
          style={{flexGrow: 1}}
            type={"grey"}
            title="Sign In"
            onClick={() => {
              window.location.href = process.env.REACT_APP_GOLFSTATUS_LOGIN_URL;
            }}
          />
        ),
      },
    ],
  };

  const guesSection = {
    type: 'mobile-stretch',
    sectionItems: [
      {
        type: "primary full-width",
        value: (
          <GSButton
          style={{flexGrow: 1}}
            type="orange"
            title="Add Details"
            buttonIcon={faPen}
            onClick={() => {
              checkoutAsGuest?.();
            }}
          />
        ),
      },
    ],
  };

  const expressColumn = {
    fit: "stretch",
    sections: [expressSection],
  };
  const signInColumn = {
     type: 'mobile-stretch',
    sections: [signInSection],
  };
  const guestColumn = {
    type: 'mobile-stretch',
    sections: [guesSection],
  };
  const expressData = [expressColumn,guestColumn, signInColumn, ];

  return (
    <user-checkout-info style={style}>
      <GSInfoGroup
        listType="mobile-vertical"
        style={{ ...defaultLayouts.fullWidth }}
        dataGroups={expressData}
      />
    </user-checkout-info>
  );
};

export default UserCheckoutInfo;
