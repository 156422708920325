import { faPen } from "@fortawesome/free-solid-svg-icons";
import { GSButton, GSCircleImage, GSInfoGroup } from "golfstatus_react_components";
import React from "react";

/**
 * Donation info group.
 * 
 * @typedef Properties
 * @type {object}
 * @property {string} style the style for this component
 *
 * @param {Properties} props
 */

export const defaultAvatar = "https://api.golfstatus.dev/images/user_round.png";

const CustomerLineItem = (props) => {

  const {style, avatar, firstName, lastName, phone, email, editClick, nameStyle} = props
  
  const avatarSection = {
    sectionItems: [
      {
        type: "secondary no-wrap full-width",
        value: <GSCircleImage size="x-large"  url={avatar ?? defaultAvatar}/>,
      },
    ]
  }

  const customerInfoSection = {
    sectionItems: [
      {
        type: "headline-2 no-wrap full-width",
        sectionItemStyle: nameStyle,
        value: `${firstName} ${lastName}`,
      },
      {
        type: "secondary no-wrap full-width",
        value: `${email}`,
      },
      {
        type: "secondary no-wrap full-width",
        value: `${phone ?? ""}`,
      },
    ]
  }

  const editSection = {
    sectionItems: [
      {
        type: "no-wrap full-width",
        value: <GSButton style={{marginRight: "4px"}} buttonIcon={faPen} onClick={() => {editClick?.()}}/>,
      },
    ]
  }

  const mainColumn ={
    fit: "stretch",
    sections: [
      avatarSection,
      customerInfoSection
    ],
  }

  const editColumn = {
    sections: [
      editSection
    ]
  }

  const getGroup = () => {
    let group = [mainColumn]
    if(editClick){
      group.push(editColumn)
    }
    return group
  }

  return <customer-line-item style={style}>
    <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
  </customer-line-item>
}


export default CustomerLineItem