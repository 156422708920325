import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import { baseURL, thunkResponse } from "../app/api";

export const fileUplaod = `${baseURL}/file`;

export const utilitySlice = createSlice({
  name: "utilities",
  initialState: {
    uploadedFile: "",
    croppedImage: {},
    croppedTarget: {},
    notifications: [],
    loading: []
  },
  reducers: {
    setUploadedFile: (state, action) => {
      state.uploadedFile = action.payload;
    },
    setCroppedImage: (state, action) => {
      state.croppedImage = action.payload;
    },
    setCroppedTarget: (state, action) => {
      state.croppedTarget = action.payload;
    },
    notifySlideOut: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearUtility: (state, action) => {
      state.uploadedFile = "";
      state.croppedImage = {};
      state.croppedTarget = {};
      state.notifications = [];
    },
    clearUtilityNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    thunkResponse(
      builder,
      uploadFile,
      (state, action) => {
        state.uploadedFile = action.payload.data.location;
      },
    )
  }
});

export const uploadFile = createAsyncThunk(
  `${utilitySlice.name}/uploadFile`,
  async (file, thunkAPI) => {
    const response = await gsApi.post(`${fileUplaod}`, file);
    return response;
  }
);

export const {
  setUploadedFile,
  setCroppedImage,
  setCroppedTarget,
  notifySlideOut,
  clearUtilityNotifications,
  clearUtility,
} = utilitySlice.actions;

export const selectCroppedTarget = (state) => state.utilities.croppedTarget;

export const selectUploadedFile = (state) => state.utilities.uploadedFile;

export const selectUtilityNotifications = (state) =>
  state.utilities.notifications;

export default utilitySlice.reducer;
