import axios from "axios";
import Base64 from "crypto-js/enc-base64";
import MD5 from "crypto-js/md5";
import HmacSHA1 from "crypto-js/hmac-sha1";
import { Deserializer as JSONAPIDeserializer } from "jsonapi-serializer";

import { getCurrentUser } from "../app/gs-session";

const moment = require("moment-timezone");
const $ = require("jquery");

const gsSessionUser = getCurrentUser();

// You can use any cookie library or whatever
// library to access your client storage.
//import cookie from 'cookie-machine';
const gsApi = axios.create({});

gsApi.interceptors.request.use(
  function(config) {
    //  config.headers.Authorization = `Bearer ${token}`;

    const spoofUserToken = process.env.REACT_APP_GOLFSTATUS_TOKEN;
    const anonymousEmail = process.env.REACT_APP_GOLFSTATUS_EMAIL;

    let type = config.method.toUpperCase();
    let contentType = type === "GET" ? "" : "application/vnd.api+json";
    let contentMd5 = Base64.stringify(MD5(JSON.stringify(config.data)));
    let url = new URL(config.url);
    let timestamp = new Date().toUTCString();

    let params = "";

    if (type === "GET" && config.url.includes("?")) {
      contentType = "application/vnd.api+json";
      config.data = {};
    }

    if (config.data && type === "GET" && !(config.data instanceof FormData)) {
      contentType = "application/vnd.api+json";
      params = $.param(config.data);
      if (params && params !== null && params !== "") {
        params = "?" + params.replace(/'/g, "%27");
      }
    }

    if (
      config.data &&
      type === "POST" &&
      config.data &&
      config.data instanceof FormData
    ) {
      contentType = `multipart/form-data`;
      if (config.data.keys() && config.data.keys().next().value === "blob") {
        //config.headers['processData'] = false;
      }
    }

    let requestUri = url.pathname + params;
    let finalURL = config.url + params;
    config.url = finalURL;

    if (type !== "POST" && type !== "PUT" && type !== "PATCH") {
      contentMd5 = Base64.stringify(MD5(""));
    }

    // load user info from cookie
    let golfstatusToken = gsSessionUser?.golfstatusToken ?? spoofUserToken;
    let email = gsSessionUser?.email ?? anonymousEmail;

    let signatureString = `${type},${contentType},${contentMd5},${requestUri},${timestamp}`;

    let hmac = null;
    let authString = null;
    if (email && golfstatusToken) {
      hmac = new HmacSHA1(signatureString, golfstatusToken);
      authString = "APIAuth-HMAC-SHA1 " + email + ":" + Base64.stringify(hmac);
    }

    let headers = config.headers || {};
    headers["Content-MD5"] = contentMd5;
    headers["Timestamp"] = timestamp;
    headers["UserTimeZone"] = moment.tz.guess();
    if (authString) {
      headers["Authorization"] = authString;
    }
    headers["Content-Type"] = contentType;
    headers["Accept"] = "application/json, text/javascript, */*; q=0.01";
    config.headers = headers;

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

gsApi.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.data) {
      return new Promise(function(resolve, reject) {
        const UserSerializer = new JSONAPIDeserializer({
          keyForAttribute: "camelCase",
          ...getRelationshipData(response),
        });
        let d = {};
        if (response?.config?.url?.endsWith?.("validate-teams-import")) {
          resolve(response.data);
        } else if (
          response?.config?.url?.endsWith?.("validate-scorecards-import")
        ) {
          resolve(response.data);
        } else {
          if (response?.config?.url?.endsWith?.("calculate-order-pricing")) {
            let donationOrderItems = response?.data?.included
              ?.filter?.(
                (included) => included?.type === "donation-order-items"
              )
              ?.map?.((i) => i?.attributes);
            response.data.data.attributes.donationOrderItems = donationOrderItems;
          }
          let data = response?.data


          if (response?.config?.url?.endsWith?.("package-item")) {
            data  = {...response.data, data: {...response.data.data, attributes: {...response?.data?.data?.attributes, responseType: response?.data?.data?.type}}} 
          }
           

          d = UserSerializer.deserialize(data);
        }
        resolve(d);
      });
    } else {
      return response;
    }
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

const getRelationshipData = (response) => {
  let relationships = {
    "live-tournament-rounds": {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    },
    "leaderboard-type-settings": {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    },
    "tournament-flights": {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    },
    "access-groups": {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    },
  };

  if (response?.config?.url?.endsWith("round-hole-assignments")) {
    relationships["tournament-teams"] = {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    };
  }

  if (response?.config?.url?.endsWith("live-tournament-scorecards")) {
    relationships["tournament-rounds"] = {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    };
  }

  if (response?.config?.url?.includes?.("live-tournament-rounds")) {
    relationships["tournament-teams"] = {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    };
    relationships["players"] = {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    };
  }

  if (response?.config?.url?.includes?.("round-hole-assignment")) {
    relationships["tournament-teams"] = {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    };
  }

  if (response?.config?.url?.includes?.("tournament-flights")) {
    relationships["tournament-teams"] = {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    };
  }

  if (response?.config?.url?.includes?.("round-tee-choices")) {
    relationships["round-tees"] = {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    };
    relationships["round-courses"] = {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    };
  }
  if (response?.config?.url?.includes?.("round-tee-player-choices")) {
    relationships["round-tees"] = {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    };
    relationships["round-courses"] = {
      valueForRelationship: function(relationship) {
        return {
          id: relationship.id,
        };
      },
    };
  }
  return relationships;
};

export default gsApi;
