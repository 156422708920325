import {
  faArrowDown,
  faExclamationCircle,
  faFilter,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import {
  GSListPage,
  GSEmptyList,
  defaultPadding,
  defaultLayouts,
  useFormDataValidation,
  GSPageSection,
  golfstatusColors,
} from "golfstatus_react_components";
import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  getTournamentPaymentSettings,
  selectNotifications,
  selectRegistrationOrder,
} from "../../../../reducers/tournamentSlice";
import "./event-cart-checkout.scss";
import {
  useHeaderStyle,
  useSectionStyles,
  useTheme,
} from "../../../../hooks/themeHooks";
import CartStepItem from "./cart-step-item";
import { getCurrentUser } from "../../../../app/gs-session";
import {
  getOrderItemCount,
  getRegistrationSummary,
} from "../../../../models/Registration";
import { getUserPaymentOptions } from "../../../../reducers/userSlice";
import {
  getActivePaymentMethods,
  isBankAttached,
  isCardAttached,
} from "../../../../forms/CartSections";
import { getRouting } from "../../../../helpers/Converters";
import {
  ORDER_ID,
  TOURNAMENT_ID,
  eventBaseRoute,
} from "../../../../routes/event";
import { moneyWithCommas } from "../../../../helpers/RegexHelper";
import {
  useNotificationAction,
  useNotificationBanner,
} from "../../../../hooks/notificationHooks";
import PageHeader from "../../../page-header";
import PaymentWidget from "../../../widgets/payment-widget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigationButtons } from "../../../../hooks/pageHooks";
import PaymentSelectWidget from "../../../widgets/payment-select-widget";
import PaymentDetailsWidget from "../../../widgets/payment-details-widget";
import CheckoutItemsWidget from "../../../widgets/checkout-items-widget";
import CheckoutDiscountWidget from "../../../widgets/checkout-discount-widget";
import CheckoutDonationWidget from "../../../widgets/checkout-donation-widget";
import CheckoutFeesWidget from "../../../widgets/checkout-fees-widget";
import CheckoutRoundUpWidget from "../../../widgets/checkout-round-up-widget";
import CheckoutTotalWidget from "../../../widgets/checkout-total-widget";
import PageFooter from "./page-footer";
import { usePermissions } from "../../../../hooks/permissionHooks";
import { useCustomer, useLoading, useTournament } from "../../../../hooks/tournamentHooks";
import InternationalFeeWidget from "../../../widgets/international-fee-widget";
import CartContactWidget from "../../../widgets/cart-contact-widget";

const EventCartCheckout = (props) => {
  const { style } = props;
  const navigate = useNavigate();

  const route = getRouting(eventBaseRoute, [
    TOURNAMENT_ID,
    "cart",
    ORDER_ID,
    "checkout",
    "*",
  ]);
  const match = useMatch(route);

  const tournament = useTournament();
  const tournamentID = tournament?.id;

  const dispatch = useDispatch();
  const registrationOrder = useSelector(selectRegistrationOrder);
  const {customer, customerComplete} = useCustomer();
  const [, theme, getStyle] = useTheme();
  const [user, setUser] = useState({});

  const { headerStyle } = useHeaderStyle();

  const bodyStyle = { ...getStyle(theme.surfaceVariant) };
  const sectionStyle = {
    ...getStyle(theme.outlineVariant),
    ...defaultLayouts.fullWidth,
  };

  const { headerSectionStyle } = useSectionStyles();

  const permissions = usePermissions();

  const [isLoading] = useLoading("getPublicTournamentDetails","getUserPaymentOptions","selectUser")

  const totalCostWithFees =
    registrationOrder?.totalCostWithFees !== ""
      ? registrationOrder?.totalCostWithFees
      : "0.0";
  const freeRegistration = (registrationOrder?.totalCost ?? 0) === 0;

  const currentPaymentMethod = getActivePaymentMethods(permissions).find?.(
    (m) => m.type === registrationOrder?.paymentType
  );

  useEffect(() => {
    const user = getCurrentUser();
    if (user?.uid) {
      dispatch(getUserPaymentOptions(user.uid));
    } else {
      // TODO: call to action to invite the user to log in if they have saved payment methods
    }
  }, [dispatch]);

  const [context] = useFormDataValidation({
    initialValid: false,
    setData: (data) => {
      setUser(data);
    },
    data: user,
  });

  const notifications = useSelector(selectNotifications)?.filter?.(
    (n) => n.id === "createAndPay" || n.id === "applyDiscount"
  );

  useNotificationAction(
    "fulfilled",
    notifications,
    () => {
      navigate("receipt");
    },
    "createAndPay"
  );

  context.checkoutAsGuest = () => {
    navigate("customer");
  };

  const getPageActions = useNavigationButtons("checkout");

  useEffect(() => {
    if (tournamentID) {
      dispatch(getTournamentPaymentSettings(tournamentID));
    }
  }, [dispatch, tournamentID]);

  const getSearchActions = () => {
    return [
      {
        id: 1,
        type: "mobile-icon",
        actionIcon: faFilter,
      },
    ];
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="Cart"
        detail="There is currently nothing in your cart..."
        actions={
          permissions.packagesEnabled()
            ? [
                {
                  title: "View Packages",
                  type: "grey",
                  onClick: () => {
                    navigate(
                      getRouting(eventBaseRoute, [tournamentID, "packages"])
                    );
                  },
                },
              ]
            : []
        }
        style={{ ...defaultLayouts.fullWidth, ...defaultPadding.emptyListPad }}
      />
    );
  };

  const isSidePanelOpen = () => {
    return match?.params?.["*"] !== "";
  };

  const getRouter = () => {
    return <Outlet />;
  };

  const getNextStep = () => {
    if (!customerComplete()) {
      return {
        nextStep: "Add Contact Details",
        icon: (
          <FontAwesomeIcon
            style={{ ...theme.error.light }}
            icon={faExclamationCircle}
          />
        ),
        actions: [
          {
            title: "Add Details",
            buttonIcon: faPen,
            type: "orange",
            onClick: () => {
              navigate("customer");
            },
          },
          {
            title: "sign in",
            type: "grey",
            onClick: () => {
              window.location.href = process.env.REACT_APP_GOLFSTATUS_LOGIN_URL;
            },
          },
        ],
      };
    }
    if (currentPaymentMethod?.id === 1 && !freeRegistration) {
      if (!isCardAttached(registrationOrder)) {
        return {
          nextStep: "Please add a card",
          icon: (
            <FontAwesomeIcon
              style={{ color: golfstatusColors.orange400 }}
              icon={faExclamationCircle}
            />
          ),
          actions: [
            {
              title: `Select Card`,
              type: " orange",
              onClick: () => {
                navigate("card");
              },
            },
          ],
        };
      }
    }
    if (currentPaymentMethod?.id === 2) {
      if (!isBankAttached(registrationOrder)) {
        return {
          nextStep: "Please add a Bank Account",
          actions: [
            {
              title: `Select Account`,
              type: " orange",
              onClick: () => {
                navigate("bank");
              },
            },
          ],
        };
      }
    }
    if (currentPaymentMethod?.id === 4) {
      if ((customer?.firstName ?? "") === "") {
        return {
          nextStep: "Please add your information",
          actions: [
            {
              title: `Edit Info`,
              type: "orange",
              onClick: () => {
                navigate("customer");
              },
            },
          ],
        };
      }
    }
    return {
      nextStep: "Review & Finalize Order",
      actions: [
        {
          title: `Scroll to review`,
          rightIcon: faArrowDown,
          type: "secondary",
          onClick: () => {},
        },
      ],
    };
  };

  const payLabel = () => {
    if ([1, 2].includes(currentPaymentMethod?.id)) {
      return "Pay Now";
    } else {
      return "Confirm Order";
    }
  };

  const totalCanRoundUp = () => {
    if (
      registrationOrder?.totalCostWithFees?.endsWith?.(".0") &&
      !registrationOrder?.roundUpToggle
    ) {
      return false;
    }
    return true;
  };

  const summarySections = () => {
    const summary = [<CartContactWidget />];
    const hasOrderItems = registrationOrder?.registrationOrderItems?.length > 0;
    if (hasOrderItems) {
      summary.push(<CheckoutItemsWidget />);
    }
    if (
      permissions.allowDiscountCodes &&
      permissions.packagesEnabled() &&
      hasOrderItems
    ) {
      summary.push(<CheckoutDiscountWidget />);
    }
    if (permissions.donationsActive()) {
      summary.push(<CheckoutDonationWidget />);
    }
    if (permissions.feesEnabled()) {
      summary.push(<CheckoutFeesWidget />);
    }
    if (registrationOrder?.internationalCardUsed) {
      summary.push(<InternationalFeeWidget />);
    }
    if (
      permissions.canRoundUp &&
      hasOrderItems &&
      permissions.donationsActive() &&
      totalCanRoundUp()
    ) {
      summary.push(<CheckoutRoundUpWidget />);
    }
    summary.push(<CheckoutTotalWidget />);

    return summary;
  };

  const getItemList = () => {
    if (
      registrationOrder?.registrationOrderItems?.length < 1 &&
      registrationOrder?.donationOrderItems?.length < 1
    ) {
      return (
        <div style={{ ...defaultLayouts.vStack }}>
          <PageHeader header="Payment & Review" />
          {getEmptyListMessage()}
        </div>
      );
    }
    return (
      <div style={{ ...defaultLayouts.vStack }}>
        <PageHeader header="Payment & Review" />
        {!freeRegistration ? (
          <GSPageSection
            {...headerSectionStyle}
            body={[<PaymentSelectWidget />, <PaymentDetailsWidget />]}
          />
        ) : null}

        <GSPageSection
          title="Review Order"
          style={sectionStyle}
          bodyStyle={bodyStyle}
          description={`Give your order one last glance. Tapping “${payLabel()}” will complete this order.`}
          body={summarySections()}
        />
        <GSPageSection
          title="Finalize Order"
          style={sectionStyle}
          bodyStyle={bodyStyle}
          body={[<PaymentWidget />]}
        />
        <PageFooter showFooter={true}>
          <CartStepItem
            itemCount={getOrderItemCount(registrationOrder)}
            total={moneyWithCommas(totalCostWithFees)}
            description={getRegistrationSummary(
              registrationOrder,
              moneyWithCommas(totalCostWithFees),
              "Total",
              permissions.feesEnabled()
            )}
            {...getNextStep()}
            style={{
              ...defaultLayouts.vStack,
              ...defaultLayouts.fullWidth,
              maxWidth: "1080px",
            }}
          />
        </PageFooter>
      </div>
    );
  };

  const [banner] = useNotificationBanner({
    notifications,
    timeoutAction: () => {
      dispatch(clearNotifications());
    },
  });

  const getBanner = () => {
    return banner;
  };

  return (
    <event-cart-checkout style={{ ...style }}>
      <GSListPage
        title=""
        getBanner={notifications?.length > 0 ? getBanner : false}
        getPageActions={getPageActions}
        getSearchActions={getSearchActions}
        getItemList={getItemList}
        isSidePanelOpen={isSidePanelOpen}
        getRouter={getRouter}
        headerStyle={headerStyle}
        loading={isLoading}
      ></GSListPage>
    </event-cart-checkout>
  );
};

export default EventCartCheckout;
