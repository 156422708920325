import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gsApi from "../auth/auth";
import { baseURL, getThunkResponse } from "../app/api";
import { getResponse } from "../helpers/JSONapi";
import { getRouting } from "../helpers/Converters";

export const tournmanetsURL = `${baseURL}/tournaments`;

export const tournaemntsRoute = (nested) => {
  return getRouting(tournmanetsURL, nested);
};

//replace all occurences of template with the name of your new slice
export const leaderboardSlice = createSlice({
  name: "leaderboards",
  initialState: {
    leaderboards: [],
    flights: [],
    favoriteTeams: [],
    leaderboard: {},
    notifications: [],
    loading: [],
  },
  //be sure to export any reducers on line 65
  reducers: {
    setLeaderboard: (state, action) => {
      state.leaderboard = action.payload;
    },
    setFavorites: (state, action) => {
      state.favoriteTeams = action.payload ?? []
    },
    addFavorite: (state, action) => {
      const favorites = [...state.favoriteTeams, action.payload]
      state.favoriteTeams = favorites
      localStorage.setItem("favoriteTeams", JSON.stringify(favorites))
    },
    removeFavorite: (state, action) => {
      const favorites = state.favoriteTeams?.filter?.(t => t !== action.payload)
      state.favoriteTeams = favorites
      localStorage.setItem("favoriteTeams", JSON.stringify(favorites))
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    clearNotifications: (state, action) => {
      state.notifications = [];
    },
  },
  extraReducers: (builder) => {
    getThunkResponse(builder, getPublicLeaderboards, (state, action) => {
      state.leaderboards = action.payload;
    });
    getThunkResponse(builder, getLeaderboardFlights, (state, action) => {
      state.flights = action.payload;
    });
  },
});

export const getPublicLeaderboards = createAsyncThunk(
  `${leaderboardSlice.name}/getPublicLeaderboards`,
  async (tournamentID, thunkAPI) => {
    const response = await getResponse(
      gsApi.get,
      tournaemntsRoute([tournamentID, "public-leaderboards"]),
      {},
      thunkAPI
    );
    return response;
  }
);

export const getLeaderboardFlights = createAsyncThunk(
  `${leaderboardSlice.name}/getLeaderboardFlights`,
  async (tournamentID, thunkAPI) => {
    const response = await getResponse(
      gsApi.get,
      tournaemntsRoute([tournamentID, "tournament-flights"]),
      {},
      thunkAPI
    );
    return response;
  }
);

export const {
  setLeaderboard,
  setFavorites,
  addFavorite,
  removeFavorite,
  addNotification,
  clearNotifications,
} = leaderboardSlice.actions;

//export any selectors needed
export const selectLeaderboards = (state) =>
  state?.[leaderboardSlice.name]?.leaderboards;

export const selectFlights = (state) => state?.[leaderboardSlice.name]?.flights;

export const selectLeaderboard = (state) =>
  state?.[leaderboardSlice.name]?.leaderboard;

export const selectFavoriteTeams = (state) => state?.[leaderboardSlice.name]?.favoriteTeams

export const selectNotifications = (state) =>
  state?.[leaderboardSlice.name]?.notifications;

export const selectLoading = (state) => state?.[leaderboardSlice.name]?.loading;

export default leaderboardSlice.reducer;

//ADD reducer to store.js
