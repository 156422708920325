import React, { useEffect, useMemo } from "react";
import {
  GSItemList,
  GSSidePanelPage,
  defaultPadding,
  defaultLayouts,
  defaultBorders,
  GSEmptyList,
  defaultGapping,
  defaultTypography,
  } from "golfstatus_react_components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useMatch, useNavigate } from "react-router-dom";

//import scss
import "./round-hole-assignments.scss";
import { getRouting } from "../../../../helpers/Converters";
import {
  ROUND_ID,
  TOURNAMENT_ID,
  eventBaseRoute,
  getProp,
} from "../../../../routes/event";
import { useDispatch, useSelector } from "react-redux";
import {
  getRoundHoleAssignments,
  getTournamentTeams,
  selectHoleAssignments,
  selectLoading,
  selectTournamentRound,
  selectTournamentTeams,
} from "../../../../reducers/tournamentSlice";
import TeamLineItem from "../teams/team-line-item";
import { useTheme } from "../../../../hooks/themeHooks";
import { getDate,  getTime } from "../../../../helpers/Dates";
import PageHeader from "../../../page-header";
import { useTournament } from "../../../../hooks/tournamentHooks";

//Name the component
const RoundHoleAssignments = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [, theme, getStyle] = useTheme();

  const match = useMatch(
    getRouting(eventBaseRoute, [
      TOURNAMENT_ID,
      "rounds",
      ROUND_ID,
      "hole-assignments",
    ])
  );
  const roundID = getProp(match?.params, ROUND_ID);

  const tournament = useTournament();
  const tournamentID = tournament?.id;

  const holeAssignments = useSelector(selectHoleAssignments);
  const tournamentTeams = useSelector(selectTournamentTeams);
  const currentRound = useSelector(selectTournamentRound);
  const loading = useSelector(selectLoading);

  useEffect(() => {
    dispatch(getRoundHoleAssignments(roundID));
  }, [dispatch, roundID]);

  useEffect(() => {
    dispatch(getTournamentTeams(tournamentID));
  }, [dispatch, tournamentID]);

  const leftNavigation = () => {
    navigate(-1);
  };

  const getNavigation = () => {
    //Add Title to your component
    return {
      title: `Round ${currentRound.roundName ?? currentRound.number} Hole Assignments`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const getEmptyListMessage = () => {
    return (
      <GSEmptyList
        title="No Hole Assignments Yet"
        detail="Please check back closer to the event date for starting hole assignments."
        actions={[
          {
            title: `Round ${currentRound.roundName ?? currentRound.number} Details`,
            type: "grey",
            onClick: () => {navigate(-1)},
          },
        ]}
      />
    );
  };

  const teamsWithAssignments = useMemo(() => {
    return tournamentTeams.map?.(tt => {
      return {
        ...tt,
        holeAssignment: holeAssignments?.find?.((ha) =>
          ha.tournamentTeams?.some(h => h.id === tt?.id)
        )
      }
    }).filter?.(twa => !!twa.holeAssignment)?.sort?.((a,b) => a.holeAssignment?.hole - b.holeAssignment?.hole)
  }, [tournamentTeams, holeAssignments])

  const getHoleAssignment = (team) => {
    const {holeAssignment} = team
    return (
      <div style={{...defaultLayouts.vStack}}>
        <div>
          <div style={{...defaultTypography.body}}>
            Hole {holeAssignment?.hole} {holeAssignment?.position} - {getTime(holeAssignment?.teeTime)}
          </div>
        </div>
        <div style={{...defaultTypography.bodyRegular, opacity: .7}}>{getDate(holeAssignment?.teeTime)}</div>
      </div>
    );
  };

  const getContent = () => {
    return (
      <gs-form>
        <PageHeader header={`Round ${currentRound.roundName ?? currentRound.number} Hole Assignments`}/>
        <GSItemList
          type="vertical medium-large-gap"
          style={{...defaultLayouts.fullWidth, ...defaultPadding.xLargePad.apply("horizontal"), ...defaultPadding.smallLayoutPad.apply("vertical")}}
          items={teamsWithAssignments}
          listItem={(team) => (
            <div style={{ ...defaultPadding.xLargePad,
              ...getStyle(theme.surfaceVariant),
              ...defaultLayouts.fullWidth,
              ...defaultBorders.mediumLargeBorderRadius, ...defaultGapping.largeGap, ...defaultLayouts.vStack }}>
              {getHoleAssignment(team)}
              <TeamLineItem
                style={{
                  ...defaultLayouts.fullWidth,
                }}
                {...team}
              />
            </div>
          )}
          emptyMessage={getEmptyListMessage()}
          loading={loading.includes?.("getTournamentTeams")}
          loadingMainText="Loading Teams..."
          loadingSubtext="please wait."
        />
      </gs-form>
    );
  };

  return (
    //name the component tag
    <round-hole-assignments>
      <GSSidePanelPage
        header={getNavigation()}
        content={getContent()}
      ></GSSidePanelPage>
    </round-hole-assignments>
  );
};

//export the new namet
export default RoundHoleAssignments;
