import { GSInfoGroup } from "golfstatus_react_components";
import React from "react";
import { moneyWithCommas } from "../../../../helpers/RegexHelper";

/**
 * Abbreviated order item designed for checkout page
 * 
 * @typedef Properties
 * @type {object}
 * @property {string} style the style for this component
 *
 * @param {Properties} props
 */

const AbbreviatedOrderItem = (props) => {

  const {quantity, tournamentPackage, style} = props
  const {name, cost} = tournamentPackage ?? {}
  
  const nameSection = {
    sectionItems: [
      {
        type: "body full-width",
        value: name,
      },
    ]
  }

  const costSection = {
    style: {alignItems: "flex-end"},
    sectionItems: [
      {
        type: "primary",
        value: moneyWithCommas(cost * quantity)
      },
      {type: "secondary no-wrap "
        ,value: `(${quantity} x ${moneyWithCommas(cost)})`
      }
    ]
  }

  const nameColumn ={
    fit: "stretch",
    sections: [
      nameSection,
    ],
  }

  const costColumn = {
    sections: [
      costSection
    ]
  }

  const getGroup = () => {
    let group = [nameColumn, costColumn]
    return group
  }

  return <abbreviate-order-item style={style}>
    <GSInfoGroup dataGroups={getGroup()}></GSInfoGroup>
  </abbreviate-order-item>
}


export default AbbreviatedOrderItem