import React, { useEffect, useMemo } from "react";
import "./customer-invoice-pay.scss";
import {
  GSCaptchaCheckbox,
  GSForm,
  GSSidePanelPage,
} from "golfstatus_react_components";
import { faChevronLeft, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import {
  checkInternationalCard,
  clearNotifications,
  getCustomerInvoiceDetails,
  payCustomerInvoice,
  selectBillingAddress,
  selectCaptchaData,
  selectInvoiceDetails,
  selectInvoiceLoading,
  selectInvoiceNotifications,
  selectPaymentDetails,
  selectSelectedPaymentOption,
  setBillingAddress,
  setCaptchaToken,
  setPaymentDetails,
  setSelectedPaymentOption,
} from "../../reducers/invoiceSlice";
import {
  getUserPaymentOptions,
  selectUserPaymentOptions,
} from "../../reducers/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import {
  getSaveBannerActions,
  useNotificationBanner,
} from "../../hooks/notificationHooks";
import { getPaymentFormSections } from "../../forms/PaymentForm";
import {
  useElements,
  useStripe,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { useFormValidation } from "../../hooks/formHooks";
import { selectLoggedInUser, selectTheme } from "../../reducers/appSlice";
import { useTheme } from "../../hooks/themeHooks";

const CustomerInvoicePay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const match = useMatch("/invoice/:id/pay");
  const billingAddress = useSelector(selectBillingAddress);
  const captchaData = useSelector(selectCaptchaData);
  const details = useSelector(selectInvoiceDetails);
  const notifications = useSelector(selectInvoiceNotifications);
  const loading = useSelector(selectInvoiceLoading);
  const paymentDetails = useSelector(selectPaymentDetails);
  const savedPaymentOptions = useSelector(selectUserPaymentOptions);
  const selectedPaymentOption = useSelector(selectSelectedPaymentOption);
  const loggedInUser = useSelector(selectLoggedInUser);
  const mode = useSelector(selectTheme);

  // form context
  const [context, , setIsValid] = useFormValidation(false);
  context.billingAddress = billingAddress;
  context.captchaToken = captchaData.captcha_token;
  context.details = details;
  context.paymentDetails = paymentDetails;
  context.selectedPaymentOption = selectedPaymentOption;
  context.loading = loading;

  const [, theme, getStyle] = useTheme();

  const totalStyle = getStyle(theme.secondaryContainer);

  context.totalStyle = { ...totalStyle };

  context.mode = mode;

  context.getTotalCostWithFees = () => {
    if (details?.registrationOrder?.totalCostWithFees) {
      return details?.registrationOrder?.totalCostWithFees;
    }
    return details?.registrationOrder?.purchaseCost + details?.registrationOrder?.totalFees;
  };

  context.onBillingAddressChange = (value, field) => {
    setIsValid(true);
    let updated = { ...billingAddress };
    // API expects a two character country code
    updated[field] = value === "USA" ? "US" : value;
    dispatch(setBillingAddress(updated));

    if (paymentDetails?.token && field === 'country') {
      // international card check
      const update = {
        ...details.registrationOrder,
        tournament: details.tournament,
        donationOrderItems: details.donationOrderItems,
        registrationOrderItems: details.registrationOrderItems.map((item) => {
          const tp = details.packages.find(p => p.id === item.tournamentPackageId);
          return {
            ...item,
            tournamentPackage: {
              id: tp?.tournamentPackageKey,
            },
          };
        }),
        paymentOptionKey: null,
        globalToken: null,
        stripeToken: paymentDetails?.token,
        cardName: `${paymentDetails?.firstName} ${paymentDetails?.lastName}`,
        last4: paymentDetails?.last4,
        brand: paymentDetails?.brand,
        paymentType: "credit_card",
      };

      dispatch(
        checkInternationalCard({
          registrationOrder: update,
          cardInfo: {
            cardCountryCode: updated?.country,
            organizationKey: details.organization.organizationKey,
          },
        })
      );
    }
  };

  context.onPaymentDetailsChange = (details) => {
    setIsValid(true);
    dispatch(setPaymentDetails(details));
  };

  context.onCreditCardChange = async (value) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded
      return;
    }

    const card = elements.getElement(CardNumberElement);
    if (card) {
      const result = await stripe.createToken(card);
      if (result.token) {
        const updatedPaymentDetails = {
          ...paymentDetails,
          token: result.token?.id,
          last4: result.token?.card?.last4,
          brand: result.token?.card?.brand,
          registrantCoversFees: context.isRegistrantCoveringFees(),
        };
        dispatch(setPaymentDetails(updatedPaymentDetails));

        if (billingAddress?.country) {
          // international card check
          const update = {
            ...details.registrationOrder,
            tournament: details.tournament,
            donationOrderItems: details.donationOrderItems,
            registrationOrderItems: details.registrationOrderItems.map((item) => {
              const tp = details.packages.find(p => p.id === item.tournamentPackageId);
              return {
                ...item,
                tournamentPackage: {
                  id: tp?.tournamentPackageKey,
                },
              };
            }),
            paymentOptionKey: null,
            globalToken: null,
            stripeToken: updatedPaymentDetails?.token,
            cardName: `${updatedPaymentDetails?.firstName} ${updatedPaymentDetails?.lastName}`,
            last4: updatedPaymentDetails?.last4,
            brand: updatedPaymentDetails?.brand,
            paymentType: "credit_card",
          };

          dispatch(
            checkInternationalCard({
              registrationOrder: update,
              cardInfo: {
                cardCountryCode: billingAddress?.country,
                organizationKey: details.organization.organizationKey,
              },
            })
          );
        }
      }
    }
  };

  context.onSelectedPaymentOptionChange = (option) => {
    setIsValid(true);
    dispatch(setSelectedPaymentOption(option));
  };

  context.isUserLoggedIn = () => {
    return loggedInUser !== null;
  };

  context.registrantCoversFees = () => {
    return (
      details?.tournament?.platformFeeEnabled &&
      !details?.tournament?.organizationPaysPlatformFee
    );
  };

  context.registrantCanOptToCoverFees = () => {
    return (
      details?.tournament?.organizationPaysPlatformFee &&
      details?.tournament?.allowRegistrantCoverFees
    );
  };

  context.isRegistrantCoveringFees = () => {
    return (
      context.registrantCoversFees() ||
      (context.registrantCanOptToCoverFees() &&
        paymentDetails?.registrantCoversFees)
    );
  };

  context.toggleRegistrantCoversFees = () => {
    dispatch(
      setPaymentDetails({
        ...paymentDetails,
        registrantCoversFees: !paymentDetails.registrantCoversFees,
      })
    );
  };

  context.getPaymentOptions = () => {
    const allOptions = [];
    if (savedPaymentOptions && savedPaymentOptions.length > 0) {
      // if organization is an escrow account, only show global payment options
      // We are not currently supporting Stripe saved payment options, could revisit later
      if (details?.organization?.isEscrowAccount) {
        allOptions.push(
          ...savedPaymentOptions.filter(
            (option) => option.paymentType === "credit_card" && option.hasGlobal
          )
        );
        allOptions.push(
          ...savedPaymentOptions.filter(
            (option) =>
              option.paymentType === "bank_account" && option.hasGlobal
          )
        );
      }
    }
    allOptions.push({
      icon: faCreditCard,
      title: "Credit Card",
      fee: 0.05,
      feeLabel: "5% Fee",
      paymentType: "credit_card",
      saved: false,
    });
    // TODO: implement ACH option later
    // ACH payments are only available for escrow accounts
    // if (details?.organization?.isEscrowAccount) {
    //   allOptions.push({
    //     icon: faBuildingColumns,
    //     title: 'Bank Account',
    //     fee: 0.02,
    //     feeLabel: '2% Fee',
    //     paymentType: 'bank_account',
    //     saved: false,
    //   });
    // }

    return allOptions;
  };

  context.getCaptcha = useMemo(() => {
    return (
      <GSCaptchaCheckbox
        captchaKey={process.env.REACT_APP_CHECKBOX_CAPTCHA_KEY}
        action="CHECKOUT"
        setToken={(value) => dispatch(setCaptchaToken(value))}
      />
    );
  }, [dispatch]);

  context.submitPayment = async (paymentToken = null) => {
    let paymentInfo = {
      captcha_data: captchaData,
      registrationOrderId: details?.id,
    };
    if (isSavedPayment()) {
      paymentInfo.paymentDetails = {
        ...paymentDetails,
        registrantCoversFees: context.isRegistrantCoveringFees(),
      };
    } else {
      paymentInfo.billingAddress = billingAddress;

      // Global Pay
      if (details?.organization?.isEscrowAccount) {
        if (!paymentToken) {
          return;
        }

        // TODO: implement pay by bank account
        const updatedPaymentDetails = {
          ...paymentDetails,
          token: paymentToken,
          registrantCoversFees: context.isRegistrantCoveringFees(),
        };
        dispatch(setPaymentDetails(updatedPaymentDetails));
        paymentInfo.paymentDetails = updatedPaymentDetails;

        // Stripe Pay
      } else {
        paymentInfo.paymentDetails = paymentDetails;
      }
    }
    dispatch(payCustomerInvoice(paymentInfo));

    // clear captcha
    grecaptcha.enterprise.reset(); // eslint-disable-line no-undef
  };

  // effects
  useEffect(() => {
    // if refreshing or landing for the first time, fetch details
    if (match?.params?.id) {
      dispatch(getCustomerInvoiceDetails(match.params.id));
    }
  }, [dispatch, match?.params?.id]);

  useEffect(() => {
    // if registration order is paid, navigate to receipt page
    if (details?.registrationOrder?.status === "paid") {
      navigate(`/invoice/${match?.params?.id}/receipt`);
    }
  }, [
    dispatch,
    navigate,
    details?.registrationOrder?.status,
    match?.params?.id,
  ]);

  useEffect(() => {
    if (loggedInUser) {
      dispatch(getUserPaymentOptions(loggedInUser.id));
    } else {
      // TODO: call to action to invite the user to log in if they have saved payment methods
    }
  }, [dispatch, loggedInUser]);

  const isSavedPayment = () => {
    return selectedPaymentOption && selectedPaymentOption.saved;
  };

  const leftNavigation = () => {
    // if (isValid && !bannerNotifications) {
    //   setUnsaved(true);
    //   return;
    // }
    dispatch(clearNotifications());
    navigate(-1);
  };

  const getNavigation = () => {
    return {
      title: `Pay Invoice`,
      leftIcon: faChevronLeft,
      leftButtonClick: leftNavigation,
    };
  };

  const timeoutAction = () => {
    dispatch(clearNotifications());
  };

  // TODO: update these notification settings and banner to include validations
  let notificationSettings = {
    notifications: notifications,
    saveAction: context.submitPayment,
    bannerActions: getSaveBannerActions(context.submitPayment, leftNavigation),
    timeoutAction,
  };

  const [bannerNotifications] = useNotificationBanner(notificationSettings);

  const getContent = () => {
    return (
      <GSForm
        formTitle={"Pay Invoice"}
        formSections={getPaymentFormSections(context)}
      ></GSForm>
    );
  };

  const getDrawerActions = () => {
    return [
      {
        name: "Submit Payment",
        isDisabled: !Boolean(captchaData.captcha_token), // only disable the submit button if the captcha token is empty
        action: () => context.submitPayment(),
        type: "black",
      },
      {
        name: "Cancel",
        action: () => navigate(`/${match?.params?.id}`),
        type: "grey",
      },
    ];
  };

  const getDrawer = () => {
    return {
      actions: getDrawerActions(),
    };
  };

  return (
    <customer-invoice-pay>
      <GSSidePanelPage
        header={getNavigation()}
        banner={bannerNotifications}
        content={getContent()}
        drawer={getDrawer()}
      ></GSSidePanelPage>
    </customer-invoice-pay>
  );
};

export default CustomerInvoicePay;
